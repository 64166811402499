<template>
  <svg
    version="1.1"
    ref="svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 768 768"
    style="enable-background:new 0 0 768 768;"
    xml:space="preserve"
  >
    <defs>
      <radialGradient id="light_gradient">
        <stop offset="0.0054" style="stop-color:#B9FFFF" />
        <stop offset="0.0127" style="stop-color:#B6FFFF" />
        <stop offset="0.0185" style="stop-color:#ACFFFF" />
        <stop offset="0.0238" style="stop-color:#9BFFFF" />
        <stop offset="0.0287" style="stop-color:#84FFFF" />
        <stop offset="0.0335" style="stop-color:rgba(101,255,255,.99)" />
        <stop offset="0.038" style="stop-color:rgba(101,255,255,.92)" />
        <stop offset="0.0424" style="stop-color:rgba(101,255,255,.86)" />
        <stop offset="0.0442" style="stop-color:rgba(101,255,255,.8)" />
        <stop offset="0.1118" style="stop-color:rgba(101,255,255,.75)" />
        <stop offset="0.2009" style="stop-color:rgba(101,255,255,.7)" />
        <stop offset="0.2947" style="stop-color:rgba(101,255,255,.6)" />
        <stop offset="0.3916" style="stop-color:rgba(101,255,255,.5)" />
        <stop offset="0.4923" style="stop-color:rgba(101,255,255,.4)" />
        <stop offset="0.5981" style="stop-color:rgba(101,255,255,.3)" />
        <stop offset="0.7112" style="stop-color:rgba(101,255,255,.2)" />
        <stop offset="0.8369" style="stop-color:rgba(101,255,255,.1)" />
        <stop offset="1" style="stop-color:rgba(101,255,255,.0)" />
      </radialGradient>
    </defs>
    <g class="main_group">
      <g class="light_g">
        <ellipse
          class="st0"
          cx="743.772"
          cy="187.167"
          rx="108.468"
          ry="108.189"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st1"
          cx="709.807"
          cy="662.952"
          rx="96.368"
          ry="96.119"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st2"
          cx="507.641"
          cy="300.228"
          rx="40.366"
          ry="40.262"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st3"
          cx="638.928"
          cy="300.228"
          rx="44.622"
          ry="44.507"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st4"
          cx="554.512"
          cy="136.871"
          rx="52.238"
          ry="52.104"
        />
      </g>
      <g class="light_g">
        <ellipse class="st5" cx="437.86" cy="155.371" rx="58.042" ry="57.893" />
      </g>
      <g class="light_g">
        <ellipse class="st6" cx="355.038" cy="419.914" rx="58.501" ry="58.35" />
      </g>
      <g class="light_g">
        <ellipse class="st7" cx="437.86" cy="574.765" rx="76.591" ry="76.394" />
      </g>
      <g class="light_g">
        <ellipse
          class="st8"
          cx="555.074"
          cy="560.601"
          rx="76.591"
          ry="76.393"
        />
      </g>
      <g class="light_g">
        <ellipse class="st9" cx="489.747" cy="246.328" rx="27.511" ry="27.44" />
      </g>
      <g class="light_g">
        <ellipse
          class="st10"
          cx="613.846"
          cy="204.303"
          rx="27.511"
          ry="27.44"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st11"
          cx="575.498"
          cy="236.59"
          rx="23.784"
          ry="23.723"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st12"
          cx="543.218"
          cy="195.902"
          rx="23.784"
          ry="23.723"
        />
      </g>
      <g class="light_g">
        <ellipse class="st13" cx="503.3" cy="145.774" rx="23.784" ry="23.723" />
      </g>
      <g class="light_g">
        <ellipse
          class="st14"
          cx="602.442"
          cy="256.712"
          rx="23.784"
          ry="23.723"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st15"
          cx="591.655"
          cy="289.907"
          rx="23.784"
          ry="23.723"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st16"
          cx="695.417"
          cy="268.329"
          rx="27.511"
          ry="27.44"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st17"
          cx="455.47"
          cy="283.461"
          rx="19.849"
          ry="19.798"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st18"
          cx="361.073"
          cy="270.811"
          rx="19.849"
          ry="19.798"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st19"
          cx="399.158"
          cy="257.76"
          rx="19.849"
          ry="19.798"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st20"
          cx="294.943"
          cy="257.05"
          rx="19.849"
          ry="19.798"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st21"
          cx="418.117"
          cy="412.769"
          rx="19.849"
          ry="19.798"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st22"
          cx="464.956"
          cy="493.369"
          rx="19.849"
          ry="19.798"
        />
      </g>
      <g class="light_g">
        <ellipse class="st23" cx="442.223" cy="324.908" rx="27.5" ry="27.429" />
      </g>
      <g class="light_g">
        <ellipse
          class="st24"
          cx="564.062"
          cy="494.747"
          rx="56.565"
          ry="56.42"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st25"
          cx="539.967"
          cy="494.747"
          rx="14.44"
          ry="14.403"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st26"
          cx="485.944"
          cy="406.237"
          rx="56.565"
          ry="56.42"
        />
      </g>
      <g class="light_g">
        <ellipse class="st27" cx="455.34" cy="415.402" rx="23.52" ry="23.46" />
      </g>
      <g class="light_g">
        <ellipse class="st28" cx="462.663" cy="379.553" rx="23.52" ry="23.46" />
      </g>
      <g class="light_g">
        <ellipse class="st29" cx="401.001" cy="381.551" rx="23.52" ry="23.46" />
      </g>
      <g class="light_g">
        <ellipse class="st30" cx="359.928" cy="364.161" rx="23.52" ry="23.46" />
      </g>
      <g class="light_g">
        <ellipse
          class="st31"
          cx="399.324"
          cy="470.422"
          rx="56.565"
          ry="56.42"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st33"
          cx="329.426"
          cy="223.835"
          rx="63.156"
          ry="62.994"
        />
      </g>
      <g class="light_g">
        <ellipse
          class="st34"
          cx="252.558"
          cy="329.871"
          rx="129.272"
          ry="128.939"
        />
      </g>
      <g>
        <polygon
          class="st32"
          points="304.033,367.68 303.121,366.772 301.484,367.417 302.889,369.365 304.039,370.108 304.479,370.796
          306.5,370.468 305.133,368.093 	"
        />
        <path
          class="st32"
          d="M329.044,385.073l-1.157-0.16l-1.168-0.719l-1.176,0.144l-0.631,0.896l-0.216,0.886l0.142,1.045l0.703,0.815
          l2.212,0.379l0.269-0.414l0.689-0.019l1.188,0.938l0.686-1.438L329.044,385.073z M327.218,386.427l-0.187,0.288l-0.707-0.121
          l-0.061-0.071l-0.044-0.322l0.082-0.333l0.083-0.117l0.986,0.606l0.511,0.07H327.218z"
        />
        <path
          class="st32"
          d="M304.287,377.322l-0.666-0.557l-0.504-0.101l-0.694-0.844l-1.715,0.78l0.213,1.594l1.023,1.514l1.665,0.82
          l2.778-2.578l-1.919-0.229L304.287,377.322z M302.963,378.54l-0.293-0.433v-0.001l0.282,0.056l0.108,0.091l0.193,0.431
          L302.963,378.54z"
        />
        <path
          class="st32"
          d="M338.383,338.899l-1.388,1.27l-0.229,1.01l1.289,1.068l-0.014,0.334l1.098,1.414l1.156,0.525l2.196,1.609
          l0.524-0.605l0.599,0.368l1.775-0.454l1.435,1.204l0.201-0.946l0.113,0.08l0.609,0.116l0.768,0.636l-0.449-0.02l-0.222,1.294
          l3.084,2.855l1.287,1.789l0.721,0.671l0.072,0.742l0.529,0.755l1.53,0.644l0.8-0.969l-0.185-0.658l-0.063-0.162l1.639-0.247
          l1.237-0.919l0.281-1.307l-0.261-2.542l-1.02,0.85l-0.41-1.642l-2.179-2.523l-0.44-1.031l-0.888-0.533l-2.304-2.583l-1.637-1.184
          l-1.663-1.88l-0.789-1.25l-0.259-0.148l0.547,0.104l0.961-1.15l-0.39-0.612l0.788-0.608l-0.658-1.492l-0.359,0.045l-0.143-0.347
          l-0.396-0.05l-0.161-0.335l2.521,0.3l0.631-0.762l-0.011-0.955l-0.401-0.896l-0.691-0.812l-2.289-1.696l-1.275,0.036l-0.252,1.134
          l-0.504-0.057l-0.289,1.414l1.454,1.821l-0.534,0.51l-0.348-0.355l-0.312,0.072l-1.605-1.866l0.137,2.622l-0.282,0.118
          l-0.255,0.447l-2.246-0.55l-0.747-0.098l-0.935,0.864l2.482,3.492l0.586,0.164l-0.994,1.292l0.138,0.852l-1.74-0.482
          L338.383,338.899z M346.193,343.266l-1.396-3.563l0.3-0.609l1.843,4.703L346.193,343.266z M346.751,329.034l1.357,0.995
          l0.427,0.468l0.123,0.274l-1.113-0.123l-0.537-0.187l-0.449-0.562L346.751,329.034z M344.461,333.754l0.362-0.152l0.197,0.202
          l0.988-0.117l0.144-0.137l0.123,0.258l0.344,0.043l0.071,0.171l-0.646,0.499l0.351,0.551l-0.686,0.362l-1.086,1.501l1.493,0.859
          l0.624,0.987l1.832,2.086l1.641,1.187l2.323,2.605l0.743,0.485l0.294,0.691l2.138,2.53l0.318,1.348l0.619,1.075l0.603,0.168
          l-0.101,0.478l-0.486,0.361l-0.925,0.139l-1.199-1.58l-1.917-2.102l-0.328-0.708l-2.6-2.262l-0.348-0.015l-0.939-0.778
          l-0.667-0.127l-0.503-0.357l-2.164-5.523l-0.173-0.645l-0.104-0.061l-0.841-2.147l0.972-1.121L344.461,333.754z M343.742,335.636
          l0.775,1.977l-0.587-0.346l-0.091-0.696l-0.532-0.715l-0.019-0.005l-0.295-0.752L343.742,335.636z M341.423,334.363l0.832,0.206
          l0.438,0.315l0.352,0.899l-0.767-0.215L341.423,334.363z M341.913,341.75l2.254-0.175l-1.445-1.727l-0.036-0.224l0.578-0.125
          l1.195,0.891l0.219-0.444l1.218,3.109l-1.546-1.097l0.205,2.148l-0.694,0.177l-1.143-0.703l-0.454,0.523l-1.144-0.838l-1.022-0.481
          l-0.534-0.688l0.021-0.528l-0.994-0.824l0.503-0.46l0.212-0.045l1.247,0.346L341.913,341.75z"
        />
        <polygon
          class="st32"
          points="302.917,344.937 304.517,345.333 305.761,340.748 305.213,339.677 303.813,340.19 303.098,341.345
          303.262,342.261 302.632,343.869 	"
        />
        <path
          class="st32"
          d="M798.771,287.694c-0.153-0.452-0.286-0.907-0.42-1.362c-0.119-0.408-0.239-0.816-0.372-1.222
          c-0.095-0.29-0.261-0.51-0.394-0.688c-0.063-0.084-0.143-0.189-0.16-0.24c-0.236-0.647-0.509-1.287-0.782-1.927
          c-0.167-0.393-0.335-0.785-0.493-1.18l-0.172-0.427l-0.459-0.041c-0.715-0.063-1.478-0.01-2.216,0.042
          c-0.355,0.025-0.707,0.05-1.039,0.06c-1.783,0.048-4.138,0.058-6.532-0.292c-0.427-0.063-0.85-0.121-1.271-0.179
          c-1.859-0.254-3.616-0.494-5.353-1.162c-0.725-0.276-1.863-0.946-2.965-1.593c-0.951-0.559-1.85-1.087-2.562-1.418
          c-0.464-0.216-1.066-0.472-1.735-0.755c-1.939-0.821-4.869-2.061-5.817-3.07c-0.546-0.581-0.717-1.586-0.882-2.559
          c-0.215-1.265-0.437-2.572-1.507-3.258c-1.318-0.842-2.847-0.81-4.198-0.787c-0.852,0.019-1.667,0.033-2.122-0.206
          c-0.401-0.212-0.766-0.6-1.152-1.01c-0.249-0.266-0.498-0.528-0.759-0.754c-0.354-0.308-0.733-0.552-1.101-0.789
          c-0.504-0.324-0.979-0.63-1.299-1.05c-0.248-0.324-0.324-0.76-0.195-1.11c0.455-1.23,1.12-1.209,2.223-1.18
          c0.944,0.026,2.239,0.065,3.069-1.184c0.324-0.487,0.546-0.893,0.44-1.685c-0.133-0.999-0.674-1.866-1.521-2.438
          c-0.465-0.316-0.998-0.625-1.674-0.969c-0.153-0.123-0.283-0.228-0.318-0.283c-0.295-0.447-0.486-0.802-0.532-1.188
          c-0.032-0.265,0.034-0.472,0.135-0.786c0.065-0.204,0.131-0.414,0.177-0.645c0.101-0.5,0.124-0.991,0.147-1.467
          c0.045-0.926,0.085-1.727,0.619-2.353c0.215-0.02,0.892,0.448,1.21,0.674c0.35,0.249,0.651,0.464,0.946,0.583
          c1.223,0.492,4.698,0.23,5.799-0.307c0.824-0.399,1.688-1.95,1.842-2.639c0.183-0.825-0.013-1.668-0.567-2.44
          c-0.963-1.34-2.774-2.18-4.073-2.318c-0.119-0.011-0.407-0.019-0.748-0.026c-0.339-0.007-0.995-0.021-1.28-0.049
          c-0.114-0.138-0.152-0.42-0.197-0.835c-0.075-0.706-0.189-1.773-1.348-2.148c-1.988-0.65-2.512,0.089-2.991,1.122
          c-0.164,0.355-0.351,0.758-0.711,1.168c-0.485,0.555-3.03,0.907-4.003,0.555c-0.336-0.122-0.41-0.196-0.58-0.381
          c0.437-0.746,0.798-1.657,0.631-2.468c-0.1-0.487-0.379-0.888-0.809-1.157c-0.261-0.163-0.578-0.231-0.886-0.297
          c-0.135-0.029-0.268-0.054-0.368-0.093l-0.659-0.256l-0.181,0.394c-0.026-0.004-0.054-0.009-0.081-0.014
          c-0.275-0.045-0.675-0.112-0.883-0.189c0.062-0.389,0.69-1.292,1.004-1.742c0.257-0.369,0.459-0.665,0.605-0.921
          c1.13,0.013,1.698-0.462,1.967-0.789c0.401-0.488,0.52-1.136,0.309-1.691c-0.029-0.079-0.065-0.154-0.107-0.225
          c0.656-0.111,1.22-0.462,1.619-1.042l0.263-0.381l-0.223-0.405c-0.201-0.367-0.475-0.958-0.511-1.094l-0.113-0.606
          c1.15-0.158,2.391-0.377,2.831-1.352c0.289-0.64,0.138-1.388-0.462-2.286c-0.107-0.163-0.175-0.359-0.253-0.587
          c-0.13-0.379-0.291-0.852-0.704-1.274c-0.521-0.532-1.128-0.827-1.665-1.086c-0.268-0.13-0.538-0.259-0.799-0.424
          c-0.407-0.257-0.575-0.445-0.807-0.704c-0.019-0.021-0.037-0.041-0.056-0.062c0.079,0.051,0.16,0.104,0.242,0.158
          c0.896,0.589,1.91,1.261,2.925,1.093c1.008-0.168,1.609-1.4,1.758-2.546c0.149-1.152-0.15-2.055-0.807-2.465
          c0.039-0.042,0.078-0.086,0.119-0.131c0.609-0.681,0.959-1.293,0.942-2.411l-0.039-0.227c-0.104-0.31-0.325-0.618-0.595-0.822
          l-0.142-0.108l-0.175-0.033c-0.855-0.158-1.657-0.052-2.435,0.328c-0.006-0.01-0.011-0.02-0.017-0.029
          c-0.104-0.189-0.206-0.364-0.341-0.525c-0.501-0.601-1.251-0.216-2.389,0.37c-0.241,0.124-0.572,0.294-0.63,0.317
          c-0.614,0.113-0.67,0.048-0.905-0.219c-0.227-0.258-0.569-0.648-1.25-0.882c-1.051-0.364-3.235-1.122-3.281-1.618
          c0-0.004,0.006-0.446,1.498-1.336c0.122,0.071,0.277,0.171,0.394,0.247c0.605,0.392,1.291,0.835,1.938,0.78
          c0.449-0.038,0.851-0.308,1.191-0.8c1.01-1.459,1.531-4.942,1.237-5.985l-0.04-0.144l-0.093-0.117
          c-0.602-0.761-1.405-1.203-2.264-1.244c-0.85-0.046-1.655,0.31-2.3,0.982c-0.263,0.274-0.446,0.581-0.608,0.852
          c-0.132,0.22-0.257,0.428-0.391,0.569c-0.113,0.108-0.438,0.326-0.723,0.48c0.07-0.506,0.308-1.259,0.441-1.684
          c0.176-0.557,0.342-1.083,0.396-1.509c0.042-0.328,0.012-0.908-0.044-1.825c-0.012-0.204-0.026-0.452-0.038-0.684
          c2.184,0.447,3.479-0.014,3.856-1.367c0.434-1.56-0.684-2.128-1.352-2.468c-0.529-0.269-1.03-0.523-1.313-1.138l-0.075-0.156
          c-0.036-0.071-0.084-0.168-0.087-0.207c0.047-0.271,0.193-0.365,0.92-0.591c0.698-0.217,1.653-0.514,2.098-1.576
          c0.171-0.41,0.191-1.053-0.161-1.485c-0.292-0.373-0.736-0.413-1.03-0.439c-0.08-0.008-0.195-0.018-0.18,0.002l-0.421-0.318
          l-1.322,0.867l-1.17-0.276l-0.472,1.079l-2.066,0.311l-1.422-1.332l-0.809,0.883c-1.038,1.121-1.945,2.099-1.402,4.175
          l-0.155-0.005c-1.177-1.112-2.138-1.189-2.852-1.247c-0.327-0.026-0.563-0.045-0.767-0.152c-0.602-0.318-1.182-0.586-1.745-0.847
          c-1.369-0.632-2.552-1.178-3.721-2.295l-0.191-0.182l-0.264-0.022c-0.095-0.009-2.343-0.191-3.551,0.452l-0.183,0.098l-0.549,0.91
          l-0.007,1.215l-2.06,1.108l-0.354,1.332l0.479,0.862l-1.66,0.219l0.022,0.68c0.025,0.743-0.196,1.354-0.431,2.001
          c-0.216,0.595-0.438,1.207-0.48,1.928c-0.725,0.522-1.471,1.425-1.736,2.275c-0.762,0.458-1.399,0.9-1.941,1.349l-0.21,0.173
          l-0.17,0.914l-1.128,0.586l-1.036,0.598l-1.214,0.024l-0.204,0.176c-0.309,0.266-0.52,0.464-0.683,0.617
          c-0.327,0.307-0.378,0.354-0.958,0.501l-1.32-1.157l-0.962,0.086l-0.196,0.217c-0.665,0.734-1.205,2.155-1.474,2.959
          c-0.135,0.077-0.244,0.142-0.336,0.196c-0.118,0.069-0.253,0.148-0.278,0.157c-0.044,0.012-0.204,0.016-0.39,0.021l-0.936,0.032
          l-0.84,2.148c-0.362,0.15-1.364,0.266-2.982,0.343c-0.557-1.118-1.915-2.269-1.932-2.282l-0.169-0.139l-2.337-0.279l-1.543,0.882
          l-0.602,1.233c-1.322-0.489-3.541-1.062-4.511-1.028c-0.079,0.005-0.85,0.043-1.202,0.054l-0.025-0.013
          c-0.99-0.523-2.098-1.109-3.451-0.956l-0.009-0.008c-1.741-1.669-2.901-2.725-5.671-2.747l-0.219,0.002l-0.349,0.227
          c-0.356,0.232-0.698,0.455-1.049,0.66c-0.423-0.285-0.71-0.553-0.947-0.876l-0.199-0.25l-0.303-0.049
          c-1.017-0.165-1.894-0.308-3.025-0.174l-0.435,0.051l-0.169,0.403c-0.269,0.639-0.879,1.342-1.418,1.963l-0.154,0.18
          c-1.445,0.153-3.568-0.534-5.146-1.04c-1.126-0.36-1.827-0.577-2.333-0.608l-1.784-1.999l-0.383,0.04
          c-0.094,0.01-0.188,0.011-0.283,0.013c-0.215,0.004-0.459,0.009-0.722,0.074l-0.274,0.068l-0.06,0.084
          c-0.103,0.039-0.287,0.102-0.616,0.203c-1.252-0.441-2.495-0.158-3.508,0.072c-0.567,0.129-1.1,0.251-1.489,0.208
          c-0.188-0.021-0.421-0.094-0.667-0.172c-0.575-0.183-1.29-0.412-2.043-0.127l-0.186,0.102c-1.153,0.865-2.195,1.79-3.039,2.698
          l-0.532-1.542l-1.626-1.269c-0.004-0.031-0.008-0.062-0.013-0.093c-0.114-0.845-0.272-2.002-1.212-2.567l-0.381-0.229l-0.384,0.224
          c-0.285,0.166-0.53,0.353-0.775,0.542c-0.112,0.086-0.222,0.171-0.334,0.25l-0.02-0.014c-1.772-1.291-1.798-1.301-1.939-1.358
          c-0.112-0.042-0.143-0.053-2.505-0.361l-1.184-1.233l-0.209-0.057c-0.344-0.094-0.692-0.184-1.042-0.273
          c-0.675-0.173-1.353-0.347-2.004-0.553c-0.111-0.036-0.435-0.17-0.775-0.31c-0.922-0.378-1.084-0.438-1.237-0.459
          c-0.117-0.019-0.17-0.025-2.117,0.15l-0.977-0.848c-0.639-2.627-1.538-6.123-3.92-8.625c-0.622-0.654-1.394-1.237-2.14-1.801
          c-0.428-0.323-0.854-0.645-1.25-0.98c-1.506-1.266-2.933-1.261-4.463-1.256c-0.313,0.001-0.644,0.003-0.945-0.003
          c-0.119-0.02-0.562-0.164-0.825-0.25c-0.337-0.11-0.654-0.21-0.8-0.24c-0.768-0.16-1.541-0.281-2.315-0.403
          c-0.559-0.088-1.117-0.176-1.673-0.278c-0.338-0.063-0.658-0.137-0.973-0.209c-0.771-0.177-1.568-0.36-2.485-0.36
          c-0.577,0-0.86,0.466-1.027,0.739l-0.18,0.232l0.091,1.034l-0.64-0.159l0.278,1.495l-0.039,0.041l-0.647-1.359l-1.36-0.768
          l-1.191,1.631l-0.062-0.057l0.017-1.345l-0.438-0.669c0.085-0.033,0.171-0.069,0.258-0.109l0.247-0.116l0.927-1.994l-2.554-0.151
          c0.166-0.384,0.702-1.748,0.337-2.542l-1.979-2.014l-0.127,1.603c-0.029,0.375-0.154,0.955-0.256,1.264l-2.296-2.957l-0.219,2.259
          l-0.449-1.642l-0.911,0.893c-0.218,0.213-0.591,0.431-0.922,0.557l1.014-1.382l-0.934-1.143l-0.299-0.037
          c-0.751-0.092-1.499-0.212-2.247-0.333c-0.332-0.053-0.664-0.106-0.995-0.157l-0.108-0.122c0.533-0.387,0.963-0.459,1.45-0.541
          c0.575-0.096,1.227-0.206,1.882-0.833l0.463-0.442l-1.031-1.488l-1.686-0.685l-0.35,0.049c-0.355,0.05-0.667,0.093-0.978,0.197
          c0.426-0.541,0.682-1.114,0.831-1.848l0.075-0.366l-0.869-0.959l-0.281-0.036c-0.068-0.009-0.138-0.018-0.207-0.027
          c0.163-0.206,0.289-0.431,0.334-0.666l0.067-0.35l-1.417-1.691l-2.04-0.623c-0.088-0.255-0.058-0.434-0.013-0.69
          c0.041-0.241,0.093-0.541,0.042-0.895c-0.019-0.14-0.069-0.283-0.388-1.096c-0.075-0.19-0.143-0.362-0.178-0.46l-0.07-0.189
          l-1.295-1.075l-0.315,0.035c-0.565,0.062-1.051,0.22-1.505,0.404c-0.454-0.846-0.343-1.283-0.187-1.896
          c0.2-0.784,0.449-1.761-0.347-3.439l-0.118-0.249l-1.108-0.502l-0.676-1.673l-2.522-0.21l-0.39-0.594l-1.733-0.408l-0.733-0.862
          l-0.594-0.062c-0.38-0.038-1.025-0.1-1.269-0.13c-0.552-0.256-1.136-0.655-1.701-1.042c-0.444-0.303-0.903-0.617-1.364-0.875
          c-0.378-0.21-0.778-0.387-1.18-0.563c-0.35-0.153-0.699-0.305-1.027-0.486c-0.341-0.188-0.696-0.436-1.058-0.685
          c-0.598-0.413-1.217-0.84-1.848-1.093c-0.113-0.045-0.142-0.057-2.062-0.36l0.053-0.502l-0.644-1.19l-0.347-0.068
          c-0.758-0.149-1.302-0.204-1.661-0.239c-0.094-0.009-0.193-0.02-0.275-0.029c-0.019-0.122-0.036-0.289-0.055-0.453
          c-0.029-0.271-0.066-0.608-0.127-1.031l-0.097-0.682l-1.259,0.07c-0.469-0.218-0.848-0.768-1.248-1.348
          c-0.425-0.616-0.864-1.253-1.518-1.693c-0.18-0.12-0.308-0.228-0.422-0.358c-0.066-0.348-0.183-0.836-0.322-1.427
          c-0.233-0.985-0.746-3.146-0.75-3.985l2.874-0.806l-2.666-0.835c-0.158-0.045-0.482-0.138-0.664-0.218l-0.071-0.617l-0.516-0.236
          l0.113-0.424l-0.366-1.091l-0.899,0.082c-0.078-0.079-0.122-0.186-0.215-0.419c-0.038-0.097-0.08-0.2-0.129-0.312l-0.139-0.318
          l-0.931-0.278c-0.071-0.084-0.131-0.156-0.179-0.216l0.401-1.088l-1.583,0.474l-0.346-0.359l-0.543-0.007l-0.321-0.438l-0.35-0.122
          l-0.088-0.576l-0.106-0.147c-0.587-0.809-2.089-0.69-2.569-0.556l-0.198,0.056l-0.518,0.542l-1.037-0.861l-0.423,0.715
          c-0.037-0.082-0.069-0.164-0.094-0.239l-0.164-0.521l-1.001-0.005l-0.44-1.925l-1.158,0.019l-0.151,0.075
          c-0.313,0.155-0.557,0.468-0.838,0.83c-0.027,0.035-0.055,0.071-0.083,0.107c-0.2-0.348-0.396-0.778-0.577-1.177
          c-0.303-0.666-0.616-1.354-0.979-1.821l-2.387-3.711l-0.411,0.011l0.188-0.178l-0.01-0.336c-0.024-0.778-0.781-1.342-1.18-1.532
          c-0.858-0.406-3.128-0.996-5.785-1.668c-0.738-0.187-1.357-0.343-1.704-0.441c-0.557-0.158-1.621-0.729-2.398-1.145
          c-0.277-0.148-0.528-0.283-0.728-0.384l-0.136-0.069l-2.276-0.152c-0.725-0.284-1.893-0.786-2.25-1.054
          c-0.492-0.368-0.892-0.894-1.627-1.924c-0.892-1.247-2.043-1.015-2.883-0.846c-0.168,0.034-0.342,0.068-0.52,0.094
          c-0.347,0.05-0.456,0.045-0.434,0.066c-0.061-0.055-0.205-0.309-0.331-0.533l-0.119-0.208c-0.512-0.893-1.049-0.98-1.648-0.997
          c-0.282-0.008-0.601-0.017-1.109-0.221c-1.169-0.468-1.933-1.25-2.606-1.939l-0.276-0.281c-0.933-0.932-1.88-0.165-2.336,0.204
          c-0.258,0.209-0.525,0.425-0.787,0.5c-1,0.287-1.25,1.087-1.471,1.793c-0.136,0.433-0.276,0.879-0.587,1.346
          c-0.716,1.076-1.112,1.668-3.138,0.908c-1.092-0.411-1.824-0.567-2.33-0.159c-0.463,0.375-0.378,0.932-0.333,1.231
          c0.011,0.071,0.027,0.178,0.028,0.194c-0.064,0.257-0.691,0.864-1.34,1.125c-0.426,0.17-0.731,0.514-0.858,0.968
          c-0.218,0.776,0.125,1.72,0.538,2.27c0.387,0.512,0.661,1.226,1.221,3.183c0.579,2.025,0.875,3.64,0.877,3.656l0.042,0.231
          l2.003,2.003l-0.216,2.146l-3.369,1.123c-1.01-0.219-3.486-0.68-4.581-0.211c-1.166,0.501-1.447,0.078-1.615-0.174
          c-0.655-0.979-3.412-2.716-3.958-3.054l-0.264-0.164l-0.303,0.071c-0.274,0.064-2.713,0.654-3.845,1.462
          c-0.505,0.361-2.612,0.441-4.274,0.308l-0.504-0.041l-1.078,2.156l1.923,1.749l1.047,2.875c-0.152,0.288-0.662,0.636-1.853,0.569
          c-2.68-0.166-3.319,1.905-3.667,3.012c-0.067,0.212-0.127,0.41-0.197,0.573c-0.097,0.227-0.215,0.365-0.35,0.408
          c-0.161,0.052-0.41-0.007-0.664-0.159c-0.639-0.383-1.91-0.749-3.233,1.102c-0.613,0.86-2.176,1.774-3.864,1.774
          c-2.07,0-4.23,0.916-4.321,0.955l-0.135,0.075c-0.162,0.113-1.598,1.13-2.201,1.892c-0.444,0.327-3.108,0.682-5.318,0.808
          l-0.936,0.054l0.785,2.744l-0.799,1.998c-0.126,0.314-0.639,1.374-1.93,1.805c-0.171,0.057-0.304,0.095-0.408,0.119
          c0.008-0.03,0.017-0.062,0.027-0.098c0.126-0.444,0.047-0.866-0.222-1.188c-0.625-0.748-2.049-0.673-3.708-0.509
          c-1.331,0.131-3.277-0.418-3.297-0.423l-0.308-0.088l-4.497,2.625l0.366,0.645c0.139,0.245,0.499,1.002,0.499,1.568
          c0,1.085-0.542,1.757-1.708,2.115c-1.184,0.364-1.881,0.941-2.073,1.714c-0.147,0.597,0.044,1.224,0.526,1.727
          c-0.086,0.039-0.209,0.086-0.381,0.135c-0.257,0.074-0.86,0.248-1.056,0.793c-0.188,0.526,0.106,0.953,0.392,1.366
          c0.109,0.159,0.24,0.347,0.369,0.573c0.492,0.862,0.619,1.236,0.24,2.374c-0.468,1.402,0.498,2.209,1.075,2.692
          c0.201,0.167,0.408,0.341,0.484,0.468c0.601,1.005,2.326,2.261,4.501,3.278c1.703,0.794,2.233,1.133,2.746,1.46
          c0.151,0.097,0.299,0.191,0.467,0.292c0.342,0.206,0.81,0.833,1.104,1.312c-0.071,0.56-0.208,1.312-0.374,1.643
          c-0.298,0.6-0.75,1.507-1.198,2.704c-0.457,1.224-1.188,4.285-1.218,4.414l-0.037,0.153l0.314,1.703
          c0.051,0.921,0.147,2.785,0.147,3.238c0,1.107-0.261,3.583-0.863,4.287c-1.133,1.322-1.82,2.185-1.82,3.022v3.548l1.921,2.374
          l-2.723,0.545l-2.186,2.058l-1.127-1.025l-5.188-2.961l-1.52,2.069l-1.555-1.558l-0.531,0.535c-0.346,0.35-0.515,1.206-0.515,2.618
          c0,0.214-0.059,0.384-0.346,0.519c-0.664,0.312-1.795,0.098-2.152-0.201c-0.441-0.368-0.872-0.391-1.153-0.349
          c-0.829,0.128-1.375,0.98-1.773,1.602c-0.089,0.139-0.169,0.266-0.244,0.364c-0.489,0.652-0.223,1.728,0.082,2.508
          c-0.626,0.314-1.369,0.624-1.718,0.624c-0.527,0-1.062,0.085-1.58,0.168c-0.416,0.066-0.809,0.129-1.105,0.129
          c-1.038,0-2.575,0.92-3.399,1.469c-0.814,0.544-3.249,2.362-3.802,2.777c-0.372,0.107-1.492,0.421-2.299,0.536
          c-1.176,0.168-2.562,1.065-3.762,1.921c-1.142,0.816-2.373,2.654-2.425,2.731l-0.249,0.374l1.722,3.226l1.412-0.566
          c0.221,0.518,0.563,1.225,1.006,1.845l0.361,0.503c0.538,0.748,0.893,1.241,1.248,1.831c0.356,0.596,0.88,0.643,1.223,0.501
          c0.848-0.353,0.875-1.889,0.742-2.411c-0.09-0.361-0.26-0.748-0.408-1.045c0.33-0.378,0.797-0.967,1.097-1.569
          c0.252-0.504,0.29-0.583,1.062-0.36c0.152,0.043,0.242,0.079,0.292,0.103c0.003,0.034,0.004,0.07,0.007,0.109
          c0.011,0.16,0.024,0.344,0.067,0.557c0.107,0.54,0.427,1.285,0.798,2.148c0.202,0.472,0.411,0.957,0.578,1.401
          c0.211,0.56,0.745,1.213,1.31,1.905c0.435,0.532,1.228,1.501,1.296,1.889c-0.628,0.734-1.067,0.9-2.032,0.128
          c-1.289-1.032-2.162-0.086-2.581,0.368c-0.456,0.493-0.681,0.706-1.263,0.539c-1.247-0.354-2.146-0.266-2.751,0.274
          c-0.599,0.535-0.789,1.405-0.58,2.66c0.047,0.279,0.045,0.431,0.038,0.506c-0.093,0.023-0.251,0.043-0.374,0.059
          c-0.313,0.041-0.703,0.09-1.126,0.249c-0.443,0.167-0.75,0.487-0.865,0.9c-0.246,0.893,0.473,1.894,1.23,2.842l0.185,0.234
          c0.22,0.36,0.455,2.367,0.455,4.781c0,1.692,1.54,3.195,2.559,4.189c0.251,0.246,0.464,0.45,0.588,0.604
          c0.451,0.566,1.8,1.735,2.617,2.424c-0.276,0.254-0.582,0.495-0.817,0.589c-0.892,0.356-1.612,1.157-2.333,2.598
          c-0.171,0.339-1.64,0.437-2.996,0.206l-4.592-1.898l-3.396,1.965l-1.145,3.296c-0.657,0.112-1.455,0.101-1.613-0.176
          c-0.307-0.537-0.7-0.817-1.167-0.833c-0.854-0.017-1.418,0.815-2.198,1.984c-0.042,0.063-0.067,0.093-0.187,0.081
          c-0.714-0.065-1.963-1.283-2.229-1.901c-0.497-1.156-2.508-2.805-2.906-3.124l-0.101-0.068c-0.946-0.533-2.003-1.451-2.003-1.88
          c0-0.427-0.208-1.054-1.202-1.616c-1.145-0.648-3.5-1.214-4.408-0.668c-0.215,0.127-0.647-0.128-0.776-0.232l-0.146-0.095
          c-0.15-0.075-1.503-0.721-2.659-0.337c-0.352,0.117-0.929-0.309-1.722-1.259c-0.03-0.036-3.073-3.567-5.214-3.087
          c-1.68,0.375-1.594,2.354-1.518,4.1c0.018,0.413,0.037,0.837,0.037,1.255c0,1.16-1.038,1.687-2.292,2.21
          c-0.223,0.093-0.42,0.176-0.575,0.253c-0.49,0.244-2.857,0.709-4.691,1.021l-1.663-4.566l-2.935-1.957
          c-0.939-0.531-3.504-2.009-4.077-2.467c-0.686-0.549-1.733-0.747-2.491-0.47c-0.507,0.185-0.854,0.571-0.951,1.058
          c-0.011,0.054-0.024,0.094-0.023,0.115c-0.065-0.02-0.199-0.079-0.402-0.248c-0.396-0.33-0.138-1.531,0-2.176
          c0.044-0.205,0.084-0.393,0.11-0.554c0.03-0.177,0.186-0.425,0.337-0.665c0.368-0.582,0.923-1.46,0.32-2.516
          c-0.669-1.169-2.701-2.497-2.928-2.642c-1.117-0.726-3.071-2.098-3.562-2.686c-0.557-0.669-1.783-1.647-2.526-2.217
          c0.192-0.298,0.406-0.67,0.557-1.049c0.336-0.835,0.241-3.083,0.205-3.752l-0.018-0.32l-0.243-0.208
          c-0.129-0.11-1.289-1.075-2.428-1.075c-0.153,0-0.293-0.005-0.419-0.009c-0.607-0.018-1.531-0.052-1.799,1.025
          c-0.152,0.608-1.618,4.51-2.134,5.284c-0.507,0.76-1.376,1.801-3.07,2.061c-0.321,0.05-1.297,0.201-1.615,0.959
          c-0.303,0.726,0.19,1.41,0.55,1.909c0.087,0.121,0.172,0.238,0.238,0.347c0.189,0.317,0.147,0.453,0.021,0.855
          c-0.037,0.118-0.074,0.239-0.106,0.367c-0.1,0.403-0.904,2.684-1.439,4.171c-0.379,0.574-1.083,1.424-1.5,1.564
          c-0.87,0.289-1.556,1.05-2.106,1.662c-0.149,0.166-0.291,0.323-0.425,0.457l-0.199,0.206c-0.516,0.544-0.889,0.937-2.071,0.643
          c-1.874-0.469-2.424,0.821-2.752,1.591c-0.123,0.289-0.239,0.561-0.392,0.745c-0.582,0.696-2.001,1.222-3.302,1.222
          c-1.169,0-2.248,0.836-2.912,1.489c-0.499-0.607-1.319-1.401-2.468-1.879c-1.278-0.532-2.391-0.173-3.129,0.318
          c-0.288-0.446-0.658-1.068-0.948-1.72c-0.297-0.668-0.761-0.901-1.099-0.979c-1.031-0.241-2.124,0.676-2.921,1.345l-0.129,0.108
          c-0.753,0.627-1.021,2.016-1.115,3.001c-0.777-0.232-1.988-0.574-3.454-0.917c-2.153-0.508-3.443-0.315-3.94-0.19
          c-0.742-0.132-3.292-0.576-4.136-0.576c-0.502,0-2.231-1.188-2.846-1.955c-0.253-0.317-0.538-0.575-0.813-0.824
          c-0.43-0.39-0.802-0.727-1.022-1.239c-0.191-0.449-0.52-0.775-0.949-0.944c-0.825-0.326-1.734,0.06-1.834,0.105l-0.325,0.145
          l-0.314,1.151l-1.983-0.85l-1.46,3.399l0.592,0.329c0.013,0.007,1.296,0.722,1.986,1.274c0.305,0.244,0.575,0.952,0.714,1.508
          c-0.247,0.382-0.729,1.111-1.13,1.611c-0.26,0.324-0.555,0.622-0.791,0.842c-0.228-0.176-0.521-0.404-0.84-0.659
          c-0.845-0.677-2.013-0.29-3.363,0.159c-0.463,0.154-0.957,0.318-1.461,0.444c-1.611,0.401-2.264,2.137-2.513,3.165l-2.335-0.335
          c-0.516-0.199-2.766-1.054-3.648-1.201c-0.285-0.047-0.62-0.418-0.828-0.739l0.345-1.899l-0.47-0.261
          c-0.155-0.086-1.543-0.84-2.602-0.84c-1.005,0-2.381,0-3.263,0.352c-0.486,0.195-2.903,0.625-4.313,0.849l-2.665,1.358
          l-2.013-1.384l-3.94-1.538l-2.157-1.581l-0.315-0.156l-1.302,0.085l-0.24,0.039l-6.998-3.409l-0.915,0.273l-0.779,2.01
          l-0.417,0.238l-0.361,0.957l0.313,0.936l1.213,0.785l0.229,1.058l-0.146,0.688l0.397,0.548l-0.091,0.167l0.317,0.649l-0.561,0.725
          l0.825,1.056l0.487,1.726l-0.867,1.055l0.351,0.927l1.476,1.521l0.107,0.839l-0.499,0.75l0.787,1.04l-0.496,0.658l0.838,0.884
          l0.06,0.006l-0.033,0.127l0.272,1.425l-0.43,0.926l0.349,0.811l-0.273,0.592l0.262,0.561l-0.551,0.84l0.888,0.938l0.497,0.072
          l0.03,0.249l0.418,0.194l-0.012,0.398l0.55,0.798l1.092,0.224l0.505-0.281l1.771-0.104l-0.018,0.071l-0.702,0.062l-0.124,0.375
          l-0.247-0.162l-0.932,0.06l-0.802,1.2l1.799,1.891l-0.415,1.223l-0.828,0.695l0.139,0.982l-0.5,0.565l1.971,0.925l0.463,0.687
          l-0.175,0.32l0.309,0.821l-0.5,0.812l0.127,1.191l-0.474,0.358l0.009,0.674l-0.002,0l-0.128,1.041l-1.077,0.439l1.201,1.779
          l0.323,1.027l0.237,0.104l-0.491,0.763l1.282,1.355l0.118,0.253l0.013,0.29l-0.342,0.296l0.001,0.053l-1.519,1.026l1.271,1.023
          l0.042,0.354l1.215,1.407l0.833,0.384l-0.079,0.123l-0.225-0.168l-1.469,0.662l1.204,2.599l0.477,0.098l0.619,0.655l0.666-0.104
          l0.138,0.609l0.439,0.239l-0.925,0.905l0.527,0.708l-0.341,0.28l-0.328,1.042l0.384,1.008l0.693,0.435l1.265-0.316l0.632,0.086
          l1.025,0.502l0.736,0.89l0.513,0.027l-0.124,0.396l0.265,1.295l0.702,0.024l0.081,0.188l-0.125,1.051l0.441,1.234l1.292,0.402
          l0.165-0.316l0.03,0.028l-0.111,0.184l0.258,0.913l1.739,1.65l1.328,2.168l0.619-0.2l1.063,0.245l0.166,0.695l0.73,1.282
          l1.196,1.193l0.022,0.444l0.64,1.578l1.104,1.604l0.117,1.343l0.517,1.232l-0.586-0.089l-0.148,0.146l0.199-0.397l-0.725-0.282
          l0.186-1.192l-1.446-0.16l-0.079,0.06l-0.659-0.544l-0.11-0.01l-0.05-0.216l-0.054,0.207l-0.285-0.025l-0.312-0.335l-0.036-0.005
          l0.143-0.348l-0.944-2.258l-0.781,0.655l0,0l-0.691-0.157l-0.723,1.395l0.547,0.933l0.084,0.01l-0.043,1.204l1.149,0.655
          l0.001,0.004l-0.645,0.879l0.626,0.984l0.023,0.17l-0.517,0.436l1.307,2.253l1.508-1.912l-0.056-0.311l0.495-0.037l0.292-0.541
          l0.359,0.023l-0.127,0.767l0.5,0.467l0.152,0.295l0.107-0.053l0.114,0.106l0.234-0.098l0.229,1.091l-0.048-0.016l-0.355,1.41
          l0.394,1.375l1.293,0.684l0.542,1.253l0.984-0.677l0.179,0.167l-0.161,1.496l0.516,1.064l1.238,0.68l-0.422,0.617l0.742,1.209
          l0.748-0.099l0.141,0.228l0.74,0.403l0.303,0.72l0.628,0.116l0.346,0.759l1.267-0.559l0.759,0.234l0.083-0.028l-0.27,0.753
          l0.395,1.165l-0.479,0.384l0.616,1.432l-0.057,1.56l1.684-0.187l0.87,0.474l1.096-1.011l-1.149-1.281l0.475-0.474l-0.602-1.317
          l0.571-0.578l0.027-0.427l0.784,1.161l0.176-1.878l0.309,0.312l3.293,0.183l0.774,0.338l2.189,0.04l0.54-1.676l-0.522-1.034
          l-0.42-0.253l-0.118-0.914l-0.513-0.103l0.156-0.907l-0.799-1.181l-0.097-0.393l0.074-0.675l0.628-0.248l0.583-1.16l0.902-0.68
          l-1.023-1.727l0.853-0.464l0.326-1.592l0.668,0.276l1.451-2.57l0.158,0.19l1.078-1.833l0.132-0.929l0.55-1.504l2.577-0.404
          l0.58-1.306l0.568-0.565l1.479-0.608l0.124-0.149l0.052,0.731l1.625,0.223l0.246-0.286l0.667,0.616l1.001,0.177l1.009-0.773
          l0.474-1.289l1.166-1.315l0.329-0.927l0.025-2.13l-0.372-1.35l0.326-0.501l-0.488-1.725l-0.397-0.089l-0.082-0.423l0.223-0.237
          l-0.164-0.868l-0.195-0.209l0.646-0.688l-0.784-1.53l0.294-0.831l-0.274-0.745l1.4-1.101l1.886-2.895l0.627-2.059l0.521,0.238
          l1.431-2.944l0.926-1.142l0.242-1.944l0.398-0.895l0.133-1.4l0.639-1.077l0.266-1.088l-0.065-1.567l0.234-1.655l1.002-2.616
          l2.129-8.482l0.738-1.712l1.076-1.042l0.458-1.578l0.652-0.525l1.139-1.675l0.194-0.136l1.773,1.046l1.261,0.512l2.187,0.682
          l2.463,0.51l0.452,0.485l2.055,0.353l3.398,1.438l0.987,1.491l1.688,1.576l2.429,1.34l1.92,0.691l0.735-0.198l-0.111,0.136
          l1.47,2.254l-0.185,0.839l0.559,0.525l-0.11-0.005l-1.158-1.07l-1.238,1.197l-0.544-0.099l-0.792,1.22l0.246,0.321l-1.271,0.593
          l0.07,1.167l1.123,1.398l-0.435,0.311l-0.109,1.509l0.342,2.436l0.503,0.804l0.014,0.081l-0.67,0.834l-0.289,1l-0.58,0.172
          l-0.438,1.069l0.42,0.857l-0.667,2.214l-0.228,0.064l-0.294-0.026l-0.82-0.623l-1.492-0.575l-0.92,0.016l-0.997,0.687l-0.591,1.014
          l0.318,1.925l-2.092,0.638l-1.154,1.29l-0.87,1.661l0.473,0.446l0.218,1.074l1.159,0.846l0.458,0.984l1.834,2.296l0.5-0.063
          l0.452,0.633l0.367,0.015l1.14,1.472l0.813,0.098l0.664,0.715l0.863-0.101l1.134,0.829l0.742-0.199l1.915,0.556l1.706-0.125
          l1.578-1.47l0.012-0.001l1.416,0.93l1.783,0.363l-0.017,1.676l-1.092,2.145l0.673,1.111l0.977,0.203l0.441,0.646l0.461,0.082
          l0.537,0.795h0.809l0.588,0.358l1.249-0.166l0.709,0.349l0.305,0.314l0.297,1.146l0.538,0.032l0.569,0.588l0.421,0.051l0.891,0.97
          l0.78-0.08l0.348,0.175l3.027,0.479l0.401-0.397l0.68-0.068l-0.037,0.137l0.056,0.212l-0.265,0.31l0.077,0.272l-0.487,0.378
          l0.767,0.882l-0.121,0.48l1.533,1.624l1.256,0.021l1.23,1.012l3.482,1.712l0.296-2.441l-0.594-1.711l-0.608-0.289l-0.257-0.583
          l-1.027-0.443l-1.436-1.393l-0.482-0.023l-0.335-0.34l-0.705-0.154l-0.994-1.008l-0.343,0.108l0.292-0.479l-1.274-1.668
          l-0.116-0.287l0.133-0.193l0.2,0.19l0.67-0.296l0.615,0.257l2.376,0.311l1.023-0.2l0.543-0.717l0.306,0.298l0.916-1.222
          l-0.333-1.532l-1.456-2.206l0.083-0.126l-0.597-1.063l-1.645-0.538l-0.883-0.491l-0.523-0.494l-1.705-2.488l-0.193-0.837
          l0.214-1.253l-0.247-0.792l-0.311-0.303l0.283-0.583l-0.274-1.424l-1.528,0.104l-0.254,0.288l-0.676-0.879l-1.351,1.098
          l-0.482,0.036l-1.899-1.627l-1.082-2.011l0.24-0.875l-0.539-1.594l1.865-2.165l-1.068-3.244l-1.086-0.178l-0.399,0.416
          l-0.061-0.152l-0.424-0.172l-0.269-0.674l-1.478-0.425l-0.271,0.007l-1.104-2.261l-0.548-1.626l-0.918-1.263l-0.951-2.122
          l-0.054-0.099l0.39,0.192l0.338,0.909l0.779,0.176l2.165,1.527l0.638,0.807l2.096,1.771l1.718,2.506l1.964,0.649l0.41,0.298
          l0.284,1.064l0.882,0.245l1.219,1.317l1.047,0.765l2.042,0.846l1.621,0.245l0.341,0.399l1.637,0.494l1.52,1.192l0.809-0.117
          l0.712,0.561l0.064,1.154l0.267,0.816l0.703,0.899l0.307,1.857l1.182,1.764l0.476,1.057l0.973,3.109l0.051,1.067l1.103,1.891
          l0.174,0.457l-0.397,0.942l0.468,1.239l0.764,0.482l0.837,0.892l-0.023,0.51l0.243,0.435l-0.104,0.409l0.353,0.837l0.048,0.947
          l-0.478,1.018l0.055,1.583l-0.372,2.06l-1.165,1.503l0.087,0.685L360,326.756l-0.42,3.532l-0.073,0.282l-0.068-0.017l-0.769,1.019
          l0.091,0.991l-0.319,0.731l0.344,0.965l-0.556,1.923l0.285,0.737l-0.125,0.298l-0.146,2.192l0.047,1.252l0.373,0.716l-0.185,2.056
          l0.236,1.342l1.167,2.8l-0.705,1.023l0.396,0.643l-0.653,0.762l0.655,1.658l0.291,1.98l0.7,1.2l0.683,0.622l-0.032,0.694
          l0.969,1.117l0.324,0.153l-0.181,0.36l0.392,1.028l1.06,1.393l0.307,0.785l0.767,0.411l0.181,0.411l0.442,0.404l0.16,0.731
          l0.324,0.053l-0.014,0.091l0.233,0.672l-0.03,0.584l0.542,1.892l0.718,0.645l-0.118,0.301l1.607,2.864l0.352,0.201l0.18,0.536
          l0.631,0.425l0.471,0.663l1.347,1.041l0.986-0.011l3.221,3.153l0.743,1.317l1.884,0.902l1.16,1.255l1.14,2.608l0.61,0.578
          l0.25,0.615l0.506,0.313l0.189,0.616l1.797,1.646l0.838,0.183l0.479,0.626l0.332,0.128l0.41,1.12l0.628-0.074l0.114,0.551
          l1.03,1.146l1.838,0.784l3.098,4.636l1.202,1.146l0.326,0.981l0.545,0.287l1.81,1.953l0.98,0.127l0.548,0.299l0.768,0.78
          l1.198,0.643l2.869,2.066l1.151,0.188l0.588,0.359l3.524,1.318l1.879,1.57l1.709,2.423l1.31,0.955l1.101,0.375l0.349-0.183
          l0.267,0.818l0.707,0.475l0.846,0.136l1.832-0.233l0.274-0.227l1.894,1.223l-0.084,0.543l-1.013,0.038l-0.443-0.322l-2.179-0.298
          l-0.261-0.354l-1.983-0.243l-1.318-0.795l-1.438-0.119l-1.553,0.859l-1.23-0.181l-1.347-1.014l-1.033-0.415l-0.76-0.736
          l-2.204-1.242l-0.549-0.685l-0.319-0.123l0.277-0.331l-0.763-1.382l-0.814,0.067l-2.014-2.003l-1.373-0.267L397.703,403
          l0.174-0.312l-0.677-1.208l-1.182,0.285l-0.104,0.186l-0.214-0.036v-0.366l-2.642-3.313l-0.475-0.201l-2.654-2.438l-1.48,0.198
          l-0.144-0.152l-0.416-1.282l-0.52-0.396l-0.178-0.953l-0.484-0.533l-1.386-0.446l-0.65-0.61l-0.142-0.623l-1.546-1.686
          l-0.777-1.598l-1.649-0.931l-0.791-0.882l-2.442-1.59l-0.113-0.158l0.02,0.015l1.581-0.215l-0.275-1.4l-0.893-0.798l-0.062-0.451
          l-0.412-0.456l-0.095-0.632l-0.839-0.878l0.005-0.502l-0.72-1.039l-0.419-0.237l-0.114-0.906l-0.564-0.083l-0.323-0.548
          l-0.809,0.246l-1.774-0.891l-1.194-0.265l-0.247-0.272l-0.18-0.93l-1.915-1.819l-0.119-0.268l0.038-0.782l-1.144-2.922l-0.63,0.149
          l-0.228-0.378l-0.792-0.404l-0.381-1.081l-0.828-0.245l-0.511-0.946l-0.823,0.331l0.15-0.738l-0.987-0.962l-0.831,0.625
          l-1.264-0.091l-1.392,0.396l-0.454,0.969l0.68,1.638l-0.335-0.551l-1.194-1.136l-0.653-0.352l-0.088-0.781l-0.676-0.784
          l-1.709-1.429l-1.298-1.425l-0.923-1.457l-0.472-1.377l-0.579-0.323l-2.791-4.043l-0.985-0.44l-0.682-1.068l-1.695-0.833
          l-0.093,1.948l0.604,1.972l2.825,4.39l0.472,0.093l0.557,1.911l1.185,1.374l0.451,0.304l0.123,0.312l0.903,1.164l0.521,0.361
          l0.266,0.841l1.792,1.801l0.608,1.417l1.509,0.727l0.673,0.615l-0.05,0.536l0.733,0.94l0.325,0.275l-0.31,0.726l1.397,3.293
          l1.094,0.314l0.277,0.462l0.832,0.546l0.785,1.666l0.048,0.312l-0.884,0.136l-0.882,1.203l0.65,1.008l3.214,3.155l1.046,0.396
          l1.424-0.071l0.913,0.797l0.681,0.09l3.953,4.411l0.139,0.752l1.06,1.59l-0.24,0.793l0.377,0.764l0.955,0.52l3.205,0.81
          l0.164-1.271l1.504,0.807l1.208,1.276l-1.438,0.113l-0.521,1.141l-0.049,1.797l0.353,0.479l-0.196,2.137l1.53,2.246l-0.619,0.36
          l-0.209-0.072l-1.823-1.27h-1.117l-0.548-0.641l-1.346-0.136l-0.848-0.729l-1.92,0.972l0.274,2.324l0.577,1.784l0.753,0.56
          l0.584,1.102l0.967,0.758l2.357-0.35l1.203,0.307l0.299-1.29l-0.133-0.396l0.558-0.006l0.423,0.296l1.187,0.351l0.4,1.411
          l0.61,0.24l-0.292,0.628l-0.747,0.229l-0.491,0.879l0.632,1.068l0.572,0.229l-0.302,0.292l1.076,0.688l-0.068,0.463l0.647,0.774
          l0.354,0.307l0.683-0.1l0.561,0.337l0.248,0.624l0.677,0.033l-0.333,0.346l0.207,0.411l-0.558,0.685l0.286,0.896l-0.036,0.354
          l-0.198,0.501l-0.715,0.155l-0.745,1.166l1.421,1.242l0.385,0.719l1.187,1.251l0.73,0.188l2.589,2.893l0.317,0.493l-0.148,0.066
          l0.093,0.382l-0.378,0.23l0.046,1.398l1.063,0.218l0.471,0.696l0.011,0.006l-0.265,0.397l0.967,1.373l0.562,0.222l1.817,2.271
          l0.91-0.507l0.354,0.189l1.542,2.653l-0.112,0.543l0.407,0.862l1.183,1.368l0.269,1.234l0.732,0.369l0.293,0.554l1.231,0.324
          l0.463-0.125l0.854,0.225l0.922,0.633l0.381,0.471l0.046,0.718l0.952,0.479l0.562,0.586l0.285,0.69l1.869,2.429l1.181,1.874
          l-0.235,0.89l-0.6-0.407l-0.424-0.67l-0.214-0.231l-0.681-0.282l-0.962,0.372l-0.68-1.124l-4.199-3.08l-1.019-0.505l-0.284-0.276
          l-0.502-1.032l-0.932-0.008l-0.659-0.391l-0.022-0.196l-1.296-0.697l-0.115-0.604l-0.179-0.146l0.273-0.359l-1.57-1.997
          l-0.845-0.237l-1.046-1.114l-1.625-1.135l-1.111-2.019l-0.464-0.348l0.061-0.099l-0.183-0.366l0.328-0.373l-0.774-1.04
          l-1.537-0.591l-0.597-0.667l-0.992-0.192l-0.023,0.017l-0.295-0.659l-0.885-0.079l-1.239-1.71l-1.19-0.059l-0.148-0.135
          l-0.361-0.832l-0.803-0.474l-0.287-0.654l-0.891-0.146l-0.586-0.904l-1.428-1.271l-1.638,0.246l0.013,0.564l-0.405-0.111
          l-1.543-1.781l-2.43,0.385l0.554,0.83l-0.36-0.295l-0.933-0.077l-0.315-0.368l-0.355-1.016l-2.288-2.443l-1.054-0.743l-0.453-0.742
          l-1.131-0.248l-0.793,0.999l0.214,0.727l-0.157,0.762l2.698,3.068l0.574,1.677l1.111,0.696l1.552,2.162l0.905,0.395l0.393-0.105
          l1.373,1.562l1.054-0.131l1.234-1.467l-0.557-1.278l1.013,0.487l-0.25,0.092l1.728,1.34l0.011,0.544l1.387,0.739l0.2,0.69
          l0.506,0.179l0.174,0.621l1.883,0.41l-0.053,0.577l0.62,0.688l0.29,1.099l0.721,0.413l1.048,1.5l0.407,0.184l0.151,0.526
          l2.15,1.713l1.846,2.256l1.655,1.204l0.645-0.779l0.775,0.687l0.228-0.544l0.094,0.94l0.876,0.083l0.097,0.185l0.038,0.018
          l-0.295,1.311l0.515,0.16l-0.637,0.759h2.103l0.587,0.498l0.211-0.022l-0.112,0.448l1.868,2.285l1.508,1.484l0.807,2.078
          l0.783,0.766l1.292,0.665l0.813,0.604l0.318,0.712l0.803,0.448l0.256,0.555l0.456,0.334l0.414,1.319l0.787,0.926l1.208,0.751
          l0.409-0.381l1.047,1.055l-0.128,1.062l1.118,1.542l1.521-0.354l0.137,0.146l0.248,1.722l0.194-0.162l3.352,2.302l-0.083-2.558
          l-0.693-0.573l-1.188-0.375l0.64-1.514l0.663,0.369l1.206-0.107l0.36-1.632l-0.251-0.916l-0.957-1.042l-2.418-1.989l-2.42-1.722
          l-0.563-1.203l-0.012-0.313l0.657,0.523l1.125-0.095l0.605-0.965l0.6,0.401l0.5,0.614l0.476,0.677l0.212,1.022l0.659,0.598
          l0.688,1.311l1.355,1.348l0.942-0.187l0.96,0.258l0.294,0.55l1.486,0.252l1.245,1.062l1.137,2.172l2.176,1.708l0.061,0.226
          l-2.095-0.775l-0.854,1.222l1.877,1.923l1.394-0.061l-0.012-1.415l0.587,0.394l0.885-0.114l0.333,0.398l0.715,0.502l0.346,0.774
          l0.304,0.094l-0.439,1.089l-0.591-0.778l-0.852-0.393l-0.859,0.583l-0.244,1.239l1.484,2.274l0.129,0.729l2.401,2.494l0.804,0.047
          l0.57,1.129l0.136,0.859l0.71,0.653l0.675-0.029l2.184,1.706l0.594-0.51l0.865,0.615l0.738-1.557l-1.76-2.551l-0.132-0.046
          l0.103-0.194l-0.103-0.495l0.213,0.032l0.326,0.188l0.781,1.103l0.725,0.23l0.337,0.905l0.528,0.469l0.717,1.752l0.903-0.729
          l1.041,0.662l0.828-0.056l0.416,0.599l2.02,0.872l0.343-0.108l0.451,0.28l1.03-0.188l0.479,0.899l0.18-0.059l-0.063,0.508
          l0.638,0.543l0.167,0.397l-0.253,0.481l-1.394-1.34l-1.484-0.293l-0.494,1.392l0.381,0.917l0.21,0.207l-1.851,2.025l-0.114,0.891
          l0.094,0.229l-0.999,0.01l-0.593-0.737l-0.086-0.858l-1.03-0.912l-0.128-0.016l-0.165-1.427l-1.23,0.468l-1.195-0.324l-1.111,0.618
          l0.498,1.15l0.876,0.224l0.084,0.111l-0.664,0.743l0.857,0.761l-1.118-0.149l-1.213-1.096l-1.307-0.299l-0.306,1.596l1.409,1.485
          l1.305,0.799l1,1.06l2.023-0.14l0.187-0.424l0.34,0.147l1.108,1.145l1.823-0.257l-0.492-1.097l0.309-0.39l-0.109-0.289l0.463-0.594
          l0.083,0.201l0.592,0.101l0.382,0.822l1.232-0.124l0.648,0.447l1.057-0.129l0.529-1.294l-0.329-0.818l-0.881-1.038l-0.108-0.108
          l1.071,0.063l0.347-0.894l0.959,0.187l-0.509-1.336l0.356,0.479l1.694-0.191l-0.083-0.264l0.087,0.027l0.464-1.193l-0.949-1.478
          l0.619,0.361l0.44,0.475l3.027,1.706l-0.216,0.398l3.18,3.084l1.089,0.218l0.336-0.431l0.878,0.829l0.24,0.396l-0.598,0.548
          l0.173,0.462l-0.542,0.804l2.227,0.708l0.618-0.363l0.829,0.457l0.156,0.626l0.199,0.094l-0.127,0.184l0.028,0.027l-0.287-0.208
          l-1.238,0.25l-0.294-0.166l-0.576,0.485l-0.542-0.15l-1.607,1.201l2.428,1.333l-1.133,0.884l0.808,0.881l-0.14,0.778l0.639,0.573
          l-0.153,0.495l-0.084,1.113l0.267,0.546l-0.301,1.409l0.701,0.458l-0.956,0.598l0.083,1.292l1.037,0.749l-0.549,0.797l0.457,1.186
          l0.877,1.369l0.906-0.176l0.175,0.219l0.934,0.522l-0.375,1.048l0.252,0.557l-0.866,0.083l0.778,1.075l-0.396-0.042l0.139,1.513
          l0.684,0.343l0.544,1.305l0.564-0.459l0.448,0.796l1.277-0.324l0.323-0.607l0.232,0.147l-0.148,0.306l0.949,1.812l1.205-0.962
          l0.047,0.188l0.883,0.596l1.042-0.316l0.044-0.124l0.138,1.676l1.393-0.256l0.479,0.374l0.881,0.178l1.121-0.694l0.058-0.188
          l0.134,0.059l0.181-0.057l0.171,0.606l1.001,0.143l0.452,0.479l0.878-0.116l1.638,0.294l1.788-0.511l-0.415-0.596l0.892-0.606
          l-0.073-0.057l0.496-0.057l1.137,0.454l0.556-0.623l0.998-0.139l1.526-1.202l-1.09-1.459l-1.165-0.664l0.542-0.172l0.341,0.118
          l0.644,0.746l1.078,0.062l0.641-0.429l0.193,0.006l-1.005,1.34l1.175,0.57l0.078,0.775l1.302,0.085l1.666,0.504l1.021,1.184
          l0.573-0.743l0.645,0.127l0.172-0.459l0.058,0.014l1.092-0.233l0.606-0.454l0.276,0.319l1.33-0.09l1.26,0.607l1.755-0.113
          l0.848,0.196l2.141,0.088l3.584-0.538l1.008-1.971l-2.208-0.005l-0.217-0.367l-0.899-0.349l-0.486,0.328l-0.369-0.356l-0.94-0.315
          l-0.358-0.572l-2.84-1.351l-3.175-0.42l1.055-0.165l1.338-0.802l0.468-0.672l0.475-0.127l0.78-0.504l0.975-0.201l1.001-0.553
          l1.377-0.268l2.48,0.515l1.531,0.055l0.57,0.293l1.762-0.015l0.569,0.125l0.571,0.493h-0.806l-0.132,0.256l-0.731-0.015
          l-0.439,1.009l0.232,0.505l-1.702,0.056l-1.093,0.738l-0.745,0.974l0.669,1.104l1.043-0.229l0.545,0.417l1.533,0.193l0.836,0.402
          l3.962-0.222l1.234-0.329l3.935,0.64l0.526,0.328l1.156,1.658l4.649,3.396l1.185,0.179l1.229,0.668l0.318,0.847l1.121,0.466
          l0.813,0.962l1.039-0.577l2.883,0.172l0.61-0.271l0.859,1.029l0.316,1.002l0.594,0.52l0.398,0.753l2.794,0.922l5.623,0.896
          l1.314-0.022l1.405,0.518l1.093-0.173l1.525,0.448l1.892,0.223l0.706-0.32l0.312,0.655l1.165,1.241l1.332,0.995l0.644,0.91
          l1.919,1.377l0.461,0.548l0.253,1.003l0.55,0.771l2.125,2.153l1.104,1.441l1.764,1.526l0.421,0.521l-0.021,1.138l0.579,0.733
          l2.012,1.595l0.966,0.443l0.67,0.796l0.313,0.582l0.354,1.554l1.003,0.856l0.29,1.087l2.551,1.229l0.184,1.141l0.48,0.462
          l0.273,1.029l3.062,2.263l0.459-0.541l0.684,0.589l0.704,1.597l0.84,0.325l0.601,0.618l1.296,0.419l1.168,0.058l1.642,0.76
          l0.833,0.92l0.7-0.043l0.463,0.435l1.03,0.183l1.14,0.82l0.64,0.707l0.872,0.516l0.234,0.864l0.845,0.276l0.832,0.771l1.111-0.014
          l2.194,1.299l0.222,0.529l0.858,0.795l0.682,1.91l0.979,0.762l0.821,1.05l0.654,1.396l0.654,0.172l0.136,0.342l0.541,0.109
          l0.411,0.828l1.343,1.853l1.421-0.086l0.378,1.146l0.803,0.154l0.102,0.377l1.112,0.944l0.216-0.16l1.106,0.237l-0.286,0.663
          l0.353,1.765l0.489,0.274l-0.104,0.064l0.895,1.783l0.946,0.682l0.234,0.856l1.01,1.173l0.416,0.102l0.187,0.573l1.52,1.718
          l1.098,0.434l0.669,0.663l0.831,0.157l0.552-0.389l0.8,0.42l0.523-0.312l0.795,0.16l1.043,1.033l4.698-1.492l1.672-1.518
          l7.912-3.392l-0.343-0.707c-1.066-2.201-2.254-4.88-2.311-5.341c0-0.61-1.531-4.034-4.552-10.175l-0.101-0.205l-0.197-0.113
          c-0.796-0.459-7.793-4.495-8.389-4.791c-0.454-0.238-5.446-3.631-8.521-5.736l-1.908-1.774c-0.204-0.617-0.33-1.299-0.277-1.479
          c0.559-1.111-1.22-4.184-1.779-4.743c-0.62-0.62-2.23-1.925-3.895-2.425c-1.249-0.376-3.048-0.644-3.729-0.736l-1.818-1.949
          c-0.235-0.528-1.185-2.659-1.454-3.194c-0.287-0.576-3.639-7.013-4.006-7.718l-1.598-4.793l-0.098-0.776
          c0.595-0.654,2.033-2.292,2.438-3.237c0.518-1.212,0.959-3.412,0.803-4.994c-0.198-1.998-2.093-3.265-2.717-3.683l-0.344-0.23
          c-0.981-0.665-2.465-1.67-3.79-1.966c-1.337-0.295-2.24-0.34-3.025-0.144c-0.547,0.136-1.217,0.675-1.643,1.062l-1.979-0.697
          c-0.761-0.631-2.532-2.143-3.079-2.799c-0.763-0.918-1.94-2.094-2.582-2.734c-0.645-0.649-2.349-1.648-2.541-1.761l-2.987-1.062
          l-1.494-1.221l-2.579-2.864l-1.594-3.192l-3.771-3.534c-0.255-0.608-1.107-2.591-1.684-3.311c-0.313-0.391-0.89-0.744-1.557-1.153
          c-0.53-0.325-1.634-1.002-1.718-1.318c-0.163-1.15-0.934-2.699-0.966-2.765l-0.074-0.148l-4.329-3.434
          c-0.399-0.775-2.006-3.741-3.538-4.508c-1.271-0.635-3.258-0.962-4.069-1.074l-2.197-3.434l-0.062-0.084
          c-0.032-0.038-0.784-0.96-0.437-2.475c0.521-2.255-0.038-3.53-2.604-5.936c-0.908-0.852-2.042-1.762-3.14-2.643
          c-1.736-1.394-3.532-2.835-4.024-3.733c-0.898-1.648-1.948-3.447-1.959-3.467l-0.879-1.408l-3.096-1.18l-1.855-3.697l-2.344-3.518
          l-1.493-3.647l-5.354-0.321c-0.241-0.027-0.621-0.149-0.674-0.152v-2.425l0.51-1.703l-1.773-2.952l-0.498,0.076
          c-1.88,0.288-3.863,0.343-4.241,0.118l-0.294-0.194c-0.735-0.482-0.864-0.605-0.864-0.823c0-0.052,0.026-0.111,0.071-0.213
          c0.089-0.199,0.254-0.569,0.068-0.994c-0.207-0.471-0.724-0.722-1.546-0.996l-0.406-0.133c-1.726-0.565-3.04-1.065-2.961-1.922
          c0.125-1.391-0.519-2.473-0.945-3.188c-0.104-0.174-0.246-0.412-0.269-0.493c-0.192-0.771-0.729-1.49-1.739-2.332
          c-0.414-0.345-1.332-1.482-2.07-2.397c-0.945-1.171-1.497-1.847-1.838-2.102c-0.353-0.264-1.317-1.192-1.979-1.843l1.045-1.307
          l-1.513-3.779l-1.665-0.279c-0.159-1.137-0.511-3.767-0.511-4.869c0-1.106,0.485-2.035,0.914-2.854l0.201-0.39
          c0.441-0.887,1.329-2.508,1.33-2.51l0.074-0.198c0.016-0.071,0.372-1.758-0.799-2.929c-0.862-0.864-2.426-1.813-2.773-2.019
          l-1.517-1.35l-2.743,0.132l-0.107-0.855h1.686c1.102,0,2.242-0.727,2.242-1.942c0-0.935-1.18-2.332-1.687-2.891l-0.342-0.376
          l-0.845,0.317c-0.071-0.488-0.095-0.999,0-1.287c0.352-1.048-0.048-2.771-0.132-3.105c-0.013-0.051-0.322-1.274-1.23-3.395
          c-0.28-0.653-0.467-1.224-0.647-1.776c-0.387-1.182-0.72-2.202-1.749-3.233c-1.178-1.176-2.101-1.452-2.775-1.654
          c-0.161-0.048-0.312-0.093-0.459-0.151c-0.655-0.262-1.635-0.412-2.073-0.47c-0.791-0.607-2.468-1.918-2.895-2.347
          c-0.421-0.421-0.695-0.979-0.84-1.707c-0.022-0.108-0.052-0.231-0.084-0.363c-0.06-0.243-0.201-0.813-0.154-0.941
          c0,0,0.062-0.035,0.249-0.035c1.071,0,2.69,0,2.689-1.481c0.025-0.23,0.458-1.027,0.908-1.671l0.172-0.247l-0.399-2.592
          l-0.311-0.18c-0.307-0.179-0.781-0.581-0.833-0.84c-0.23-1.151-0.976-3.806-2.765-3.58c-1.038,0.129-1.178,0.009-1.56-0.471
          c-0.357-0.446-0.833-0.711-1.252-0.944c-0.405-0.226-0.755-0.421-0.9-0.712c-0.22-0.438-0.361-0.884-0.498-1.316
          c-0.122-0.386-0.237-0.75-0.398-1.068c-0.193-0.387-0.528-1.494-0.694-2.101l-0.161-0.589l-2.383,0.148
          c-0.215-0.724-0.638-1.946-1.284-2.913c-0.955-1.43-2.206-2.38-2.259-2.42l-0.424-0.32l-0.442,0.294
          c-0.12,0.08-1.174,0.812-1.37,1.993c-0.048,0.29-0.267,0.523-0.255,0.564c0,0-0.052-0.037-0.124-0.179
          c-0.312-0.624-0.723-0.956-1.219-0.986c-0.828-0.041-1.357,0.739-1.868,1.504c-0.313,0.471-0.629,0.902-0.851,1.196
          c-0.398-0.133-0.768-0.306-0.904-0.431c0.003-0.087,0.009-0.18,0.015-0.275c0.046-0.742,0.134-2.124-1.218-2.704
          c-0.916-0.392-1.55-0.905-2.057-1.664c-0.502-0.753-2.759-4.356-3.398-5.379l0.301-1.197l-0.969-1.506l1.703-0.473l4.557-2.992
          l-3.832-3.834l0.134-1.753l-2.048-5.849l0.893-2.231l-0.087-0.26c-0.131-0.396-0.221-1.093-0.067-1.331
          c0.014-0.021,0.039-0.059,0.175-0.073c1.816-0.18,2.972-1.155,3.344-2.821c0.042-0.189,0.084-0.363,0.123-0.525
          c0.248-1.028,0.462-1.916-0.47-2.848c-0.395-0.436-1.45-3.503-2.202-6.096l-0.047-0.162l-0.733-0.826l1.925-1.283l-0.08-0.479
          c-0.083-0.491-0.098-1.546,0.236-1.88c0.802-0.801,1.717-2.317,1.755-2.381l0.64-1.677l-0.212-0.319
          c-0.542-0.813-1.169-1.873-1.239-2.151c-0.12-0.48-0.778-2.755-1.066-3.748l1.038-0.907l0.309-1.999
          c0.688-0.794,1.766-1.989,2.114-2.252c0.179-0.085,0.858,0.032,1.374,0.237l0.406,0.161l1.56-1.249l-0.423-0.844l0.375-0.25
          l1.221,0.812l1.639-0.49l2.43,0.559l0.489-1.63h1.18l0.213,0.255c-0.129,0.356-0.207,0.822,0.021,1.232
          c0.125,0.225,0.44,0.601,1.16,0.601c1.324,0,2.221-0.431,2.924-0.853c0.182-0.11,0.39-0.266,0.613-0.436
          c0.784-0.598,1.239-0.86,1.546-0.64c0.851,0.608,1.959,1.203,2.421,1.443l0.723,1.343l-1.295,2.019l0.563,1.695l2.897,0.291
          c0.131,0.596,0.416,1.485,1.009,2.079c0.883,0.884,2.562,3.566,2.471,4.298c-0.15,1.21-0.205,2.392,0.766,3.556
          c0.121,0.145,0.225,0.277,0.32,0.397c0.507,0.645,0.991,1.256,2.167,1.056c0.538-0.088,2.102,0.451,3.262,1.466
          c1.175,1.025,1.451,1.483,1.549,1.967c0.031,0.157,0.78,3.847,2.19,4.554c0.554,0.276,1.054,0.298,1.456,0.315
          c0.396,0.018,0.594,0.033,0.796,0.193c0.622,0.498,1.155,1.421,1.16,1.43l0.143,0.249l0.272,0.09
          c0.111,0.036,1.111,0.352,2.023,0.172l0.237-0.049c1.42-0.301,1.726-0.162,1.781-0.052c0.487,0.971,1.699,2.341,1.75,2.398
          l0.28,0.316l0.415-0.076c1.292-0.235,3.436-0.585,4.042-0.585c1.04,0,1.748-0.337,1.825-0.375l0.354-0.176l0.056-0.391
          c0.006-0.044,0.156-1.11,0.156-2.343c0-1.037,0.095-1.453,0.721-2.206c0.714-0.856,1.734-1.877,1.745-1.887l0.208-0.208
          l0.149-3.896l1.917-2.097l-0.464-0.507c-0.291-0.317-0.651-0.92-0.571-1.227c0.05-0.193,0.369-0.325,0.555-0.387
          c0.701-0.233,1.328-0.392,1.882-0.531c1.27-0.32,2.272-0.573,2.729-1.483c0.106-0.211,0.295-0.493,0.515-0.826
          c0.76-1.152,1.802-2.729,1.802-4.283c0-1.356,0.711-2.666,1.093-3.37c0.069-0.126,0.127-0.235,0.172-0.323
          c0.052-0.104,0.116-0.241,0.191-0.402c0.296-0.63,0.91-1.942,1.395-2.33c0.384-0.307,1.523-0.752,2.396-1.05
          c0.472,0.503,1.386,1.161,2.789,0.905c1.252-0.227,2.334-0.836,2.897-1.201c0.97,0.538,2.196,1.301,2.498,1.678
          c0.664,0.829,3.697,1.906,4.971,2.331c0.318,0.105,3.146,0.99,4.762-0.45c1.146-1.017,2.781-0.18,3.431,0.227
          c1.006,0.627,2.604,1.219,3.66,1.609c0.262,0.097,0.481,0.178,0.637,0.241c0.922,0.367,2.668-0.121,3.181-0.278l0.115-0.046
          c0.487-0.243,1.312-1.036,1.312-2.462c0-0.496-0.098-1.005-0.184-1.455c-0.001-0.007-0.003-0.014-0.004-0.021
          c1.12,0.551,1.902,0.616,2.889-0.176c0.675-0.54,0.49-1.268,0.332-1.678c0.328-0.085,0.576-0.255,0.74-0.507
          c0.248-0.383,0.201-0.796,0.113-1.131c0.158-0.051,0.282-0.127,0.373-0.2c0.347-0.278,0.476-0.686,0.539-1.083
          c0.349,0.275,0.826,0.526,1.316,0.347c0.698-0.252,0.771-1.036,0.835-1.728c0.015-0.155,0.028-0.313,0.05-0.464
          c0.058-0.404,0.66-1.062,0.992-1.187c0.003,0.008,0.006,0.018,0.009,0.028c0.374,1.248,0.614,1.573,1.104,1.862
          c0.034,0.02,0.072,0.041,0.117,0.076c0.082,0.065,0.126,0.143,0.089,0.155c-0.557,0.419-1.49,1.286-1.49,2.391
          c0,0.871,0.203,1.708,1.678,1.933c1.54,0.241,2.43,0.09,2.875-0.491c0.033-0.043,0.069-0.097,0.104-0.16
          c0.212,0.879,0.609,1.372,1.189,1.47c0.589,0.101,1.12-0.029,1.491-0.366c0.259-0.234,0.556-0.68,0.5-1.471
          c-0.018-0.24-0.014-0.402-0.005-0.509c0.327,0.087,0.632,0.278,0.758,0.437c0.062,0.076,0.135,0.157,0.217,0.247
          c0.313,0.343,0.702,0.769,0.923,1.756c0.298,1.349,0.555,2.513,1.933,2.742c0.479,0.08,0.714,0.341,1.087,0.797l0.259,0.309
          c0.546,0.643,1.057,1.121,2.665,1.426c1.346,0.258,2.115,0.804,2.637,1.252c0.507,0.433,1.307,0.834,1.579,0.965
          c0.835,0.662,3.287,2.523,4.667,2.34c0.742-0.091,1.979,0.016,2.798,0.088c0.435,0.038,0.79,0.067,0.99,0.067
          c0.025,0,2.587-0.015,2.958-1.291l0.035-0.125c0.777-2.74,1.085-3.675,1.207-3.979c0.004-0.009,0.007-0.018,0.011-0.026
          c0.357,0.753,0.707,1.244,1.977,1.244l0.245-0.001c0.665-0.004,1.68-0.013,2.074,0.379c0.053,0.052,0.213,0.211,0.213,0.664
          c0,2.341,1.35,3.006,2.054,3.354c0.3,0.15,6.929,2.153,8.473,0.607c1.1-1.096,1.651-1.646,1.832-2.58
          c0.044-0.073,0.226-0.162,0.267-0.166c0.001,0.001,0.085,0.119,0.055,0.518c-0.048,0.627-0.163,1.132-0.265,1.577
          c-0.19,0.838-0.429,1.88,0.678,2.435c0.119,0.059,0.218,0.113,0.303,0.159c1.002,0.542,1.378,0.227,2.203-0.6
          c0.227-0.227,0.463-0.423,0.676-0.578c-0.166,0.389-0.395,0.782-0.589,1.116c-0.427,0.734-0.795,1.368-0.367,1.926
          c0.418,0.543,1.16,0.342,1.438,0.266l0.479-0.134c0.666-0.188,0.666-0.188,1.191,0.032c0.148,0.062,0.324,0.137,0.54,0.223
          c1.209,0.484,1.999,0.144,2.361-0.102c0.566-0.383,0.905-1.052,0.905-1.789c0-0.063,0.016-0.143,0.041-0.23
          c0.085,0.904,0.222,1.455,0.699,1.727c0.506,0.287,0.982,0.046,1.528-0.229l0.246-0.125c0.915-0.472,1.196-0.542,1.573-0.164
          c0.463,0.464,1.111,0.684,1.818,0.61c0.938-0.092,1.952-0.697,2.468-1.47c0.3-0.45,1.609-1.528,2.783-1.574
          c0.522-0.02,0.899,0.163,1.223,0.575c1.101,1.4,2.104,1.956,2.909,2.402c0.474,0.262,0.848,0.47,1.178,0.8
          c1.607,1.604,2.892,2.324,3.811,2.142c0.604-0.121,2.031,0.067,3.114,1.631c1.232,1.779,2.354,2.794,3.75,3.395
          c1.294,0.556,4.32,0.371,5.6-0.905c1.045-1.044,2.613-2.159,3.088-2.396c1.688-0.848,1.796-2.138,1.589-3.071l-0.108-0.46
          c-0.119-0.493-0.435-1.801-0.222-2.037c0.017-0.01,0.106-0.037,0.281-0.022c2.6,0.214,3.489-1.08,3.773-2.207l0.067-0.273
          c0.249-1.029,0.531-2.195,1.379-2.479l0.271-0.092c0.487-0.166,0.994-0.336,1.18-0.244c0.052,0.025,0.234,0.155,0.435,0.758
          c0.556,1.664,1.067,2.611,1.874,2.564c0.813-0.051,1.003-1.002,1.064-1.313c0.105-0.525,0.703-1.054,1.253-1.111
          c0.148-0.013,0.594-0.059,0.902,0.664l0.312,0.717c0.232,0.527,0.472,1.071,0.486,1.167c-0.005,0.005-0.045,0.059-0.227,0.18
          c-0.312,0.207-1.258,0.835-1.105,1.721c0.094,0.545,0.543,0.922,1.339,1.122c1.118,0.279,1.235,0.475,1.409,1.001
          c0.242,0.725,0.629,1.2,1.185,1.453c0.626,0.284,1.43,0.256,2.451-0.084c1.762-0.587,1.896-0.423,2.159-0.094
          c0.593,0.743,2.021,0.8,2.615,0.794c0.568-0.009,2.492-0.125,3.342-1.261c0.174-0.23,0.358-0.456,0.539-0.675
          c0.826-1.006,1.763-2.146,0.977-3.324c-0.267-0.399-0.541-0.852-0.426-1.182c0.064-0.182,0.368-0.655,1.819-1.116
          c2.006-0.639,2.509-0.602,2.628-0.527c0.028,0.032,0.123,0.199,0.123,1.004c0,1.122,0.528,2.091,1.448,2.659
          c0.808,0.498,1.775,0.597,2.471,0.25c0.113-0.057,0.166-0.056,0.165-0.058c0.05,0.026,0.183,0.208,0.257,0.577
          c0.319,1.612,2.91,1.144,4.3,0.89l0.386-0.072c3.706-0.697,5.821-0.645,6.29,0.16c1.059,1.815,2.434,3.659,3.101,4.327
          c0.53,0.53,3.541,2.564,5.063,2.13c1.279-0.368,2.662-0.912,3.054-1.069c0.725-0.288,1.283-0.989,1.358-1.704
          c0.065-0.616-0.216-1.182-0.773-1.555c-0.206-0.138-0.412-0.254-0.602-0.36c-0.091-0.051-0.2-0.112-0.289-0.167
          c0.002-0.003,0.005-0.006,0.007-0.009l0.082-0.098c0.125-0.151,0.271-0.328,0.379-0.437c0.066,0.064,0.164,0.172,0.301,0.354
          c0.906,1.206,1.141,1.579,1.395,2.218c0.312,0.779,1.192,1.237,1.878,0.985c0.277-0.102,1.138-0.565,0.576-2.248
          c-0.133-0.397-0.212-0.771-0.254-1.083c0.832,1.15,1.66,1.657,2.466,1.49c0.744-0.151,1.29-0.843,1.502-1.942
          c0.069-0.021,0.301,0.047,0.401,0.205c0.07,0.111,0.015,0.268-0.165,0.467c-0.369,0.41-0.767,0.736-1.117,1.024
          c-0.625,0.512-1.164,0.954-1.038,1.638c0.129,0.702,0.907,0.962,1.282,1.087l0.349,0.118c1.211,0.409,2.255,0.764,2.98,0.161
          c0.616-0.511,0.571-1.38,0.484-1.99c-0.235-1.66,0.282-2.512,0.724-2.586c0.24-0.04,1.001,0.256,1.229,0.668
          c0.047,0.084,0.117,0.212-0.123,0.482c-1.331,1.497-1.504,2.477-0.661,3.746c0.136,0.205,0.265,0.481,0.4,0.773
          c0.376,0.809,1.008,2.16,2.369,1.77c0.581-0.163,1.198,0.399,1.951,1.152l0.281,0.273c0.833,0.796,0.938,1.032,0.655,1.483
          c-0.592,0.945-0.617,2.005-0.064,2.637c0.247,0.28,0.968,0.864,2.354,0.233c2.065-0.938,2.79-0.844,3.037-0.598
          c0.334,0.334,0.459,0.459-0.094,1.29c-0.825,1.236-0.389,2.78,0.297,3.816c0.343,0.513,0.704,1.434,0.801,1.966
          c-0.036-0.018-0.076-0.038-0.12-0.061c-2.03-1.083-3.166-0.681-3.662-0.344c-0.577,0.391-0.868,1.057-0.807,1.835
          c-0.063,0.724,0.09,1.378,0.282,2.2l0.19,0.835l0.195,0.164c0.128,0.107,0.274,0.219,0.426,0.333
          c0.247,0.187,0.527,0.398,0.675,0.57c0.081,0.096,0.151,0.22,0.226,0.342c0.14,0.23,0.298,0.492,0.518,0.699
          c0.83,0.788,1.791,1.482,2.72,2.153l0.783,0.563c0.057,0.039,0.117,0.074,0.165,0.121c0.52,0.503,1.069,1.044,1.599,1.658
          c0.195,0.248,0.325,0.412,0.53,0.476c0.272,0.104,0.562,0.229,0.861,0.358c0.677,0.292,1.378,0.593,2.046,0.75
          c3.163,0.737,8.602,0.459,12.571,0.257c0.728-0.038,1.396-0.072,1.977-0.095l0.833-0.034l-0.121-0.824
          c-0.237-1.614-1.008-2.916-1.752-4.175c-0.268-0.452-0.536-0.905-0.762-1.329c-0.008-0.041-0.002-0.167,0.003-0.25
          c0.009-0.189,0.021-0.435-0.028-0.699c1.459-1.375,2.47-0.722,4.049,0.502c0.97,0.751,1.971,1.526,3.13,1.281
          c1.779-0.378,1.894-1.428,1.977-2.195c0.074-0.676,0.149-1.375,1.301-2.238c0.168-0.126,0.381-0.248,0.598-0.374
          c0.414-0.241,0.843-0.491,1.143-0.81c0.036-0.038,0.07-0.078,0.136-0.156c0.104-0.011,0.243-0.006,0.376-0.003
          c0.335,0.008,0.753,0.021,1.159-0.162c0.683-0.307,0.783-0.735,0.716-1.087l0.004-0.053c0.213-0.134,0.503-0.309,0.628-0.38
          l0.731-0.521l-0.037-0.427c-0.019-0.218-0.093-0.372-0.173-0.484c0-0.014,0-0.029,0.001-0.046l0.002-0.092l-0.02-0.09
          c-0.08-0.357-0.309-0.56-0.683-0.605c0.003-0.046,0.006-0.093,0.009-0.14c0.081-1.36,0.09-1.729-0.215-2.045
          c-0.796-0.814-1.236-1.264-2.255-0.649c-0.119,0.071-0.269,0.162-0.467,0.259c-0.077,0.033-0.188,0.052-0.296,0.076
          c-0.34,0.076-0.691,0.154-0.944,0.415c-0.139,0.145-0.226,0.301-0.289,0.42c-0.039-0.012-0.081-0.026-0.115-0.038
          c-0.168-0.057-0.377-0.128-0.625-0.142c-0.117-0.004-0.264,0.006-0.413,0.022c-0.028,0.003-0.058,0.007-0.081,0.012
          c-0.344-0.305-0.785-0.549-1.247-0.805c-0.252-0.139-0.508-0.281-0.745-0.432c0.138-0.061,0.312-0.131,0.529-0.211l0.124-0.048
          c1.046-0.465,1.311-0.812,1.621-1.327c0.106-0.176,0.216-0.357,0.433-0.604l0.089-0.096c0.248-0.263,0.431-0.466,0.525-0.692
          l0.096-0.229l-0.113-0.455c-0.127-0.521-0.259-1.06-0.438-1.452c0.021-0.035,0.051-0.084,0.091-0.148l0.188-0.308l-0.159-0.439
          c0.044-0.191,0.072-0.508-0.166-0.797c-0.037-0.045-0.076-0.085-0.116-0.119c0.072-0.06,0.16-0.124,0.224-0.171
          c0.361-0.267,0.907-0.669,0.903-1.368c-0.003-0.636-0.492-1.169-1.017-1.592c0.096-0.277,0.289-0.693,0.407-0.947
          c0.386-0.832,0.619-1.333,0.43-1.795c-0.048-0.118-0.09-0.241-0.133-0.365c-0.127-0.371-0.271-0.792-0.561-1.165
          c-0.135-0.17-0.308-0.292-0.473-0.383c0.023-0.34-0.11-0.718-0.301-1.213c-0.034-0.088-0.065-0.169-0.085-0.227
          c-0.072-0.219-0.19-0.387-0.276-0.51c-0.021-0.028-0.042-0.055-0.06-0.084c-0.002-0.025-0.002-0.051-0.003-0.077
          c-0.01-0.223-0.02-0.476-0.223-0.714c-0.054-0.059-0.27-0.293-0.616-0.264c0.009-0.169,0.027-0.349,0.037-0.444
          c0.014-0.127,0.024-0.237,0.027-0.312c0.01-0.216-0.061-0.395-0.137-0.537c0.056-0.165,0.187-0.313,0.372-0.515
          c0.184-0.2,0.393-0.427,0.498-0.734c0.012-0.036,0.021-0.072,0.029-0.108c0.71,0.248,1.447,0.59,1.996,1.099
          c0.173,0.179,0.173,0.179,2.187,0.677l0.715,0.179l0.915-0.924c1.748-1.753,3.924-3.934,4.644-6.325l0.153-0.508l-0.428-0.313
          c-0.833-0.61-1.469-0.938-2.254-1.277c0.035-0.098,0.071-0.196,0.105-0.282l0.122,0.043l0.16-0.013
          c0.193-0.016,0.372-0.066,0.534-0.13c0.074,0.027,0.159,0.049,0.254,0.059c0.073,0.053,0.146,0.104,0.22,0.155
          c0.107,0.075,0.213,0.146,0.309,0.224c0.545,0.44,1.352,1.069,2.045,1.436c0.25,0.133,0.509,0.165,0.697,0.188
          c0.057,0.006,0.111,0.01,0.097-0.008c0.039,0.031,0.127,0.146,0.185,0.222c0.226,0.296,0.609,0.785,1.242,0.713
          c0.694-0.08,1.355,0.061,1.854,0.396c0.331,0.231,0.686,0.153,0.971,0.09c0.026-0.006,0.054-0.013,0.081-0.019
          c0.151,0.186,0.419,0.479,0.918,0.538c0.31,0.035,0.773,0.031,1.346-0.154c0.397,0.136,0.675-0.042,0.794-0.145
          c0.562-0.485,0.244-1.187-0.562-2.569c-0.032-0.055-0.099-0.165-0.181-0.3c-0.003-0.006-0.007-0.011-0.01-0.017
          c0.009-0.007,0.019-0.015,0.027-0.023c0.135-0.116,0.354-0.378,0.303-0.831c-0.023-0.214-0.092-0.389-0.177-0.536
          c0.326-0.03,0.689-0.093,1.08-0.162c0.21-0.037,0.481-0.084,0.697-0.117c-0.015,0.15-0.021,0.303,0.01,0.457
          c0.122,0.619,0.556,1.23,0.976,1.822c0.166,0.235,0.326,0.458,0.43,0.639l0.059,0.113c0.466,0.916,0.745,1.348,1.171,1.485
          l0.189,0.035c0.464,0.032,0.787-0.289,1.076-0.647c0.165,0.175,0.343,0.373,0.467,0.51c0.361,0.402,0.528,0.584,0.676,0.688
          l0.129,0.091l0.154,0.031c0.541,0.109,0.959,0.018,1.326-0.061c0.113-0.024,0.229-0.05,0.355-0.069
          c0.747-0.113,1.753-0.027,2.727,0.056c1.153,0.098,2.149,0.182,2.866-0.021l0.118-0.044c0.506-0.241,0.514-0.771,0.524-1.506
          c0-0.017,0-0.034,0-0.05c0.022-0.021,0.046-0.042,0.068-0.064c0.369-0.349,0.635-0.601,0.581-1.032L798.771,287.694z
           M251.193,324.489l-0.023-0.038l0.051-0.07L251.193,324.489z M251.496,323.315l-0.015-0.06l0.029,0.004L251.496,323.315z
           M349.173,322.244l0.097,0.099l0.705,0.154l0.434,0.441l0.502,0.024l1.128,1.094l0.837,0.415l0.245,0.554l0.544,0.258l0.274,0.789
          l-0.001,0.011l-1.364-0.662l-1.562-1.286l-1.15-0.021l-0.535-0.566l0.112-0.441l-0.241-0.277l0.032-0.024l-0.133-0.474
          L349.173,322.244z M378.145,406.345l-0.736,0.327l-0.615,0.717l0.118,0.352l-1.693,0.251l-0.209-0.164l-0.593-1.119l-0.664-0.493
          l-0.328-0.904l-0.167-1.403l0.09-0.046l0.445,0.383l1.217,0.122l0.605,0.709h1.337l1.521,1.06l0.492,0.2L378.145,406.345z
           M368.581,425.324l-0.498-1.456l-1.797-2.045l2.125,2.229l0.344,0.982l0.735,0.859L368.581,425.324z M373.123,429.27l-1.319-1.499
          l-0.778,0.209l-1.533-2.083l0.164,0.192l1.078,0.085l0.259,0.213l0.71,1.348l0.49,0.105l0.861,0.982l0.152,0.349L373.123,429.27z
           M436.47,488.072l-0.212-0.367l0.711,0.632L436.47,488.072z M442.419,487.695l-0.093-0.226l0.599,0.312L442.419,487.695z
           M443.233,488.358l-0.255-0.55l0.583,0.302l0.179,0.196L443.233,488.358z M443.032,486.146l-0.234-1.567l0.229,0.225l0.245,1.204
          l1.001,0.782L443.032,486.146z M751.461,218.519c-0.431,0.076-1.255-0.47-1.856-0.866c-0.251-0.165-0.492-0.323-0.717-0.454
          l-0.198-0.115l-0.229,0.016c-0.238,0.017-1.537,0.467-1.784,1.196c-0.118,0.349-0.019,0.719,0.26,0.965
          c0.241,0.212,0.396,0.387,0.54,0.548c0.266,0.296,0.54,0.603,1.124,0.971c0.307,0.195,0.627,0.352,0.945,0.506
          c0.488,0.237,0.909,0.441,1.247,0.786c0.176,0.18,0.256,0.414,0.357,0.71c0.097,0.285,0.207,0.607,0.423,0.933
          c0.352,0.527,0.366,0.789,0.344,0.838c-0.125,0.275-1.349,0.44-1.871,0.511c-0.712,0.096-1.327,0.179-1.768,0.427l-0.203,0.114
          l-0.103,0.209c-0.392,0.797-0.244,1.347-0.051,1.668l0.088,0.145l0.141,0.093c0.019,0.012,0.05,0.033,0.086,0.059v0.092
          l0.358,0.232c0.227,0.147,0.52,0.154,0.798,0.103c0.038,0.083,0.077,0.164,0.115,0.241c-0.461,0.29-1.079,0.11-1.562-0.125
          l-0.511-0.249l-0.377,0.424c-0.517,0.579-0.439,1.098-0.347,1.356c0.182,0.508,0.706,0.865,1.412,0.955
          c0.01,0.02,0.014,0.103-0.055,0.195c-0.066,0.085-0.342,0.354-1.172,0.214l-0.789-0.18l-0.076,0.804
          c-0.048,0.16-0.377,0.634-0.618,0.979c-0.718,1.032-1.46,2.098-1.235,3.023c0.092,0.379,0.331,0.681,0.69,0.871
          c0.383,0.204,0.905,0.291,1.41,0.374c0.214,0.036,0.416,0.067,0.569,0.108l0.612,0.164l0.135-0.296
          c0.122,0.027,0.264,0.06,0.307,0.081c0.094,0.059,0.122,0.116,0.137,0.188c0.075,0.366-0.214,1.091-0.737,1.846l-0.387,0.56
          l0.52,0.439c0.142,0.12,0.234,0.223,0.319,0.315c0.292,0.318,0.548,0.562,1.191,0.796c1.338,0.485,4.63,0.184,5.643-0.977
          c0.502-0.571,0.758-1.123,0.944-1.525c0.087-0.188,0.206-0.446,0.267-0.489c0.008-0.003,0.182-0.071,0.899,0.163
          c0.185,0.06,0.245,0.176,0.32,0.882c0.068,0.639,0.162,1.514,0.971,2.013c0.247,0.151,0.522,0.181,1.966,0.212
          c0.29,0.006,0.537,0.01,0.63,0.019c0.949,0.101,2.333,0.765,3.006,1.701c0.302,0.42,0.41,0.837,0.321,1.238
          c-0.1,0.441-0.756,1.468-1.033,1.616c-0.735,0.358-3.783,0.585-4.583,0.264c-0.131-0.052-0.4-0.244-0.638-0.413
          c-0.814-0.58-2.181-1.551-3.15-0.473c-0.936,1.041-0.994,2.241-1.046,3.3c-0.021,0.44-0.042,0.856-0.12,1.248
          c-0.034,0.173-0.086,0.328-0.135,0.479c-0.002,0.006-0.004,0.013-0.006,0.02l-5.404-3.609c0.129-0.548,0.204-1.117,0.204-1.705
          c0-4.125-3.353-7.47-7.489-7.47c-0.526,0-1.039,0.055-1.535,0.158l-13.406-26.767l19.309,4.859c0.1,0.411,0.242,0.854,0.445,1.239
          l0.271,0.512l0.563-0.132c0.381-0.089,0.809-0.229,1.246-0.374c0.372-0.123,0.747-0.244,1.094-0.334l3.36,0.846
          C751.727,218.203,751.547,218.477,751.461,218.519z M695.802,267.94l0.368,0.038l-0.259,0.156L695.802,267.94z M695.859,268.449
          l-0.003,0l0.003-0.001L695.859,268.449z M695.709,267.729l37.514-20.777c0.039,0.168,0.078,0.337,0.129,0.501l-17.802,9.86
          l-18.97,10.507L695.709,267.729z M667.195,285.184l-0.01-0.063l-0.014-0.091l-0.019,0.001l27.252-16.531l0.771,0.08l-27.695,16.758
          L667.195,285.184z M667.282,285.458l-0.042,0.026l-0.008-0.053L667.282,285.458z M667.086,285.798l0.02,0.134l-0.128-0.069
          L667.086,285.798z M666.785,285.759l-0.165-0.089l-0.044-0.289l0.201-0.122l0.119-0.009l0.116,0.062l0.043,0.283L666.785,285.759z
           M666.804,284.972l-0.535-0.29l0.061,0.407l-37.307,2.703l3.075-6.448l5.969-12.517c0.886,0.368,1.856,0.573,2.875,0.573
          c3.515,0,6.455-2.418,7.262-5.675l45.818,4.736L666.804,284.972z M666.569,285.073l-0.038,0.003l-0.004-0.026L666.569,285.073z
           M628.671,288.067l-0.026-0.046l0.049-0.004L628.671,288.067z M628.689,288.519l-0.013,0.001l0.006-0.013L628.689,288.519z
           M628.538,287.828l-0.011-0.02l-0.296,0.042l-0.012,0.001l9.213-19.324c0.148,0.078,0.3,0.149,0.453,0.218l-9.091,19.065
          L628.538,287.828z M628.564,288.29l-0.113,0.237l-0.129,0.019l-0.184,0.013L628,288.306l0.113-0.236l0.148-0.021l0.165-0.012
          L628.564,288.29z M561.441,251.182l0.004,0.07l-40.135,17.609l-0.026-0.124l-0.006-0.13l-0.002-0.048l-0.034-0.025l-0.036-0.174
          l40.015-17.557l-0.159,0.159l-0.14,0.14L561.441,251.182z M561.343,250.965l0.08-0.08l0.006,0.093L561.343,250.965z
           M561.385,250.263l0.015,0.244l-40.236,17.653l-9.546-45.694l0.039,0.015l-0.034-0.136l63.283,14.753l-13.195,13.214l-0.203-0.031
          L561.385,250.263z M464.561,250.081c-0.004-0.169-0.01-0.337-0.025-0.503l0.516-0.083l23.737-3.795l0.082,0.121l-0.385,0.434
          l-23.108,3.695L464.561,250.081z M441.336,340.009l-6.649,52.223l-0.411,0.205l-0.119-0.018l2.118-16.638l4.814-37.812l0.41,0.638
          l-0.298,1.186L441.336,340.009z M464.463,378.414l0.042,0l-8.611,35.514l-0.027-0.008l-0.006-0.013l-0.027-0.065l-0.188,0.012
          l-0.177-0.053l8.523-35.151L464.463,378.414z M464.116,378.214l0.054-0.3l0.038-0.156l0.087-0.043l0.361-0.001l-0.054,0.302
          l-0.029,0.12l-0.157,0.078L464.116,378.214z M462.671,491.996l10.174-24.194c0.131,0.07,0.267,0.132,0.403,0.194l-10.719,25.49
          l-0.029-0.739l-0.101-0.097l0.408-0.592L462.671,491.996z M469.68,464.585l-2.649,1.212l-0.808,0.37l-0.242-0.44l3.481-1.593
          C469.528,464.288,469.604,464.436,469.68,464.585z M483.83,460.986l36.451-1.481l0.079,0.231l-0.092,0.271l-10.368,0.421
          l-26.074,1.059c0.004-0.094,0.014-0.187,0.014-0.282C483.841,461.131,483.832,461.059,483.83,460.986z M472.57,454.765
          l-17.046-40.004l0.014-0.001l0.54,0.164l16.886,39.626C472.83,454.617,472.699,454.69,472.57,454.765z M455.016,413.661
          l-0.205-0.062l0.127,0.298l-33.142,2.135l-0.281-1.275l0.601-0.391l0.099-1.175l1.213-1.259l-1.804-0.703l9.568-13.203l3.361-4.637
          l0.186,0.029l0.028-0.22l13.845-6.893l15.08-7.507l-8.468,34.925L455.016,413.661z M455.13,414.348l-0.059,0.242l-32.936,2.121
          l-0.27-0.483l33.156-2.135L455.13,414.348z M380.231,377.735l-0.499-0.559l-3.113-17.093l-3.442-18.899l0.352-0.685l0.032-0.005
          l3.432,18.845l3.345,18.37C380.303,377.715,380.268,377.727,380.231,377.735z M373.437,339.804l0.058,0.32l-0.038,0.078
          l-0.059,0.114l-0.369,0.055l-0.058-0.32l0.023-0.048l0.074-0.145L373.437,339.804z M373.181,339.64l0.167-0.324l0.053,0.291
          L373.181,339.64z M388.091,310.239l-9.765,19.993l-4.552,9.319l-0.176,0.026l-0.107-0.59l13.981-28.624l-0.021,0.098l-0.051,0.24
          L388.091,310.239z M361.077,270.431l-0.004-0.021l1.61-0.569l35.903-12.687l0.078,0.141l-0.083,0.391l-37.403,13.218l0.19-0.27
          l0.088-0.125L361.077,270.431z M398.753,257.838l0.484-0.171l0.066-0.007l-6.963,33.021l-3.838,18.198l-0.57,0.382h-0.024
          L398.753,257.838z M398.802,257.609l0.01-0.046l0.02,0.036L398.802,257.609z M459.164,201.734l0.039,0.058l-19.311,12.484
          l-0.09-0.168l-0.105-0.258h-0.032l-0.009-0.017l8.215-5.311l10.937-7.07l-0.001,0.002l-0.086,0.106L459.164,201.734z
           M458.979,201.102l-0.179-0.265l0.34,0.134l-0.046,0.056L458.979,201.102z M487.015,167.282l0.502-0.039l0.052,0.079
          l-27.627,33.751l-0.268-0.106l-0.092-0.137l-0.003-0.004L487.015,167.282z M486.914,167.089l-44.815,3.466
          c-0.139-0.165-0.29-0.323-0.448-0.467l45.346-3.507l0.17,0.2L486.914,167.089z M487.298,166.936l0.076,0.089l0.018,0.027
          l-0.201,0.016L487.298,166.936z M487.242,166.562l0.11-0.009l-0.058,0.071L487.242,166.562z M488.148,166.93l0.04,0.06
          l-0.095,0.007L488.148,166.93z M488.104,166.495l0.152-0.012l-0.089,0.108L488.104,166.495z M488.313,167.181l1.149,1.746
          l9.995,15.221c-0.135,0.091-0.263,0.192-0.392,0.291l-9.487-14.426l-1.761-2.679l0.101-0.123L488.313,167.181z M503.324,145.118
          l0.143-0.062l-0.031,0.092L503.324,145.118z M537.892,130.481l1.218-0.249L503.5,145.587v-0.005l0.214-0.632l35.188-15.166
          L537.892,130.481z M539.515,129.604l0.018,0.337l-0.719,0.147L539.515,129.604z M539.549,130.268l3.373,64.831l-31.816-4.399
          c0.005-0.117,0.018-0.233,0.018-0.351c0-4.125-3.353-7.47-7.489-7.47c-0.045,0-0.089,0.006-0.134,0.007v-37.08L539.549,130.268z
           M541.124,129.106l-0.705,0.486l-0.018-0.339l0.719-0.147l-0.001,0L541.124,129.106z M542.611,175.594l1.013,19.467l-0.271,0.098
          l-0.229-0.032l-0.107-2.053l-3.272-62.9l0.316-0.136l0.178-0.036L542.611,175.594z M543.87,195.928l0.073-0.026l31.768,40.424
          l-0.032,0.028l-0.714-0.167l-1.76-2.24l-29.757-37.866l0.003-0.001l0.431,0.06L543.87,195.928z M576.276,236.721l-0.021-0.027
          l0.039,0.009L576.276,236.721z M576.437,237.25l-0.329-0.077l0.16-0.139L576.437,237.25z M576.057,236.443l-0.072-0.092
          l35.087-30.624l1.893-1.653l-0.174,0.818l-36.273,31.657L576.057,236.443z M576.133,236.864l-0.168,0.168l-0.098,0.085
          l-0.212-0.049l-0.191-0.243l0.152-0.152l0.115-0.1l0.212,0.049L576.133,236.864z M575.34,236.667l-0.179-0.227l0.331,0.077
          l-0.012,0.01L575.34,236.667z M575.344,236.995l-0.041-0.01l0.019-0.019L575.344,236.995z M489.647,245.36l-29.002-43.04
          l35.269,13.939l14.7,5.81l0.865,4.139l8.674,41.531l-28.984-21.174l-1.101-0.804l0.298-0.336l0.186-0.209L489.647,245.36z
           M490.031,245.501l-0.125,0.141l-0.136-0.099L490.031,245.501z M488.882,245.482l-29.39-43.618l0.741,0.293l29.083,43.161
          l-0.098,0.111L488.882,245.482z M489.008,245.665l-0.002,0.002l-0.001-0.001L489.008,245.665z M457.081,242.79
          c-1.869,0-3.574,0.687-4.886,1.817l-10.057-24.75l-2.075-5.106h0.08l-0.158-0.297l19.287-12.469l29.339,43.54l-24.098,3.854
          C464.075,245.669,460.918,242.79,457.081,242.79z M503.635,197.819c0.301,0,0.596-0.023,0.887-0.057l5.832,23.236l-18.454-7.293
          l-31.701-12.529l27.512-33.637l4.94,7.512l6.254,9.509c-1.683,1.37-2.759,3.454-2.759,5.789
          C496.146,194.474,499.499,197.819,503.635,197.819z M542.958,195.784l0.001,0.026l-17.765-2.456l-14.153-1.957
          c0.023-0.164,0.042-0.33,0.054-0.497l1.962,0.271l29.876,4.131l0,0.008l-0.141,0.051l-0.13,0.047L542.958,195.784z
           M505.218,197.648l3.294,13.121l2.646,10.543l-0.017-0.004l-0.556-0.22l-5.863-23.357
          C504.888,197.707,505.055,197.683,505.218,197.648z M475.335,207.372l35.079,13.865l0.15,0.597l-30.08-11.888l-20.119-7.952
          l-0.418-0.62l0.052-0.063L475.335,207.372z M460.037,201.864l-0.37-0.146l0.149-0.182L460.037,201.864z M510.763,221.805
          l-0.083-0.398l0.4,0.093l0.138,0.055l0.035,0.141l0.079,0.377l-0.405-0.094l-0.134-0.053L510.763,221.805z M505.412,197.598
          c2.88-0.7,5.102-3.056,5.6-6.003l4.336,0.599l27.623,3.82l0.005,0.1l0.007,0.135l0.265-0.096l31.404,39.961l-19.387-4.519
          l-43.888-10.232L505.412,197.598z M528.773,225.625l46.078,10.742l0.348,0.442l-0.123,0.123l-63.507-14.805l-0.123-0.488
          l-0.012-0.057L528.773,225.625z M543.428,195.875l-0.161-0.022l-0.112-0.143l-0.014-0.262l0.235-0.085l0.161,0.022l0.111,0.141
          l0.014,0.264L543.428,195.875z M540.127,129.793l-0.117,0.051l-0.278,0.057l-0.022-0.43l0.133-0.092l0.05-0.022l0.31-0.063
          l0.022,0.432L540.127,129.793z M503.3,145.549l-0.049,0.146l-0.182,0.079l-0.27-0.074v-0.231l0.049-0.145l0.183-0.079l0.269,0.074
          V145.549z M503.083,145.985l0.166,0.046l0.051,0.014v36.851c-0.168,0.007-0.337,0.014-0.502,0.032v-36.821L503.083,145.985z
           M487.817,167.019l-0.198,0.015l-0.085-0.129l-0.107-0.126l0.202-0.247l0.171-0.013l0.218,0.256L487.817,167.019z M459.701,201.367
          l-0.179,0.219l-0.073,0.047l-0.154-0.061l-0.148-0.219l0.21-0.257l0.037-0.024l0.147,0.058L459.701,201.367z M439.704,214.398
          L439.704,214.398L439.704,214.398l-0.235,0.152h-0.226l-0.073-0.137l-0.086-0.211l0.236-0.152h0.226l0.075,0.142L439.704,214.398z
           M439.527,214.75h0.32l12.19,29.999c-0.134,0.122-0.263,0.247-0.388,0.378l-12.297-30.264L439.527,214.75z M449.849,252.175
          l-50.092,5.236l-0.143-0.258l0.05-0.235l35.52-3.713l14.547-1.521C449.764,251.85,449.806,252.013,449.849,252.175z M449.9,252.371
          c0.222,0.752,0.555,1.455,0.988,2.088l-30.488,31.05c-1.25-0.934-2.798-1.494-4.48-1.494c-4.136,0-7.489,3.344-7.489,7.47
          c0,1.255,0.314,2.436,0.862,3.474l-3.508,2.349L388.74,308.72l4.564-21.64l6.209-29.442L449.9,252.371z M417.59,332.973
          l-43.575,6.543l1.931-3.953l12.406-25.401h0.084l0.038-0.178l1.319-0.883l19.968-13.371c1.352,1.947,3.604,3.225,6.159,3.225
          c1.13,0,2.197-0.257,3.158-0.703l9.368,12.946l13.15,18.172L417.59,332.973z M441.728,329.551l0.197,0.272l-0.026,0.207
          l-68.174,10.236l-0.021-0.114l0.205-0.419l52.424-7.871L441.728,329.551z M388.422,309.261h-0.129l0.151-0.101L388.422,309.261z
           M388.626,309.261l0.055-0.26l2.372-1.588l18.337-12.278c0.082,0.146,0.169,0.288,0.261,0.428l-21.053,14.098l0.195-0.399H388.626z
           M399.356,257.414l-0.161,0.057l-0.195,0.02l-0.125-0.226l0.048-0.229l0.161-0.057l0.195-0.02l0.125,0.226L399.356,257.414z
           M387.993,309.461h0.386l-0.07,0.331l-0.044,0.09l-0.119,0.079h-0.386l0.07-0.333l0.043-0.087L387.993,309.461z M387.848,310.161
          l-0.151,0.101l0.021-0.101H387.848z M373.668,339.77l-0.025,0.051l-0.008-0.047L373.668,339.77L373.668,339.77L373.668,339.77z
           M373.286,340.534l-0.167,0.326l-0.053-0.292L373.286,340.534z M377.311,359.961l-3.551-19.497l68.112-10.227l-0.675,5.305
          l-1.415,0.393l1.139,1.772l-5.848,45.929l-1.114,8.752l-33.955-5.243l-10.771-1.663c0.009-0.153,0.03-0.304,0.03-0.459
          c0-4.125-3.353-7.47-7.489-7.47c-0.423,0-0.836,0.039-1.239,0.107L377.311,359.961z M434.628,392.695l-0.039,0.304l-0.073,0.101
          l-0.127,0.063l-0.32-0.049l0.028-0.223l0.161-0.221l0.048-0.024L434.628,392.695z M434.617,392.49l0.039-0.019l-0.003,0.025
          L434.617,392.49z M434.078,393.318l-0.039,0.019l0.003-0.025L434.078,393.318z M389.22,385.683l44.714,6.905l-0.032,0.251
          l-0.163,0.224l-34.127-5.269l-10.449-1.613C389.188,386.016,389.205,385.85,389.22,385.683z M433.815,393.277l0.029,0.005
          l-0.027,0.216l-0.024,0.186l0.469-0.233l-12.81,17.674l-0.413-0.267L433.815,393.277z M455.404,414.068l0.221-0.014l0.094,0.029
          l0.088,0.205l-0.063,0.259l-0.182,0.012l-0.144-0.043l-0.078-0.184L455.404,414.068z M455.921,414.667l0.017-0.071l0.037,0.087
          L455.921,414.667z M463.974,377.875l-0.154,0.635l-20.582,10.247l-8.336,4.15l0.243-0.336l-0.294-0.045l0.021-0.162l29.102-14.489
          L463.974,377.875z M441.406,335.484l0.672-5.278l0.245-0.037l0.245,0.168l-0.637,5.001L441.406,335.484z M441.95,329.518
          l0.014-0.002l-0.002,0.018L441.95,329.518z M442.362,329.961l-0.181-0.125l-0.047-0.065l0.036-0.286l0.286-0.043l0.118,0.081
          l0.082,0.113l-0.036,0.286L442.362,329.961z M442.196,329.278l0.002-0.014l0.016,0.011L442.196,329.278z M442.176,329.006
          l-0.135-0.093l-0.05,0.396l-0.172,0.026l-22.558-31.172c0.151-0.075,0.299-0.155,0.444-0.241l22.518,31.117L442.176,329.006z
           M420.561,285.629l30.448-31.008c0.099,0.137,0.197,0.274,0.305,0.404l-30.374,30.928
          C420.816,285.841,420.692,285.732,420.561,285.629z M488.985,245.993l0.079,0.118l0.058,0.043l-0.326,0.052L488.985,245.993z
           M489.122,245.838l0.198-0.223l0.172-0.028l0.011,0.008l0.192,0.285l-0.188,0.211l-0.115,0.018l-0.172-0.126L489.122,245.838z
           M489.992,246.217l0.155-0.024l-0.169-0.25l27.859,20.353l2.378,1.738l0.153,0.731l-30.748-22.487L489.992,246.217z
           M510.838,222.163l0.031,0.007l0.527,0.208l9.581,45.865l-0.084,0.037l-0.497-0.363L510.838,222.163z M520.681,268.373
          l-0.174,0.076l-0.05-0.24L520.681,268.373z M520.868,268.509l0.194,0.142l0.024,0.116l0.009,0.189l-0.002,0.001v0.001l-0.289,0.127
          l-0.195-0.143l-0.023-0.11l-0.01-0.194L520.868,268.509z M521.104,269.171l0.007,0.137l-0.119-0.087L521.104,269.171z
           M561.724,250.584l0.111-0.049l0.272,0.042l0.017,0.282l-0.171,0.171l-0.049,0.021l-0.273-0.042l-0.019-0.314L561.724,250.584z
           M562.111,250.195l13.026-13.043l0.404,0.094l0.092,0.117l-13.091,13.078l-0.245-0.038l-0.017-0.282L562.111,250.195z
           M562.367,250.617l-0.054,0.054l-0.004-0.063L562.367,250.617z M562.604,250.653l39.144,6.025l-0.041,0.191l-0.101,0.293
          l-39.266-6.044l-0.012-0.19L562.604,250.653z M593.266,255.17l-30.451-4.687l13.06-13.08l0.065-0.064l0.729,0.17l0.277,0.065
          l-0.528-0.672l3.945-3.443l32.358-28.239l-6.866,32.199l-4.066,19.063L593.266,255.17z M601.901,256.921l0.071-0.206l0.228,0.033
          l0.243,0.037l-0.058,0.273l-0.073,0.21l-0.219-0.031l-0.252-0.039L601.901,256.921z M602.202,256.546l-0.215-0.033l4.248-19.923
          l6.738-31.592l0.51-0.445l0.085,0.055l-7.154,33.548l-3.931,18.43L602.202,256.546z M613.687,203.848l0.012,0.154l-0.026,0.121
          l-0.205,0.179l-0.264-0.172l-0.012-0.157l0.025-0.119l0.206-0.179L613.687,203.848z M613.579,203.539l0.079-0.069l0.01,0.127
          L613.579,203.539z M613.854,204.23l0.021-0.018l0.038-0.033l-0.012-0.166l0.005-0.023l25.466,16.651l0.77,0.503v0.011v0.587
          l-15.537-10.159l-10.811-7.069L613.854,204.23z M613.846,203.305L613.846,203.305l0,0.001L613.846,203.305z M680.499,228.506
          l44.007-17.437l11.427,22.816l2.156,4.305c-2.946,1.005-5.068,3.784-5.068,7.063c0,0.513,0.052,1.014,0.151,1.497l-37.536,20.789
          l-17.734-31.51c1.706-1.369,2.8-3.466,2.8-5.82C680.702,229.623,680.627,229.054,680.499,228.506z M738.278,238.121l-13.585-27.126
          l0.062-0.025l0.555,0.14l13.464,26.883C738.608,238.033,738.44,238.071,738.278,238.121z M695.417,267.662l-0.056,0.031
          l-0.47-0.049l-17.559-31.199c0.126-0.083,0.246-0.173,0.366-0.264L695.417,267.662z M695.069,268.368l-0.381-0.039l0.267-0.162
          L695.069,268.368z M695.012,267.858l0.056,0.006l-0.04,0.024L695.012,267.858z M694.769,267.834l0.089,0.158l-0.499,0.302
          l-42.685-4.412l-3.419-0.353c0.036-0.162,0.061-0.328,0.085-0.494l3.056,0.316l43.358,4.482L694.769,267.834z M625.732,212.561
          l14.409,9.422v0.104v32.417c-3.458,0.367-6.207,3.082-6.626,6.515l-9.347-1.335l-21.484-3.07l3.833-17.974l7.233-33.915
          L625.732,212.561z M613.311,204.439l-0.268,0.234l0.077-0.36L613.311,204.439z M602.577,257.115l0.103-0.298l30.81,4.402
          c-0.016,0.165-0.021,0.334-0.025,0.502l-30.927-4.419L602.577,257.115z M628.924,288.001l15.983-1.158l21.433-1.553l-0.248,0.15
          l-0.149,0.09l0.473,0.255l-13.98,1.013l-23.526,1.704l-0.12-0.22L628.924,288.001z M666.345,285.521l0.046-0.028l0.009,0.057
          L666.345,285.521z M695.126,268.062l0.204-0.124l0.073-0.04l0.156,0.016l0.182,0.323l-0.281,0.17l-0.147-0.015L695.126,268.062z
           M747.742,247.163l5.396,3.603c-0.048,0.162-0.089,0.336-0.116,0.524l-5.43-3.626C747.648,247.5,747.697,247.333,747.742,247.163z
           M746.31,215.579c0.508-0.059,0.712,0.041,0.948,0.167c-0.108,0.036-0.218,0.072-0.326,0.107c-0.171,0.057-0.34,0.113-0.504,0.165
          C746.385,215.882,746.346,215.734,746.31,215.579z M751.979,217.227c-0.016,0.134-0.045,0.251-0.074,0.369l-2.964-0.746
          c0.109-0.018,0.217-0.034,0.312-0.037c0.15-0.001,0.312,0.022,0.475,0.045c0.376,0.054,0.804,0.113,1.247-0.012l1.012,0.255
          C751.984,217.144,751.985,217.18,751.979,217.227z M751.245,216.707c0.114-0.091,0.21-0.176,0.295-0.251
          c0.069-0.062,0.164-0.147,0.193-0.16c0.001,0,0.034-0.012,0.153-0.001c0.048,0.082,0.111,0.284,0.112,0.602L751.245,216.707z
           M725.175,190.265c0.155,0,0.312-0.001,0.458,0.007c1.283,1.164,2.569,1.758,3.927,2.384c0.54,0.25,1.096,0.506,1.674,0.812
          c0.478,0.251,0.939,0.288,1.347,0.321c0.66,0.053,1.23,0.098,2.114,1.012l0.211,0.218l1.84,0.063l0.645-1.2l-0.107-0.311
          c-0.513-1.479-0.198-1.957,0.717-2.953l0.847,0.792l2.626-0.394c-0.045,0.321-0.073,0.648-0.073,0.982
          c0,1.546,0.481,2.974,1.289,4.164c-0.252-0.045-0.524-0.101-0.846-0.185c-0.314-0.084-0.564-0.149-0.837,0.026
          c-0.472,0.285-0.45,0.641-0.348,2.352c0.033,0.562,0.08,1.333,0.053,1.546c-0.037,0.293-0.189,0.777-0.338,1.247
          c-0.373,1.183-0.759,2.407-0.344,3.25c0.144,0.292,0.415,0.488,0.746,0.54c0.98,0.157,2.373-1.078,2.388-1.094
          c0.258-0.27,0.438-0.573,0.599-0.841c0.134-0.223,0.26-0.433,0.405-0.584c0.345-0.361,0.744-0.545,1.146-0.522
          c0.374,0.018,0.742,0.211,1.051,0.55c0.141,1.049-0.607,4.412-1.121,4.748c-0.194-0.015-0.747-0.373-1.014-0.544
          c-0.644-0.417-1.156-0.748-1.687-0.444c-1.23,0.697-2.628,1.659-2.513,2.928c0.133,1.459,2.148,2.159,4.284,2.899
          c0.307,0.105,0.436,0.252,0.614,0.455c0.448,0.51,0.964,0.948,2.301,0.704c0.231-0.042,0.503-0.179,1.047-0.459
          c0.204-0.105,0.521-0.268,0.77-0.381c0.119,0.214,0.271,0.47,0.532,0.745l0.443,0.466l0.528-0.367
          c0.57-0.396,1.082-0.554,1.668-0.503c-0.023,0.456-0.158,0.719-0.556,1.162c-0.756,0.838-0.762,0.837-1.39,0.798
          c-0.24-0.016-0.531-0.035-0.903-0.03c-0.13,0.001-0.261,0.013-0.393,0.025c-0.144,0.013-0.282,0.025-0.375,0.014
          c-0.256-0.035-0.4-0.112-0.6-0.219c-0.613-0.331-1.205-0.554-2.73-0.196l-0.661,0.155l0.088,0.657l-19.52-4.912V190.265z
           M725.175,210.354l19.554,4.92c0.024,0.16,0.059,0.34,0.102,0.541l-19.381-4.877l-0.275-0.549V210.354z M725.112,210.829
          l0.048-0.019l0.032,0.064h0l0,0l-0.118-0.03L725.112,210.829z M724.473,190.292c0.165-0.013,0.334-0.023,0.503-0.027v19.727
          l-0.032-0.064l-0.25,0.099l-0.221-0.056V190.292z M724.473,210.329l0.234-0.093l0.19,0.048l0.078,0.157v0.228l-0.233,0.092
          l-0.191-0.048l-0.079-0.157V210.329z M724.416,210.889l-43.967,17.421c-0.043-0.163-0.088-0.324-0.142-0.482l43.884-17.388
          l0.083,0.165v0.243l0.14,0.035L724.416,210.889z M724.256,210.123l0.018,0.004v0.031L724.256,210.123z M685.225,211.569
          l0.239,0.125c0.125,0.056,0.258,0.114,1.772,0.006c0.862-0.047,3.548,0.715,4.459,1.166l0.676,0.335l1.04-2.13l0.712-0.407
          l1.401,0.168c0.679,0.603,1.421,1.535,1.457,1.851l0.084,0.722l0.724-0.061c0.115-0.01,0.3-0.019,0.526-0.029
          c2.466-0.118,3.717-0.292,4.161-0.962l0.629-1.562c0.293-0.007,0.481-0.016,0.696-0.075c0.215-0.062,0.381-0.16,0.632-0.308
          c0.136-0.08,0.312-0.185,0.56-0.321l0.263-0.145l0.091-0.286c0.231-0.737,0.596-1.684,0.963-2.293l1.249,1.094l0.379-0.092
          c1.182-0.288,1.357-0.348,2.007-0.958c0.113-0.106,0.252-0.236,0.434-0.398l1.057-0.021l1.341-0.774l1.749-0.909l0.202-1.092
          c0.503-0.394,1.099-0.79,1.812-1.207l0.318-0.186l0.048-0.366c0.073-0.566,0.867-1.48,1.431-1.775l0.424-0.222l-0.023-0.478
          c-0.03-0.625,0.167-1.167,0.395-1.795c0.2-0.551,0.42-1.158,0.496-1.877l2.543-0.336l-1.254-2.26l0.031-0.12l2.271-1.221
          l0.011-1.694l0.107-0.178c0.239-0.087,0.575-0.146,0.938-0.185v19.608l-0.181-0.045l-0.212-0.053l0.22,0.439l-37.571,14.887
          l-6.292,2.493c-0.482-1.309-1.315-2.445-2.393-3.293l6.592-13.177C684.693,211.29,684.954,211.426,685.225,211.569z
           M684.204,211.066l-6.567,13.127c-0.122-0.089-0.244-0.176-0.371-0.258l6.516-13.026
          C683.923,210.953,684.064,211.006,684.204,211.066z M641.042,205.178l0.021-0.012c0.369-0.199,0.664-0.426,0.925-0.627
          c0.037-0.028,0.073-0.056,0.11-0.084c0.13,0.319,0.191,0.769,0.241,1.13c0.02,0.148,0.039,0.29,0.062,0.421l0.048,0.288l1.741,1.36
          l0.655,1.895l1.639,0.446l0.309-0.353c0.805-0.919,1.853-1.877,3.038-2.775c0.261-0.05,0.61,0.06,0.979,0.177
          c0.307,0.097,0.623,0.198,0.958,0.234c0.63,0.071,1.289-0.079,1.984-0.237c0.964-0.218,1.959-0.444,2.842-0.055l0.268,0.115
          l0.392-0.126c0.55-0.167,0.874-0.273,1.085-0.384c0.086-0.009,0.187-0.012,0.292-0.014c0.003,0,0.007,0,0.011,0l1.535,1.719
          l0.265,0.4l0.479-0.177c0.263-0.011,1.229,0.291,2.006,0.54c1.925,0.615,4.321,1.379,6.124,1.051l0.264-0.049l0.503-0.588
          c0.512-0.589,1.08-1.244,1.464-1.945c0.661-0.035,1.25,0.045,1.94,0.155c0.488,0.577,1.052,0.956,1.55,1.254l0.364,0.218l0.374-0.2
          c0.537-0.286,1.034-0.609,1.516-0.922c2.007,0.031,2.779,0.771,4.405,2.33l0.569,0.544l0.39-0.086
          c0.399-0.088,0.775-0.066,1.145,0.021l-6.484,12.962c-1.123-0.671-2.432-1.063-3.836-1.063c-3.332,0-6.152,2.171-7.124,5.172
          l-22.408-5.834l-2.639-0.687V205.178z M641.042,221.598l24.989,6.505c-0.047,0.159-0.089,0.321-0.125,0.484l-24.864-6.473V221.598z
           M641.042,222.323l24.822,6.461c-0.09,0.462-0.14,0.938-0.14,1.426c0,4.125,3.353,7.47,7.489,7.47c1.432,0,2.765-0.408,3.902-1.103
          l17.468,31.036l-38.673-3.997l-7.54-0.779c0.036-0.298,0.06-0.599,0.06-0.906c0-4.092-3.3-7.411-7.389-7.465v-31.893V222.323z
           M640.34,205.162l0.312,0.225l0.19-0.102v16.055l-0.066-0.017l-0.437-0.285V205.162z M640.34,221.416l0.355,0.093l0.147,0.096
          v0.459v0.001l-0.352-0.092l-0.151-0.099V221.416z M640.407,222.157l0.436,0.285v32.023c-0.169,0.002-0.337,0.012-0.503,0.025
          V222.14L640.407,222.157z M612.722,186.796c0.683,0.223,0.983,0.314,1.207,0.319c0.363,0.01,0.707,0.009,1.035,0.007
          c1.441-0.008,2.405-0.01,3.49,0.903c0.414,0.35,0.863,0.69,1.312,1.029c0.73,0.552,1.42,1.074,1.958,1.639
          c2.012,2.114,2.822,4.954,3.604,8.178l0.056,0.233l1.819,1.579l0.521-0.046c1.209-0.109,1.706-0.148,1.894-0.159
          c0.181,0.065,0.535,0.212,0.874,0.351c0.389,0.16,0.759,0.31,0.89,0.352c0.681,0.215,1.385,0.396,2.085,0.576
          c0.272,0.07,0.545,0.14,0.815,0.212l1.202,1.306l0.597,0.08c0.457,0.06,1.737,0.227,2.157,0.29c0.313,0.219,1.193,0.86,1.599,1.155
          l0.304,0.219v15.887l-26.191-17.126l0.155-0.727l0.062-0.294l-0.339,0.296l-1.227-16.299
          C612.643,186.771,612.678,186.782,612.722,186.796z M612.086,186.6c0.067,0.013,0.179,0.048,0.309,0.09l1.244,16.53l-0.235,0.205
          l-0.142-0.093l-0.122-0.08l-0.002,0.009l-1.257-16.696C611.95,186.578,612.018,186.586,612.086,186.6z M591.353,181.354
          c0.31-0.003,0.65-0.083,0.985-0.21l-0.21,0.5l0.67,1.599l1.269-0.198l1.312-1.533l0.4,0.517l0.816,0.125l-0.069,0.053l0.494,0.907
          c-0.358-0.147-0.729-0.333-1.121-0.527l-0.939-0.468l-0.312,2.357l0.618,1.376l1.112,0.62l0.337-0.142
          c0.697-0.294,1.299-0.423,1.959-0.416l-0.018,1.445l1.128,1.038l1.236-0.176l0.727-0.995l0.753,1.58l1.592-0.065l0.952-1.006
          l0.46,0.115l-0.216-2.458c0.608,0.037,1.17,0.166,1.804,0.311c0.334,0.077,0.674,0.154,1.034,0.222
          c0.57,0.105,1.143,0.196,1.714,0.286c0.614,0.096,1.227,0.203,1.84,0.32l1.299,17.268l-14.38,12.551l-22.738,19.845l-31.72-40.362
          l27.165-9.845l19.143-6.937l-0.625,0.853l1.163,1.456L591.353,181.354z M596.904,184.668c-0.141,0.042-0.283,0.09-0.427,0.143
          l-0.049-0.027l-0.162-0.361C596.474,184.512,596.687,184.595,596.904,184.668z M590.785,178.591l-0.123,0.168l-46.652,16.906
          l-0.165-0.21l-0.013-0.256l14.987-5.431l31.176-11.298C590.258,178.51,590.521,178.554,590.785,178.591z M545.452,128.937
          l0.088,0.12l0.664,0.009l0.547,0.567l0.462-0.138c0.082,0.102,0.172,0.206,0.266,0.315l0.213,0.26l0.69,0.206
          c0.165,0.415,0.369,0.876,1.024,1.24l0.144,0.08l-0.205,0.764l0.9,0.411l0.032,0.28l0.122,0.163
          c0.099,0.131,0.221,0.241,0.356,0.334c-0.229,0.757,0.021,2.159,0.699,5.018c0.155,0.655,0.288,1.208,0.335,1.513l0.027,0.184
          l0.111,0.149c0.248,0.335,0.517,0.587,0.895,0.839c0.416,0.28,0.758,0.775,1.119,1.299c0.527,0.765,1.125,1.632,2.124,1.966
          l0.136,0.045l0.181-0.01c0.013,0.109,0.023,0.209,0.033,0.301c0.146,1.329,0.357,1.681,1.673,1.812
          c0.289,0.028,0.667,0.066,1.175,0.154l0.084,0.156l-0.151,1.448l1.271,0.209c0.583,0.092,1.796,0.283,1.87,0.291
          c0.478,0.191,1.026,0.57,1.557,0.936c0.389,0.269,0.79,0.546,1.184,0.764c0.369,0.204,0.76,0.376,1.15,0.547
          c0.357,0.157,0.715,0.312,1.05,0.5c0.402,0.225,0.814,0.507,1.25,0.805c0.651,0.445,1.325,0.905,2.031,1.215
          c0.14,0.061,0.148,0.065,1.626,0.209l0.663,0.779l1.631,0.384l0.462,0.705l2.331,0.193l0.555,1.374l1.147,0.519
          c0.434,1.026,0.296,1.564,0.139,2.181c-0.223,0.872-0.475,1.861,0.719,3.632l0.353,0.524l0.576-0.258
          c0.475-0.213,0.896-0.402,1.359-0.498l0.483,0.401c0.034,0.087,0.073,0.189,0.115,0.296c0.127,0.325,0.277,0.703,0.3,0.758
          c0.017,0.119-0.001,0.235-0.035,0.431c-0.076,0.443-0.182,1.049,0.289,1.921l0.146,0.271l2.232,0.681l0.539,0.644
          c-0.189,0.173-0.461,0.376-0.669,0.487l-1.385,0.743l1.448,0.61c0.518,0.218,1.115,0.32,1.659,0.394
          c-0.129,0.41-0.321,0.723-0.642,1.065l-0.231,0.246l0.312,3.342l1.115-1.035c0.471-0.436,0.66-0.471,1.32-0.563l0.809,0.329
          c-0.145,0.04-0.305,0.066-0.494,0.099c-0.664,0.111-1.49,0.25-2.486,1.13l-0.212,0.188l-0.035,0.282
          c-0.033,0.265-0.01,0.522,0.017,0.764l0.025,0.238l0.838,0.942l0.27,0.04c0.415,0.062,0.831,0.129,1.247,0.195
          c0.162,0.026,0.324,0.049,0.486,0.075l-0.333,0.121l-45.427,16.462l-3.383-65.028L545.452,128.937z M545.053,128.392l0.269,0.367
          l-4.753,0.971l1.052-0.725l3.273-0.668L545.053,128.392z M544.289,128.125l0.234,0.082l-2.479,0.506l2.114-1.457L544.289,128.125z
           M543.452,127.141c0.171-0.018,0.363-0.015,0.524-0.005l-1.516,1.046L543.452,127.141z M541.517,127.744l0.157,0.13l-0.394,0.272
          L541.517,127.744z M541.838,128.01l0.395,0.328l-0.693,0.478l-1.15,0.235l-0.002-0.041L541.838,128.01z M515.235,112.065
          c0.648,0.486,2.376,1.163,2.716,1.293l2.302,0.188c0.165,0.086,0.355,0.188,0.562,0.299c0.885,0.474,1.984,1.063,2.698,1.266
          c0.356,0.101,0.989,0.261,1.746,0.452c1.764,0.446,4.715,1.193,5.509,1.568c0.037,0.019,0.079,0.045,0.119,0.073
          c-0.144,0.129-0.285,0.241-0.432,0.315l-1.16,0.593l1.927,1.242l0.798-0.021l1.966,3.052c0.294,0.382,0.57,0.991,0.839,1.58
          c0.395,0.868,0.803,1.766,1.404,2.28l0.457,0.39l0.48-0.361c0.281-0.211,0.528-0.505,0.749-0.787l0.325,1.42l1.146,0.006
          c0.213,0.439,0.557,0.986,1.002,1.253l0.459,0.275l-1.06,0.731l-0.291,0.06l0.004,0.073l-35.698,15.387l7.071-20.868l4.071-12.012
          C515.04,111.899,515.132,111.988,515.235,112.065z M514.78,111.673l-4.989,14.724l-6.237,18.404l-0.537,0.231l-0.065-0.018
          l2.695-7.953l8.745-25.804C514.52,111.405,514.648,111.547,514.78,111.673z M470.985,135.805c0.402-1.277,0.734-2.042,2.149-1.965
          c2.554,0.155,3.399-1.253,3.476-2.016l0.018-0.171l-1.316-3.616l-1.357-1.233l0.121-0.241c1.189,0.064,3.747,0.107,4.759-0.617
          c0.662-0.473,2.198-0.935,3.016-1.148c1.233,0.781,2.908,1.958,3.233,2.445c0.522,0.782,1.545,1.541,3.453,0.72
          c0.507-0.215,2.4,0.009,3.865,0.341l0.205,0.046l4.686-1.562l0.384-3.82l-2.177-2.177c-0.104-0.53-0.39-1.901-0.866-3.568
          c-0.627-2.191-0.942-2.981-1.465-3.673c-0.263-0.351-0.346-0.856-0.32-0.943c0.784-0.314,1.997-1.191,2.237-2.151
          c0.039-0.151,0.045-0.3,0.04-0.437c0.153,0.045,0.353,0.111,0.613,0.208c2.907,1.091,3.943-0.026,4.913-1.48
          c0.43-0.646,0.619-1.247,0.77-1.729c0.211-0.672,0.27-0.747,0.452-0.8c0.555-0.158,0.979-0.501,1.319-0.776
          c0.109-0.089,0.261-0.211,0.364-0.276l0.232,0.236c0.739,0.756,1.658,1.697,3.122,2.284c0.756,0.303,1.278,0.317,1.624,0.328
          c0.087,0.002,0.207,0.006,0.221,0.006c0.002,0.002,0.059,0.045,0.168,0.236l0.115,0.203c0.597,1.055,0.927,1.406,2.282,1.213
          c0.206-0.029,0.407-0.069,0.603-0.108c0.857-0.171,1.07-0.169,1.368,0.247c0.359,0.504,0.659,0.917,0.952,1.271l-6.877,20.296
          l-4.603,13.583l-31.891-8.794C470.906,136.053,470.945,135.932,470.985,135.805z M470.736,136.519
          c0.021-0.05,0.041-0.108,0.061-0.162l31.897,8.796l-0.008,0.023l-0.088,0.038v0.222l-0.065,0.191l-31.962-8.814
          C470.627,136.717,470.687,136.633,470.736,136.519z M466.33,138.151c0.716-1.002,1.072-0.79,1.241-0.688
          c0.636,0.384,1.311,0.492,1.896,0.3c0.299-0.097,0.684-0.313,1.003-0.771l31.998,8.824l-0.1,0.294l-0.072,0.213l0.301-0.13v36.765
          c-1.076,0.149-2.078,0.524-2.958,1.079l-10.12-15.385l-0.978-1.489l0.063-0.005l-0.292-0.343l-0.032-0.048l0.276-0.338l0.148-0.181
          l-0.729,0.056l-0.113-0.173l-0.075-0.114l-0.046,0.056l-22.689-26.718C465.598,138.982,466.037,138.562,466.33,138.151z
           M464.848,139.491l22.738,26.776l-0.059,0.072l-0.445,0.034l-22.632-26.648C464.588,139.651,464.72,139.573,464.848,139.491z
           M441.432,169.905c-0.077-0.06-0.154-0.121-0.234-0.168c-0.156-0.093-0.292-0.18-0.431-0.269c-0.528-0.338-1.126-0.72-2.919-1.556
          c-2.282-1.066-3.552-2.191-3.85-2.69c-0.207-0.345-0.513-0.6-0.809-0.847c-0.638-0.533-0.736-0.703-0.614-1.066
          c0.533-1.6,0.32-2.401-0.36-3.593c-0.079-0.137-0.151-0.24-0.226-0.355c0.852,0.34,1.775,0.541,2.75,0.541
          c4.136,0,7.489-3.344,7.489-7.47c0-1.151-0.272-2.234-0.74-3.207c0.252,0.005,0.502,0.001,0.74-0.022
          c1.178-0.116,1.948-0.133,2.295-0.046c-0.102,0.393-0.22,1.099,0.266,1.564c0.533,0.511,1.349,0.331,2.078,0.088
          c1.901-0.635,2.659-2.203,2.847-2.67l0.99-2.476l-0.412-1.441c2.322-0.173,4.84-0.53,5.426-1.262
          c0.411-0.514,1.43-1.286,1.868-1.599c0.363-0.146,2.091-0.806,3.662-0.806c1.107,0,2.124-0.289,2.98-0.712l22.551,26.556
          l-16.45,1.272L441.432,169.905z M436.397,201.352l4.734-0.947l-3.148-3.889v-3.017c0.01-0.356,0.953-1.456,1.459-2.047
          c1.19-1.391,1.224-5.224,1.224-5.262c0-0.608-0.144-3.21-0.161-3.414l-0.269-1.485c0.154-0.636,0.758-3.094,1.127-4.084
          c0.421-1.123,0.852-1.989,1.162-2.614c0.342-0.683,0.498-2.074,0.525-2.346l0.024-0.234l-0.114-0.206
          c-0.049-0.088-0.324-0.566-0.711-1.062l40.856-3.16l3.569-0.276l-27.401,33.501l-0.699-0.276l-0.3-0.119l0.537,0.797l-19.25,12.445
          l-5.414-10.186L436.397,201.352z M433.996,203.612l5.397,10.153l-0.133,0.086h-0.39l-5.253-9.882L433.996,203.612z M403.78,213.333
          c1.086-0.155,2.582-0.602,2.645-0.621l0.234-0.118c0.029-0.022,2.977-2.232,3.843-2.811c1.2-0.799,2.2-1.217,2.567-1.217
          c0.415,0,0.865-0.072,1.341-0.147c0.46-0.074,0.936-0.149,1.344-0.149c1.044,0,2.735-0.954,3.063-1.146l0.607-0.354l-0.314-0.629
          c-0.314-0.629-0.551-1.436-0.522-1.601c0.092-0.121,0.195-0.281,0.308-0.457c0.166-0.258,0.552-0.861,0.703-0.91
          c0.785,0.653,2.553,0.967,3.75,0.407c0.769-0.359,1.209-1.044,1.209-1.877c0-0.211,0.004-0.398,0.012-0.562l1.263,1.265
          l1.766-2.405l3.822,2.166l1.955,1.777l5.267,9.908h-36.196C402.916,213.596,403.389,213.389,403.78,213.333z M402.092,214.05
          h36.658l0.235,0.443l0.023,0.057h-37.7C401.546,214.389,401.814,214.215,402.092,214.05z M396.42,250.862l3.984,1.647l0.152,0.044
          c0.899,0.165,3.895,0.576,4.682-0.996c0.538-1.074,1.029-1.67,1.55-1.877c0.891-0.358,1.826-1.43,1.93-1.551l0.498-0.581
          l-0.591-0.486c-0.996-0.819-2.622-2.22-3.022-2.723c-0.152-0.19-0.407-0.443-0.713-0.741c-0.788-0.769-2.106-2.056-2.106-3.116
          c0-1.404-0.073-4.767-0.747-5.67c-0.058-0.077-0.134-0.172-0.221-0.281c-0.551-0.69-0.865-1.169-0.936-1.428
          c0.236-0.078,0.479-0.109,0.716-0.139c0.483-0.062,1.03-0.132,1.395-0.624c0.369-0.497,0.316-1.157,0.229-1.676
          c-0.129-0.774-0.033-1.176,0.099-1.294c0.136-0.124,0.56-0.174,1.341,0.049c1.491,0.425,2.296-0.444,2.776-0.964
          c0.119-0.128,0.297-0.322,0.324-0.348c0,0,0.069,0.015,0.217,0.134c2.302,1.843,3.712,0.153,4.177-0.404
          c0.863-1.038-0.258-2.409-1.342-3.734c-0.442-0.541-0.943-1.154-1.067-1.483c-0.174-0.464-0.392-0.972-0.604-1.465
          c-0.309-0.72-0.629-1.464-0.706-1.85c-0.028-0.14-0.035-0.259-0.042-0.364c-0.051-0.747-0.293-1.24-1.383-1.552
          c-1.399-0.401-2.214-0.072-2.816,1.132c-0.385,0.771-1.193,1.589-1.201,1.597l-0.41,0.413l0.298,0.5
          c0.015,0.025,0.033,0.057,0.054,0.095c-0.027-0.039-0.056-0.078-0.084-0.117l-0.359-0.5c-0.668-0.935-1.086-2.175-1.09-2.188
          l-0.252-0.755l-1.606,0.644l-0.661-1.238c0.433-0.597,1.183-1.542,1.794-1.979c0.094-0.067,0.212-0.151,0.34-0.24h38.072
          l12.409,30.541c-1.183,1.321-1.908,3.058-1.908,4.968c0,0.419,0.043,0.826,0.109,1.226l-16.988,1.776l-33.006,3.45l0.001-0.005
          l0.037-0.174l-0.398,0.141l-3.149-5.683L396.42,250.862z M396.026,251.09l3.131,5.65l-0.119,0.042l-0.201,0.021l-0.073,0.008
          l-0.015,0.073l-0.072,0.025l-3.086-5.569L396.026,251.09z M359.563,250.4c0.13-0.065,0.295-0.133,0.481-0.211
          c1.124-0.468,3.215-1.34,3.215-3.594c0-0.44-0.019-0.886-0.038-1.321c-0.048-1.1-0.108-2.469,0.345-2.57
          c0.759-0.173,2.792,1.45,3.736,2.583c0.6,0.72,1.848,2.216,3.347,1.723c0.446-0.149,1.161,0.098,1.455,0.228
          c0.516,0.391,1.524,0.839,2.389,0.317c0.603-0.209,3.14,0.479,3.373,0.98c0,1.5,2.146,2.831,2.712,3.156
          c0.869,0.703,2.167,1.92,2.419,2.508c0.392,0.912,2.007,2.669,3.47,2.803c0.655,0.06,1.213-0.204,1.572-0.744
          c0.268-0.401,0.611-0.917,0.848-1.18c0.891,1.429,3.362,0.819,3.645,0.743l0.385-0.103l1.231-3.544l1.27-0.735l3.068,5.536
          l-37.453,13.235l-3.764-19.181C358.259,250.823,359.171,250.596,359.563,250.4z M360.959,270.868l-0.106,0.151l-0.001,0l0,0
          l-0.142,0.05l-0.232-0.048l-0.053-0.268l0.108-0.153l0.141-0.05l0.233,0.048L360.959,270.868z M357.074,251.072l3.011,15.342
          l0.758,3.865l-0.185,0.065l-0.327-0.068l-3.749-19.106C356.746,251.139,356.911,251.106,357.074,251.072z M360.213,270.709
          l-0.159,0.225l-64.725-13.378l0.037-0.219l0.097-0.263l64.699,13.372L360.213,270.709z M299.079,247.302
          c0.341,0.543,0.613,0.91,0.633,0.938l0.581,0.775l0.605-0.756c0.008-0.011,0.852-1.045,2.109-0.522
          c1.512,0.629,2.309,2.04,2.315,2.052l0.55,1.006l0.703-0.907c0.331-0.427,1.479-1.646,2.389-1.646c1.757,0,3.588-0.724,4.454-1.762
          c0.294-0.354,0.467-0.759,0.619-1.116c0.338-0.794,0.395-0.881,1.009-0.724c2.029,0.503,2.927-0.44,3.522-1.065l0.171-0.178
          c0.151-0.15,0.311-0.327,0.48-0.515c0.433-0.48,0.971-1.078,1.466-1.243c1.09-0.365,2.136-1.937,2.334-2.248l0.073-0.149
          c0.139-0.385,1.36-3.782,1.513-4.398c0.024-0.098,0.054-0.191,0.083-0.283c0.155-0.498,0.368-1.18-0.167-2.073
          c-0.086-0.143-0.195-0.296-0.308-0.452c-0.086-0.119-0.211-0.292-0.29-0.428c0.081-0.025,0.199-0.055,0.366-0.081
          c1.71-0.263,3.048-1.149,4.089-2.71c0.647-0.97,2.032-4.72,2.313-5.649c0.096-0.004,0.217,0.003,0.323,0.006
          c0.142,0.005,0.298,0.01,0.47,0.01c0.357,0,0.868,0.289,1.208,0.527c0.046,1.148,0.015,2.448-0.115,2.771
          c-0.251,0.629-0.778,1.294-0.783,1.301l-0.487,0.611l0.63,0.462c0.602,0.441,2.287,1.735,2.85,2.411
          c0.795,0.953,3.581,2.778,3.899,2.985c0.748,0.48,2.071,1.483,2.44,2.127c0.131,0.229,0.085,0.383-0.286,0.97
          c-0.22,0.349-0.47,0.744-0.549,1.221c-0.023,0.141-0.059,0.305-0.098,0.485c-0.233,1.088-0.586,2.732,0.506,3.643
          c0.83,0.691,1.478,0.668,1.876,0.528c0.499-0.176,0.855-0.633,0.936-1.179c0.128-0.091,0.7-0.087,1.077,0.214
          c0.771,0.617,3.92,2.401,4.229,2.573l2.479,1.655l1.919,5.272l0.612-0.099c0.368-0.059,1.431-0.235,2.557-0.452l3.733,19.023
          l-61.69-12.75l-2.869-0.593L299.079,247.302z M298.926,247.055l-3.529,9.615l-0.454,0.092l-0.078-0.016l3.753-10.225
          C298.721,246.71,298.825,246.888,298.926,247.055z M284.948,315.424l9.667-57.676l0.328-0.067l0.157,0.032l-9.685,57.787
          L284.948,315.424z M294.941,257.477l-0.272-0.056l0.044-0.265l0.057-0.155l0.171-0.035l0.274,0.057l-0.044,0.261l-0.058,0.157
          L294.941,257.477z M256.754,253.534c0.34,0.364,0.8,0.717,1.375,0.812c0.67,0.112,2.705,0.869,3.432,1.151l4.064,0.6l0.084-0.771
          c0.002-0.023,0.288-2.325,1.525-2.633c0.543-0.136,1.073-0.311,1.571-0.476c0.653-0.216,1.746-0.577,1.955-0.411
          c0.755,0.602,1.359,1.056,1.359,1.056l0.465,0.35l0.454-0.364c0.033-0.026,0.818-0.658,1.459-1.459
          c0.618-0.771,1.363-1.959,1.394-2.009l0.154-0.247l-0.052-0.286c-0.059-0.32-0.397-1.957-1.314-2.688
          c-0.466-0.373-1.13-0.79-1.606-1.073l0.338-0.788l2.189,0.938l0.566-2.07c0.057-0.01,0.115-0.017,0.173-0.018
          c0.243,0.003,0.275,0.076,0.313,0.165c0.35,0.815,0.905,1.317,1.395,1.761c0.242,0.219,0.47,0.425,0.649,0.649
          c0.585,0.731,2.718,2.518,4.017,2.518c0.606,0,2.749,0.349,4.041,0.583l0.202,0.031l0.183-0.062
          c0.011-0.004,1.081-0.35,3.307,0.175c2.469,0.579,4.241,1.167,4.259,1.173l0.986,0.329l0.001-1.04
          c0.001-1.127,0.253-2.543,0.625-2.853l0.132-0.111c0.322-0.27,1.303-1.083,1.602-1.041c0.01,0.008,0.043,0.046,0.083,0.135
          c0.106,0.238,0.221,0.467,0.338,0.69l-3.835,10.448l-0.05-0.01l-0.025,0.149l-37.81,7.687V253.534z M256.754,265.214l0.16-0.237
          l0.068-0.101l-0.228-0.097v-0.05l3.202-0.651l34.573-7.029l-0.009,0.055l-0.126,0.345l-0.04,0.109l0.087,0.018l0,0.002
          l-37.686,7.661V265.214z M256.754,265.758v-0.313l37.62-7.648l-9.619,57.398l-0.185-0.391l-0.836-0.243l0.141-0.506l-0.777-0.41
          l-0.151-1.057l0.628-0.718l-0.792-1.73l-1.313,0.993l-0.333,0.07l-0.518-0.068l-0.002-0.015l0.51-1.132l-0.724-1.132l-0.876-4.849
          l-1.011,2.534l-0.09-0.044l-1.37,0.674l1.046,1.992l0.286,1.026l-0.373,0.482l-0.28,1.829l0.921,0.029l0.311,0.726l1.673,0.343
          l0.296,1.166l-0.68,0.059l-0.381,1.462l-0.995,0.577l0.019,0.198c-1.029,0.671-1.886,1.578-2.479,2.657l-32.292-24.174l2.064-0.622
          l1.233-0.014l1.909,0.938l0.741-1.034l-0.327-0.915l-0.934-0.769l-1.908-0.231l-2.874,0.47l-3.026-0.162l-1.219-0.912
          c-0.194-0.328-0.463-0.602-0.79-0.799l12.523-18.53l4.952-7.327l0.146,0.062L256.754,265.758z M276.113,320.364l-32.937-24.656
          l0.464-0.021l0.241-0.073l32.425,24.297C276.232,320.057,276.178,320.213,276.113,320.364z M237.284,290.508l0.281,0.672
          c-0.084,0.009-0.169,0.016-0.25,0.034L237.284,290.508z M237.699,290.981l0.252,0.189c-0.048-0.003-0.094-0.014-0.143-0.014
          c-0.012,0-0.023,0.003-0.034,0.003L237.699,290.981z M238.337,291.21l11.765-17.408l5.709-8.446l0.04,0.017v0.255l0.405-0.082
          l0.024,0.01l-17.465,25.841c-0.072-0.035-0.146-0.067-0.222-0.095l-0.008-0.018h-0.052c-0.056-0.019-0.111-0.038-0.168-0.052
          L238.337,291.21z M229.801,253.543l26.051,11.071v0.326l-0.113,0.168l-26.131-11.138 M240.078,293.155
          c-0.021-0.165-0.058-0.323-0.113-0.475l0.675,0.505L240.078,293.155z M256.052,250.972c0.056,0.024,0.114,0.047,0.168,0.071
          l-0.168,0.924V250.972z M256.052,252.524l0.038,0.075c0.034,0.068,0.197,0.37,0.464,0.704v11.262l-0.204,0.042l-0.298-0.127
          V252.524z M256.052,265.001l0.101-0.149l0.177-0.036l0.225,0.095v0.241l-0.101,0.149l-0.176,0.036l-0.225-0.096V265.001z
           M228.746,251.439l0.172-0.318l-0.56-0.773l0.075-0.351l-0.429-1.978l-1.195-0.772l0.318-0.181l0.617-1.591l6.639,3.234
          l1.418-0.155l2.02,1.48l3.969,1.555l2.686,1.847l3.126-1.645c0.848-0.137,3.771-0.621,4.513-0.919
          c0.612-0.244,1.922-0.244,2.705-0.244c0.271,0,0.659,0.115,1.031,0.26v13.507l-26.179-11.125l-0.359-0.764l-0.361-0.039
          l0.181-0.234L228.746,251.439z M235.711,286.463l-0.323-0.534l0.182-0.074l0.045-0.369l0.914,0.147l-0.171-1.542l-0.472-0.317
          l0.48-0.362l-0.164-1.529l0.619-0.994l-0.327-0.87l0.284-0.518l-0.456-0.675l0.26-0.279l-0.306-1.929l-1.263,0.725l0.017-0.708
          l0.273-0.734l0.561,0.273l1.636-1.037l-0.793-0.832l-0.234-1.006l-1.281,0.078l-0.159-0.167l0.383-0.064l0.198-0.6l0.789-0.07
          l0.183-0.747l0.361-0.039l1.313-1.114l-1.698-1.151l-3.545,0.208l-0.196,0.109l0.022-0.713l-0.56-0.261l-0.068-0.561l-0.673-0.097
          l0.202-0.309l-0.321-0.688l0.262-0.566l-0.343-0.796l0.6-0.207l0.909-1.483l-1.421-0.425l-0.003-0.018l0.658-0.275l-0.611-1.442
          l-0.254-0.028l0.141-0.188l-0.396-0.523l0.183,0.006l0.154-1.784l-0.71-0.377l-0.082-0.635l-1.493-1.538l0.762-0.926l-0.472-1.717
          l0.085-0.064l26.229,11.146l-8.294,12.272l-9.153,13.541l-0.652-0.488l-0.245-0.183l-0.011-0.262l-0.295-0.634l-0.359-0.436
          l1.031-0.185l-1.059-1.64l-0.723-0.317L235.711,286.463z M241.522,302.811l-1.266-0.211l-0.351,0.088l0.128-0.089l0.218-1.304
          l-0.126-0.169l0.202-0.198l0.639-1.147l-0.726-1.653l-0.404,0.252l-0.2-0.88l-1.266,0.216l-0.401-0.424l-0.119-0.024l0.112-0.175
          l0.473,0.253l0.794-1.45l-0.123-0.296l0.326,0.043l3.237,7.731L241.522,302.811z M243.469,304.14l-0.522-0.632l-0.004-0.002
          l-3.28-7.834l0.575,0.076l3.52,8.408L243.469,304.14z M254.625,328.042l0.62-0.17l0.022,0.079l0.507,0.278l0.134,0.25
          L254.625,328.042z M257.899,329.671l0.258-0.207l0.305,0.236L257.899,329.671z M263.58,337.264l-0.423,0.187l-0.056-0.121
          l-0.434-0.08l0.306-0.53l0.661,0.561L263.58,337.264z M266.206,339.333l-0.257,0.259l0.096-0.914l0.047,0.095l0.142,0.098
          L266.206,339.333z M274.433,325.887l-0.182,0l-1.983-1.229l-0.379,2.361l1.104,0.706l0.24,0.346l-0.393,0.647l0.819,1.382
          l-0.11,0.083l-0.483,0.961l-0.939,0.371l-0.2,1.837l0.191,0.78l0.623,1.004l-0.857,0.892l0.145,1.249l1.227,0.427l0.151-0.173
          l0.017,0.129l0.67,0.405l0.049,0.097l-0.01,0.032l-0.774-0.014l-0.623-0.272l-3.11-0.22l-1.021-1.033l-0.077,0.006l-0.04-0.452
          l-0.325,0.028l-0.58-0.849l-1.834-0.96l-0.435,0.838l-0.355-0.045l-0.63,0.257l-0.553-0.548l-0.739,0.787l-0.564-0.017
          l-1.252-1.461l-0.793-0.009l-0.034-0.326l0.416-0.453l-0.281-1.383l-1.3,0.072l-0.14,0.182l0.073-0.677l-0.46-0.429l0.523,0.027
          l0.896-1.219l0.102,0.041l0.411-1.579l-0.741-2.412l-1.462,1.914l-0.723-0.079l-0.186,0.147l-0.271-0.25l-0.363-0.677l-0.343-0.188
          l-0.111-0.401l0.672-0.611l0.38-0.997l-0.188-0.748l0.303-0.709l-1.096-0.318l-0.176-0.383l-0.14-1.614l-1.221-1.744l-0.492-1.12
          l-0.045-0.897l-1.43-1.385l-0.533-0.886l-0.355-1.487l-2.172-0.5l-0.876-1.356l-1.552-1.5l0.332-0.551l-0.646-1.47l-0.705,0.103
          l-0.82-0.768l0.035-0.28l-0.614-1.437l-0.289-0.01l0.362-0.195l0.224-1.432l-1.412-0.075l-3.512-8.389l0.282,0.037l2.112-0.094
          l33.172,24.832c-0.339,0.851-0.543,1.769-0.543,2.741c0,0.417,0.041,0.823,0.109,1.22L274.433,325.887z M279.998,319.703
          l-0.04-0.058l0.358-1.054l-0.026-0.382l0.14-0.153l0.01,0.518L279.998,319.703z M335.529,314.688l-0.581-0.121l-0.089-0.228
          l0.224-0.439c0.052,0.014,0.105,0.024,0.158,0.037l0.3,0.767L335.529,314.688z M347.827,310.531l0.753,0.735l1.179,0.682
          l1.047,0.356l-0.301,0.463l0.488,1.686l0.206-0.164l-0.312,0.623l0.887,1.274l0.007,0.13l-0.253,0.075l-1.838-0.218l-1.113-0.464
          l-0.344,0.151l-0.749-0.712l-1.587,2.307l0.458,1.128l0.811,1.106l-0.107,0.175l-0.735,0.074l-0.247,0.245l-1.936-0.281
          l-0.631-0.318l-0.467,0.048l-0.757-0.824l-0.442-0.053l-0.622-0.643l-0.137-0.458l-0.671-0.693l-1.442-0.759l-1.175,0.156
          l-0.496-0.301h-0.432l-0.447-0.661l-0.424-0.075l-0.52-1.326c0.498,0.106,1.011,0.169,1.541,0.169c2.88,0,5.377-1.624,6.63-4.002
          h3.926L347.827,310.531z M347.094,309.461l0.342,0.5h-3.692c0.08-0.164,0.157-0.33,0.225-0.5H347.094z M344.925,304.687
          l0.216-0.245l0.629,0.613l0.027,0.086l-0.205,1.194l0.325,1.406l1.04,1.519h-2.914c0.295-0.802,0.464-1.664,0.464-2.567
          c0-1.05-0.227-2.045-0.62-2.951l0.192-0.156L344.925,304.687z M332.994,286.601l0.543,1.595l0.313,0.645l-1.341-0.025l-1.141,3.478
          l2.2,0.008l0.642-0.791l0.612,0.166l0.615-0.271l0.668-0.017l0.549,0.158l0.232,0.585l0.426,0.173l0.294,0.735l1.184,0.159
          l0.256-0.267l0.343,1.043l-1.87,2.17l0.666,1.971l-0.277,1.013l0.088,0.163c-0.32-0.044-0.647-0.068-0.98-0.068
          c-4.136,0-7.489,3.344-7.489,7.47c0,0.653,0.093,1.283,0.253,1.886l-1.117,1.041l-0.91,0.067l-2.059-0.598l-0.453,0.122
          l-1.028-0.751l-0.691,0.08l-0.52-0.56l-0.73-0.088l-1.167-1.529l-0.353-0.014l-0.548-0.765l-0.561,0.071l-1.154-1.42l-0.581-1.25
          l-1.002-0.731l-0.196-0.971l-0.004-0.003l0.208-0.412l0.797-0.891l2.157-0.606l0.686-1.01l-0.327-1.984l0.149-0.257l0.348-0.239
          l0.192-0.003l0.925,0.334l1.07,0.812l1.011,0.09l1.276-0.362l1.074-3.565l-0.3-0.611l0.506-0.15l0.433-1.5l0.909-1.13l-0.184-1.038
          l-0.488-0.78l-0.275-1.962l0.047-0.647l0.473-0.339l0.336,0.325l0.573-3.707l-0.483-0.632l0.521-0.503l0.52,0.48l0.877,0.04
          l1.419,2.25l0.965,2.154L332.994,286.601z M344.929,291.331l-0.465-0.129l-0.2-0.748l-0.935-0.68l-1.801-0.633l-1.491-2.174
          l-2.146-1.829l-0.657-0.832l-2.664-1.916l-0.25-0.052l-0.039-0.061l0.072-0.858l-0.526-0.797l-0.811-0.5l-1.928-2.084l-0.326,0.055
          l0.051-0.23l-1.217-1.866l0.25-0.308l0.048-0.365l0.358-0.103l0.437-1.088l-0.72-0.879l-2.686-2.076l-1.674-0.555l-0.356,1.377
          l1.891,2.143l0.084,0.234l-0.049,0.014l-1.319-0.468l-2.148-1.163l-1.416-1.292l-0.628-0.997l0.149-1.57l-1.396,0.47l-3.236-1.366
          l-1.791-0.325l-0.439-0.471l-2.577-0.501l-2.337-0.724l-1.051-0.421l-2.555-1.507l-1.222,0.854l-1.075,1.645l-0.927,0.746
          l-0.481,1.652l-1.003,0.972l-0.881,2.043l-2.155,8.568l-1.005,2.61l-0.308,2.039l0.062,1.52l-0.113,0.553l-0.76,1.28l-0.084,1.368
          l-0.388,0.873l-0.262,1.84l-0.709,0.876l-0.919,1.847l-2.026-0.929l0.179,2.229l0.505,0.323l-0.422,1.46l-1.59,2.508l-0.513,0.403
          l-0.815-0.77l-0.818,2.089l0.454,1.234l-0.165,0.466l-0.824-0.168l-0.308,1.304l1.018,1.767l0.346,0.4l-0.11,0.117l0.277,1.432
          l0.404,0.768l0.216,0.049l0.06,0.21l-0.334,0.513l0.508,1.695l0.017,1.644l-0.187,0.526l-1.159,1.308l-0.422,1.146l-0.104,0.08
          l-1.438-1.329l-0.377,0.438l-0.044-0.572l-0.892-0.147l9.684-57.778l64.604,13.353l-14.562,20.69L344.929,291.331z
           M345.505,291.954l14.637-20.796l0.165,0.034l0.021,0.104l0.022,0.114l0.302-0.107l-14.799,21.027L345.505,291.954z
           M360.53,308.869l-1.065-3.485l-0.535-1.2l-1.098-1.66l-0.321-1.943l-0.754-0.89l-0.136-0.368l-0.174-3.133l-1.044,0.531
          l-0.626-0.493l-0.811,0.117l-1.236-0.971l-1.487-0.448l-0.427-0.499l-1.915-0.262l-1.723-0.696l-0.792-0.563l-0.392-0.424
          l14.899-21.168l0.374,0.077l-0.056-0.284l32.697-11.555l4.626-1.635l-10.828,51.344h-27.155L360.53,308.869z M360.567,309.649
          l-0.009-0.188h27.104l-0.021,0.1l-0.195,0.4h-26.701L360.567,309.649z M363.113,356.054l-0.37-0.426l0.039-0.846l-0.987-0.811
          l-0.485-0.833l-0.262-1.779l-0.4-1.066l0.781-0.899l-0.461-0.749l0.618-0.895l-1.407-3.318l-0.187-1.062l0.207-2.296l-0.42-0.807
          l-0.035-0.851l0.082-1.74l0.308-0.733l-0.321-0.83l0.551-1.905l-0.308-0.858l0.235-0.541l-0.046-0.505l0.343,0.087l0.464-1.825
          l0.383-3.17l0.821-0.939l-0.094-0.737l0.869-1.12l0.135-0.155l0.471-2.607l-0.048-1.382l0.482-1.027l-0.074-1.457l-0.266-0.744
          l0.128-0.5l-0.327-0.585l0.037-0.711l-1.341-1.429l-0.566-0.385l-0.079-0.208l0.372-0.864l-0.264-0.921l-0.831-1.461h26.489
          l-14.417,29.517l-0.225,0.034l0.056,0.306l-0.133,0.259l-8.384,16.314L363.113,356.054z M372.832,340.401l-0.034,0.005l0.025-0.052
          L372.832,340.401z M364.082,358.174l-0.116-0.303l0.571-1.143l-0.11-0.052l8.247-16.05l0.193-0.029l0.101,0.553l-5.965,11.609
          l-2.839,5.523L364.082,358.174z M377.904,376.229l-0.562-0.998l-3.87-3.832l-1.105,0.012l-0.68-0.502l-0.554-0.784l-0.441-0.297
          l-0.199-0.594l-0.487-0.278l-1.056-1.882l0.238-0.608l-1.171-1.052l-0.355-1.237l0.033-0.639l-0.208-0.586l0.178-1.19l-0.729-0.12
          l-0.093-0.209l-0.559-0.512l-0.262-0.595l-0.737-0.395l-0.147-0.375l-0.838-1.099l2.713-5.279l6.016-11.705l4.167,22.883
          l2.3,12.632L377.904,376.229z M419.22,409.681l-0.795,0.046l-0.406,0.335l-1.187,0.151l-0.306-0.049l-0.221-0.7l-1.095-0.975
          l-0.719,0.378l-0.199-0.062l-0.917-0.629l-1.687-2.393l-2.186-1.858l-3.666-1.402l-0.776-0.476l-1.092-0.179l-2.633-1.896
          l-1.034-0.523l-0.723-0.751l-1.112-0.646l-0.72-0.094l-1.529-1.648l-0.324-0.219l-0.256-0.772l-1.306-1.217l-3.297-4.935
          l-1.943-0.829l-0.407-0.452c0.201-0.48,0.344-0.987,0.441-1.512l44.426,6.86l-12.687,17.507L419.22,409.681z M421.669,418.939
          l0.115-0.4l0.258,0.271l1.539,0.179l0.905,0.862l1.994,0.628l0.458,1.123l0.682,0.812l-0.238,0.229l-1.855-1.427l-2.818-0.51
          l-0.643,0.775l-0.576,0.356l-0.596,0.671l-1.64-0.711l-1.209-1.164l-0.588-0.885l-0.919-0.391l0.411-0.154l1.794-0.135l1.716-0.67
          L421.669,418.939z M418.715,423.157l1.16,5.204l8.54,38.315l-0.463-0.151l-0.167-0.37l-9.668-43.373l0.195,0.19L418.715,423.157z
           M414.843,419.629l-14.246,15.983c-0.051-0.186-0.108-0.368-0.172-0.548l15.833-17.775l0.424-0.027l0.054,0.243l-0.272,0.305
          l-0.952,0.334l-0.802,1.213L414.843,419.629z M392.479,430.013l-3.594-14.31l0.48-0.147l3.66,14.57
          c-0.102,0.005-0.204,0.011-0.305,0.02L392.479,430.013z M416.523,417.072l0.088-0.122l0.026,0.114L416.523,417.072z
           M417.237,416.526l0.071,0.317l-0.081,0.112l-0.068,0.076l-0.319,0.021l-0.071-0.317l0.076-0.105l0.075-0.084L417.237,416.526z
           M417.108,416.334l0.069-0.078l0.016,0.072L417.108,416.334z M417.49,417.661l-0.506,0.038l-0.031-0.137l0.299-0.336l0.138-0.009
          L417.49,417.661z M417.439,416.513l0.113-0.007l-0.088,0.121L417.439,416.513z M416.899,417.322L416.899,417.322L416.899,417.322
          l-0.017-0.072l0.086-0.005L416.899,417.322z M417.593,417.204l2.175-0.14l-1.265,0.521l-0.812,0.061L417.593,417.204z
           M419.719,416.606l0.18,0.248l-2.35,0.151l-0.027-0.12l0.288-0.397l1.855-0.119L419.719,416.606z M419.248,414.59l0.111-0.004
          l-0.021,0.36l0.281,1.226l-1.655,0.107l1.245-1.717L419.248,414.59z M418.689,414.186l0.357,0.258l-1.343,1.852l-0.309,0.02
          l-0.076-0.339l1.308-1.8L418.689,414.186z M397.806,404.901l1.236,0.239l2.175,2.163l-0.624,0.743l1.899,0.734l0.483,0.603
          l2.215,1.217l0.916,0.889l1.036,0.381l1.565,1.179l2.109,0.308l1.598-0.883l0.636,0.053l1.292,0.777l1.658,0.204l0.253,0.345
          l2.144,0.293l-1.601,2.209l-0.198,0.013l-0.117,0.007l0.064,0.289l-16.205,18.18c-1.093-2.774-3.797-4.741-6.966-4.741
          c-0.051,0-0.099,0.009-0.149,0.01l-3.671-14.615l0.104-0.032l-0.291-0.711l-0.074-0.293l0.998,0.024l0.458-1.004l0.868-0.557
          l-1.014-2.579l-0.168-0.115l0.854-0.26l0.113-0.319l0.399,0.427l1.252,1.025l0.693,0.322l1.261,1.598l0.892,0.396l1.295,1.415
          l1.216,0.167l0.867-0.409l0.549-1.289l-0.198-1.033l-0.986-1.213l0.125-0.292l-0.452-0.671l0.646,0.102l1.031-0.503l0.611-0.975
          l-0.408-1.09l-1.936-1.221l-1.318-2.107L397.806,404.901z M394.88,404.478l0.903,0.794l1.39,2.222l1.705,1.075l-0.146,0.072
          l-1.184-0.188l-1.671,0.664l1.193,1.807l-0.161,0.375l1.32,1.624l0.041,0.214l-0.133,0.312l-0.199-0.027l-1.141-1.247l-0.839-0.372
          l-1.273-1.612l-0.768-0.319l-1.052-0.854l-1.416-1.515l-2.322-1.556l-1.745-0.443l0.062,0.956l-1.377-0.844l-1.465-1.652
          l0.148-0.066l2.108,1.392l1.509,0.078l0.565,0.409l2.489,0.329l0.081-0.971l0.39,0.058l1.31,0.666l0.236-1.729l-0.633-0.942
          l2.02,1.091L394.88,404.478z M388.559,408.089l-0.3,0.067v-0.455L388.559,408.089z M371.216,382.098l-2.843-4.033l0.595,0.031
          l1.509,0.81l-0.36,0.208l0.813,1.44l1.838,0.98l0.289-0.39l1.39,0.695l0.208-0.023l-0.044,0.192l-0.401-0.153l-0.174,1.733
          l0.625,1.646l0.067,0.675l-0.923-1.206l-1.316-1.321h0.028L371.216,382.098z M383.415,392.3l0.142,0.133l0.918,3.655l1.755,6.989
          l-0.62-0.409l-0.093-0.371l-2.49-9.914C383.158,392.361,383.286,392.329,383.415,392.3z M384.541,393.355l1.265,0.407l0.195,1.045
          l0.605,0.461l0.376,1.159l0.904,0.955l1.545-0.206l2.231,2.05l0.459,0.241l2.078,2.606v0.162l-0.598-0.323l-0.592-0.622
          l-0.745-0.103l-2.181-1.85l-1.864,0.468l2.753,3.733l-0.835-0.123l-0.083,0.994l-0.552-0.073l-0.617-0.446l-1.544-0.08l-0.864-0.57
          l-2.651-10.555L384.541,393.355z M359.793,365.695l0.542,0.039l-0.471,0.916l-0.25-0.608L359.793,365.695z M359.529,365.768
          l-0.016,0.03l-0.01-0.023L359.529,365.768z M354.757,364.989l0.021,0.185l-0.115-0.295L354.757,364.989z M348.631,358.142
          l-2.333-3.581l-0.015-0.049l0.469,0.209l2.438,3.532L348.631,358.142z M353.518,365.05l-0.329-1.041l-0.7-0.413l-0.628-0.771
          l-0.277-0.704l-0.598-0.333l-0.888-1.03l-0.601-2.063l0.389,0.222l0.314,0.917l1.081,1.745l1.458,1.616l1.56,1.302l0.566,1.444
          l0.021,0.188l0.065,0.032l0.212,0.54L353.518,365.05z M355.899,368.034l-0.683-1.742l0.666,0.323l0.69,1.761L355.899,368.034z
           M357.738,369.253l-0.7-0.64l-0.196-0.1l-0.655-1.671l0.719,0.659l0.774,1.272l0.194,0.217L357.738,369.253z M357.892,369.394
          l0.124-0.242l0.356,0.4l-0.098,0.191L357.892,369.394z M368.819,384.779l-0.781-0.104l-1.028-0.897l-1.716,0.085l-0.461-0.175
          l-2.787-2.712l0.924-0.142l0.543-0.814l-0.184-1.189l-1.074-2.351l-0.898-0.59l-0.405-0.675l-0.974-0.28l-0.592-1.4l0.625,0.086
          l-0.389-1.966l-1.04-0.773l-0.304-0.39l0.037-0.391l0.2-0.39l0.184,0.206l1.591,1.08l0.321-1.711l-0.655-2.38l0.595-1.157
          l0.393,0.028l-0.027,0.133l1.273,1.201l0.511-0.206l0.171,0.316l0.691,0.204l0.325,0.924l0.943,0.481l0.57,0.949l0.43-0.101
          l0.552,1.412l-0.039,0.816l0.383,0.867l1.785,1.695l0.168,0.87l0.861,0.952l1.389,0.275l2.22,1.114l0.246-0.075l0.085,0.146
          l0.158,0.023l0.065,0.521l0.776,0.439l0.252,0.365l-0.011,1.2l-0.005,0.001l-1.436-0.718l-0.896-0.053l-0.055,0.074l-0.034-0.018
          l0.033-0.019l-0.015-1.374l-2.936-1.576l-0.622-0.032l-0.495-0.484l-0.34-0.064l-0.428-0.61l-2.043-1.667l-1.151-0.595l-2.034-0.47
          l-0.665,1.598l0.417,1.203l1.268,0.101l0.527,0.308l0.769,0.723l0.324,0.981l0.712,0.632l1.116,0.359l0.493,0.45l1.96,2.835h-0.31
          l3.507,3.513l-2.711,0.34L368.819,384.779z M366.797,377.041l-0.418-0.134l-0.174-0.155l-0.214-0.646l0.338,0.27L366.797,377.041z
           M369.838,385.917l2.727-0.342l0.112,0.112l0.262,0.344l-2.696,0.338L369.838,385.917z M380.065,399.44l-0.163-0.22l0.027-0.977
          l0.027-0.061l1.552-0.122l0.67-0.872l-0.047-0.894l-1.937-2.047l-2.607-1.441l-1.229,0.347l-0.111,0.822l-1.302-0.318l-0.318-0.174
          l0.237-0.781l-1.282-1.923l-0.159-0.861l-3.018-3.368l2.673-0.336l1.173,1.543l0.63,0.145c1.131,2.691,3.781,4.589,6.89,4.589
          c0.359,0,0.71-0.033,1.055-0.083l2.536,10.095l-0.475-0.314l-0.208,0.093l-0.332-0.27l-2.015-0.305l0.169,2.147l0.945,1.065
          l-0.952-0.999l-0.917-0.114l-0.353-0.228l-0.682-1.001l0.125-0.055l0.411-1.24l-0.181-1.331L380.065,399.44z M380.256,406.822
          l-0.495-0.347l0.046-0.027l0.102,0.121l0.699,0.342L380.256,406.822z M380.854,405.362l-0.43-0.513l0.629,0.405l0.726,0.091
          l1.328,1.395l1.271,0.785l0.481,1.023l2.57,0.478l0.703-0.573l-0.286,0.974l0.913,1.47l0.62,0.426l0.395,1.005l-0.203,0.131
          l-0.23,0.504l-0.443-0.011l-0.615-0.295l-0.345,0.097l-0.083-0.258l-0.752-0.285l-0.854-1.431l-2.358-2.093l0.593-0.517
          l-1.071-0.999l-0.723-0.26l-0.308-0.802L380.854,405.362z M390.701,426.528l-3.002-3.369l-0.771-0.2l-0.201-0.202l0.353-0.142
          l-0.986-1.35l-0.574-0.15l0.202-0.043l0.531-1.344l0.126-0.966l-0.02-0.062l1.14,0.01l-0.761-1.509l0.582-0.606l-1.252-1.476
          l-0.454-0.021l-0.079-0.198l-1.455-0.874l-0.402,0.06l0.101-0.684l-0.294-0.188l0.601-0.58l-1.594-0.64l0.553-1.187l0.276,0.113
          l0.432-0.371l1.27,1.101l1.059,1.775l0.244,0.093l0.359,0.243l0.519,1.616l0.909,0.56l0.579-0.177l3.15,12.542L390.701,426.528z
           M391.393,428.803l0.517-0.235l0.33,1.312l-0.262-0.145l-0.559-0.827L391.393,428.803z M399.156,440.013l-0.268,0.004l0.099-0.141
          l0.192,0.067L399.156,440.013z M385.333,436.627l-0.665-0.299l-1.003-1.438l-0.468-0.269l-0.223-0.843l-0.423-0.47l0.073-0.803
          l-0.882-0.644l-1.375-0.299l-0.141-0.504l-0.513-0.181l-0.162-0.558l-1.17-0.624l-0.008-0.382l-0.497-0.385l0.162-0.469
          l-0.536-1.191l0.75,1.156l0.741,0.121l0.154,0.353l0.801,0.473l0.296,0.682l0.785,0.711l1.007,0.051l1.219,1.681l0.688,0.062
          l0.695,1.554l0.917-0.675l0.35,1.027l0.564,0.234c-0.339,0.81-0.534,1.691-0.566,2.615l-0.413-0.137L385.333,436.627z
           M385.891,437.521c0,0.017-0.003,0.033-0.003,0.05c0,0.024,0.006,0.047,0.006,0.072l-0.254-0.205L385.891,437.521z
           M410.228,461.585l-1.682-1.302l0.866,0.453l0.904,0.822L410.228,461.585z M410.584,457.863l2.259,1.851l0.675,0.735l0.056,0.202
          l-0.536-0.299l-0.997,0.054l-0.333,0.252l-0.384-0.209l-0.976-0.887l-1.482-0.806l-0.456-0.745l-1.048-0.503l-0.404,0.292
          l-0.883-0.89l-0.187,1.015l-0.223-0.263l-0.472-1.501l-0.54-0.395l-0.298-0.646l-0.794-0.442l-0.275-0.616l-1.186-0.881
          l-1.167-0.576l-0.463-0.453l-0.806-2.076l-1.682-1.648l-1.339-1.643l0.129-0.521l-0.374-0.718l0.233-0.031l0.573,0.339l0.407,0.004
          l0.153,0.313l0.617,0.663l1.146,0.595l3.902,2.863l0.879,1.451l0.847,0.28l0.893-0.346l0.369,0.583l0.549,0.372l0.055,1.474
          l0.859,1.838L410.584,457.863z M426.873,465.431l-0.788-0.942l-0.933,0.121l-0.214-0.797l-2.316-1.817l-1.123-2.145l-1.78-1.52
          l-1.17-0.197l-0.253-0.474l-1.925-0.518l-0.524,0.104l-0.554-0.531l-0.692-1.319l-0.557-0.566l-0.176-0.849l-0.686-0.972
          l-0.604-0.743l-2.59-1.772l-1.598-2.526l-1.677-2.075l-0.417-1.009l-0.845-0.88l-0.506-0.304l-0.022-0.337l-0.75-0.926
          l-1.277-0.914l-1.601-0.467l-0.191,0.052l-0.412-0.958l0.177-0.526l-0.225-0.3c0.137-0.562,0.221-1.146,0.221-1.751
          c0-0.595-0.077-1.171-0.209-1.726l14.281-16.023l0.302,0.617l1.216,0.517l0.385,0.578l1.013,0.992l9.656,43.317L426.873,465.431z
           M428.863,473.625l-1.677-1.742l-0.103-0.582l-0.176-0.269l0.505,0.281l0.4-0.442l0.188-0.092l-0.037,0.137l0.6,1.719l0.492,0.137
          l0.195,0.875L428.863,473.625z M429.574,473.666l-0.116-0.007l-0.166-0.745l0.151,0.142l0.292,0.743L429.574,473.666z
           M429.631,470.493l0.101-0.373l-0.533-1.149l-0.163,0.037l0.056-0.206l-0.301-0.424l1.048,0.344l0.566,0.645l1.409,0.49
          l1.017,0.954l-1.29-0.135l-0.276,0.304l-1.272-0.878L429.631,470.493z M432.027,476.309c-0.133-0.017-0.267-0.033-0.402-0.043
          l-0.052-0.041l-0.436,0.018c-0.016,0-0.032-0.002-0.049-0.002c-0.003,0-0.006,0-0.009,0l-0.052-0.328l-0.512-1.102l1.122,0.169
          l0.297-0.314l0.123,0.12l0.042,0.201l-0.282,0.534L432.027,476.309z M434.291,473.307l-0.565-0.086l-0.79-0.648l-0.556,0.024
          l-0.287-0.3l0.048-0.051l0.875,0.092l0.801,0.414l1.045,0.885L434.291,473.307z M438,476.822l-0.174,0.141l-0.087-0.211
          l-0.509-0.451l-0.461-1.241l-0.943-0.299l-0.544-0.769l1.063,0.899l0.689-1.734l-0.509-2.064l-1.42-0.397l-0.643-0.411l-1.854-1.74
          l-1.336-0.465l-0.576-0.656l-2.06-0.674l-9.694-43.488l1.82,0.828l0.905-0.148l0.72-0.893l0.175-0.107l1.49,0.165l-0.225-0.67
          l1.073,0.194l1.664,1.278l0.986,1.471l0.038,0.377l-0.477,0.954l0.105,1.239l0.558,0.975l1.119,1.248l0.909,0.216l1.73-1.385
          l0.105-1.87l-2.076-2.065l-0.321-0.752l-0.583-0.762l0.521-0.558l0.057-1.04l-0.958-1.046l-0.674-1.649l-2.334-0.735l-1.016-0.968
          l-1.498-0.175l-0.459-0.481l32.959-2.122l17.096,40.119c-2.093,1.325-3.487,3.65-3.487,6.306c0,0.969,0.191,1.892,0.527,2.741
          l-3.507,1.604l-0.46-0.835l0.082-0.143l0.139-2.937l-0.187-2.131l-0.576-0.648l-1.157-0.59l-0.735,1.195l0.688,2.479l-0.343,1.388
          l-0.847,0.354l-0.708,0.839l1.442,1.848l0.42,0.206l-7.594,3.474l-0.411,0.166l-0.06-0.21l-1.998-1.537l-0.982-0.178l-0.581,0.437
          l-0.496-0.772l-1.391-0.98l-0.836,1.124l0.564,1.229l0.641,0.72l0.262,0.679l1.034,1.27l-12.439,5.69L438,476.822z
           M428.642,427.003l0.245-0.49l1.214,1.208l-0.03,0.53l-0.522,0.417l-0.515-0.562l-0.351-0.613L428.642,427.003z M464.165,469.831
          l-0.718-0.464l-1.317,0.834l-0.464-0.523l-1.066,0.133l-0.895,0.376l-0.957,0.008l-0.629,0.466l-0.146,0.818l-0.136,0.13
          l-0.748-0.027l-0.136-0.954l7.327-3.352l0.133,0.232l-0.084,0.291l0.226,0.818l-0.458,0.83L464.165,469.831z M456.768,470.492
          l-0.495-0.324l7.602-3.478l0.094,0.046l0.21,0.366L456.768,470.492z M452.581,470.609l0.414-0.07l0.067-0.576l1.21,0.932
          l0.439,1.526l-0.147,0.082l0.72,1.707l-0.262,0.25l-0.389-0.238l-0.604,0.044l-0.151-1.433l-0.294-0.337l0.347-0.312
          L452.581,470.609z M440.725,477.719l-1.123,0.076l11.908-5.448l-0.027,0.024l0.336,0.385l-11.035,5.048L440.725,477.719z
           M452.012,483.134l-2.026-1.111l-0.434-0.468l-1.656-1.005l-0.744-0.824l-1.25-0.389l-0.271,0.479l-0.076-0.041l-0.112,0.036
          l-0.402-0.755l-1.539,0.279l-0.546-0.339l-0.467,0.147l-1.114-0.479l-0.476-0.692l11.057-5.059l0.484,0.553l0.064,0.627
          l-0.624,0.151l-0.75,0.569l-0.11,0.673l-1.062-0.93l-1.496,0.723l0.667,1.24l1.544,1.2l0.161,0.477l1.263,1.916l1.533,1.577
          l0.293,0.695l-1.526,0.227L452.012,483.134z M460.2,487.947l0.123,1.568l-0.908,0.077l-0.92-1.521l-1.545-1.495l-1.018-0.324
          l-0.409,0.523l-1.882-1.825l0.253-0.467l1.11-0.29l0.27,0.084l0.914,1.504l2.283-0.567l-0.579-0.694l0.645-0.05l-0.711-1.524
          l-0.983-0.647l-0.452-0.686l0.104-0.639l-0.655-0.755l-1.721-1.038l-1.412-2.032l0.542-1.044l0.708-0.329l0.307-0.022l0.961,0.588
          l0.814-0.776l0.469-0.052l0.574-0.898l-0.268-0.643l2.063-0.386l1.28-1.21l0.739-0.118l0.344,0.227l1.345,1.545l1.118,0.121
          l0.415-0.136l0.733,0.385l1.097-0.361l0.885,0.275l0.725-0.905l-0.096-0.339l0.938-1.116l-0.042-1.57l-1.48,0.341l-0.943,0.758
          l0.729-0.888l-0.085-0.518l0.441-0.97l-0.307-0.582l-0.023-0.97l-0.248-0.992l-0.121-0.22l3.447-1.577
          c0.662,1.215,1.655,2.221,2.854,2.91l-10.182,24.213l-0.774-0.362l0.54-0.956l1.296-1.251l-1.53-2.054l-1.198-0.491L460.2,487.947z
           M461.024,493.412v0.005l-0.095-0.096L461.024,493.412z M460.539,497.066l0.522-0.09l-1.205,2.865l-0.038-0.01l0.013-0.241
          l0.261-0.845l-0.099-0.379L460.539,497.066z M459.439,496.712l0.846,0.298l-0.399,0.949l-0.005-0.017l-0.373-0.334l0.123-0.685
          L459.439,496.712z M464.498,506.88l-1.051,1.488l-0.088,0.355l-0.176-0.418l-1.21-0.678l-0.559-0.697l-0.808,0.156l-0.181-0.276
          l0.301,0.235l1.545-0.774l-0.41-0.979l0.324-0.513l-0.614-1.152l-1.205,0.039l-0.315,0.449l-0.152-0.003l-0.128-0.093l0.541-0.338
          l0.436-0.993l-0.201-0.47l4.766,5.612l-0.038,0.005L464.498,506.88z M459.934,501.609l0.019-0.089l0.193,0.228L459.934,501.609z
           M470.557,514.791l-0.347,0.083l-1.031-0.767l-0.643-2.004l-1.335,1.933l-1.502-0.951l-0.501-0.191l0.376,0.048l0.974-1.129
          l-0.83-1.363l-0.111-0.451l0.828-0.844l4.939,5.816L470.557,514.791z M466.609,508.977l0.264-0.27l-1.102-0.943l-0.172,0.025
          l-5.582-6.572l0.08-0.374l0.261,0.072l12.144,14.301l-0.731-0.159L466.609,508.977z M460.063,500.566l0.065,0.077l-0.032-0.009
          L460.063,500.566z M472.292,516.549l-0.119,0.073l-0.475-0.371l0.419-0.407l0.288,0.339L472.292,516.549z M472.49,515.902
          l-0.196-0.231l0.326-0.317l0.029,0.034L472.49,515.902z M488.062,514.93l-0.288-0.038l-0.014-0.143l0.899,0.266l0.191,0.153
          L488.062,514.93z M489.736,513.525l-0.079,0.366l-0.289-0.232l-1.724-0.508l-0.305,0.093l0.516-0.483l0.645-0.227l0.267-0.013
          l0.901,0.837l0.908,0.095l0.296,0.163l0.064,0.225L489.736,513.525z M491.593,510.535l-0.375,0.533l-0.438,0.145l-1.021-0.244
          l-1.439,0.072l-1.258,0.409l-0.721,0.676l-0.805-0.027l-0.589,0.394l-0.469-0.543l-1.192-0.413l-0.781,0.247l-0.488-0.337
          l-1.578-0.191l-0.875-0.763l-0.876,0.707l-0.988-0.03l-1.017,0.904l0.741,1.188l2.108,0.305l0.602,0.371l1.011,0.01l0.295,0.305
          l1.432,0.85l0.028,0.037l-1.199,0.204l-0.224,0.25l-0.513-0.204l-1.406,0.161l-0.543-0.159l-0.979-0.662l-2.119,0.799l1.863,1.434
          l-0.246,0.167l-1.071-0.193l-0.341,0.049l-0.286-0.305l-0.555-0.078l-0.324-1.153l-1.117,0.352l-0.835-0.369l-12.226-14.398
          l32.826,9.052l-0.248,0.103l-0.94,0.046L491.593,510.535z M491.862,515.479l-0.02-0.005l0.307-0.157l0.018,0.097L491.862,515.479z
           M496.895,513.249l2.334,1.108l0.411,0.658l1.128,0.378l0.435,0.419l-0.135,0.021l-1.787-0.062l-0.992-0.23l-1.583,0.104
          l-1.262-0.609l-0.978,0.066l-0.721-0.836l-1.042,0.219l-0.017,0.022l-0.468-1.637l0.405-0.134L496.895,513.249z M494.803,509.809
          l-0.792,0.105l-0.105,0.044l-33.386-9.207l-0.05-0.014l-0.517-0.609l0.02-0.046l34.971,9.643L494.803,509.809z M501.732,506.601
          l-1.803,0.35l-1.014,0.557l-1.166,0.24l-0.683,0.495l-0.871,0.231l-0.598,0.859l-0.418,0.25l-35.077-9.673l1.255-2.984l0.69-0.119
          l1.344,0.554l1.447-0.312l-0.185-1.586l-1.311-0.207l-0.363-0.312l0.023-0.015l-0.452-0.818l-0.001-0.022l10.927-25.986
          c0.885,0.367,1.855,0.572,2.873,0.572c3.973,0,7.214-3.088,7.465-6.987l14.822-0.602l21.558-0.875l-13.236,39.063l-2.664,7.859
          L501.732,506.601z M504.498,507.164l3.351-9.888l12.564-37.075l0.31-0.013l0.186,0.11l-14.188,41.869l-1.698,5.011L504.498,507.164
          z M520.571,459.734l0.083-0.244l0.246-0.01l0.143,0.084l0.001,0.002h0l0.057,0.168l-0.082,0.243l-0.244,0.01l-0.146-0.086
          L520.571,459.734z M538.587,525.731l-2.299-0.738l-0.181-0.343l-0.55-0.547l-0.279-0.885l-1.773-2.123l-1.312,0.579l-2.955-0.176
          l-0.344,0.19l-0.253-0.298l-0.812-0.337l-0.153-0.484l-0.424-0.401l-1.588-0.893l-1.203-0.228l-4.076-2.931l-1.257-1.804
          l-1.042-0.65l-4.56-0.741l-1.313,0.369l-3.477,0.193l-0.521-0.266l-1.579-0.246l-0.125-0.096l1.576-0.052l0.997-1.246l-0.098-0.215
          l0.186,0.004l0.011-0.022l0.219,0.256l1.863-0.587l0.647,0.357l1.467-0.481l-0.191-0.638l-0.096-0.512l0.777-1.008l-1.059-0.232
          l26.739-11.524l1.516,29.141L538.587,525.731z M509.835,509.806l0.152,0.039l-0.093,0.029L509.835,509.806z M538.296,496.913
          l-0.404,0.278l1.222-0.25l-26.641,11.487l-0.525-0.115l-0.575,0.047l27.532-11.867L538.296,496.913z M539.515,496.315l0.018,0.337
          l-0.719,0.147L539.515,496.315z M539.709,496.181l0.133-0.092l0.048-0.021l0.312-0.064l0.022,0.432l-0.098,0.068l-0.113,0.049
          l-0.283,0.058L539.709,496.181z M541.268,526.152l-1.522-29.267l0.318-0.137l0.176-0.036l1.536,29.519L541.268,526.152z
           M541.124,495.817l-0.705,0.486l-0.018-0.339l0.72-0.147l-0.002,0.001L541.124,495.817z M605.921,577.598l-0.146-0.536l6.556-5.721
          c0.005,0.202,0.042,0.394,0.096,0.579L605.921,577.598z M607.423,577.903l-0.275-0.04l-0.297,0.235l-0.265-0.057l-0.283-0.51
          l6.19-5.402c0.037,0.091,0.084,0.175,0.132,0.26l-0.543,2.548l-0.488,2.289l-0.235-0.736l-1.344-0.278l-0.715,0.868l-1.608,0.322
          L607.423,577.903z M609.599,583.223l-0.105-0.212l0.551-0.338l-0.421-0.799l0.146-0.249l-0.513-0.905l-0.867-0.086l-0.024-0.121
          l0.254-0.588l0.354-0.374l1.347,0.59l0.354-1.365l0.67-0.386l-1.126,5.28L609.599,583.223z M610.688,584.007l-0.292-0.21
          l1.182-5.542l0.29-0.167l-0.152-0.477l1.061-4.977c0.12,0.163,0.263,0.307,0.422,0.433l-2.411,11.304L610.688,584.007z
           M631.307,584.342l-6.586,2.823l-1.73,1.517l-3.522,1.117l-0.717-0.709l-1.803-0.365l-0.299,0.179l-0.866-0.455l-0.668,0.47
          l-0.433-0.429l-1.051-0.416l-1.074-1.214l-0.349-1.073l-0.762-0.186l-0.475-0.552l-0.071-0.258l2.472-11.589
          c0.36,0.234,0.789,0.373,1.251,0.373c0.582,0,1.107-0.222,1.512-0.578l14.227,9.303
          C630.674,582.997,631.005,583.709,631.307,584.342z M630.212,581.961l-13.923-9.105c0.115-0.121,0.214-0.256,0.301-0.401
          l13.268,8.677C629.969,581.399,630.087,581.675,630.212,581.961z M616.775,565.111c0.415,0.206,5.004,2.841,8.114,4.635
          c2.041,4.157,4.185,8.749,4.297,9.309c0,0.299,0.222,0.957,0.541,1.752l-13.041-8.528c0.148-0.303,0.238-0.639,0.238-0.999
          c0-1.267-1.03-2.294-2.3-2.294c-0.298,0-0.581,0.061-0.842,0.164l-0.476-6.321C615.003,563.975,616.502,564.974,616.775,565.111z
           M613.095,562.686l0.494,6.556c-0.174,0.088-0.334,0.196-0.479,0.324l-0.15-1.988l-0.395-5.251
          C612.743,562.448,612.918,562.567,613.095,562.686z M594.2,545.65l2.412,2.645l0.27,0.033c0.023,0.003,2.34,0.294,3.755,0.721
          c1.277,0.384,2.652,1.436,3.265,2.049c0.491,0.49,1.458,2.542,1.465,3.089c-0.335,0.812,0.095,2.186,0.301,2.752l0.062,0.169
          l2.218,2.062l0.087,0.07c0.638,0.437,2.483,1.699,4.319,2.944l0.57,7.566c-0.321,0.355-0.527,0.808-0.575,1.311l-2.11,1.841
          l-4.648,4.056l-0.691-0.134l-0.014-0.042l0.239-0.989l-0.363-0.132l0.332-0.034l0.358-1.184l-0.598-0.586l-0.149-0.916
          l-1.296-0.551l-1.069,1.565l0.283,1.269l-0.062,0.139l-0.636-0.868l-0.709-1.429l-0.465-0.094l-0.116-0.293l-0.703-0.184
          l-0.4-0.843l-1.015-1.328l-0.838-0.681l-0.658-1.845l-0.89-0.823l-0.286-0.688l-2.985-1.768l-0.934,0.012l-0.635-0.587
          l-0.386-0.126l-0.191-0.709l-1.126-0.601l-0.605-0.669l-1.6-1.173l-0.929-0.163l-0.607-0.573l-0.63,0.039l-0.554-0.611l-2.202-1.02
          l-1.156-0.026l-0.837-0.271l-0.57-0.587l-0.565-0.218l-0.589-1.339l-1.348-1.16l-1.004-0.32l-0.264,0.31l-1.512-1.116l-0.246-0.928
          l-0.422-0.405l-0.082-0.51l19.091-6.919l0.764-0.277C593.984,545.163,594.195,545.639,594.2,545.65z M593.638,544.391
          l-19.805,7.178l-0.081-0.503l19.68-7.132C593.502,544.088,593.57,544.241,593.638,544.391z M556.159,496.114l0.063,0.154
          l3.75,3.448l1.578,3.159l2.799,3.15l1.724,1.419l3.141,1.148c0.67,0.394,1.8,1.113,2.184,1.499c0.571,0.57,1.761,1.758,2.479,2.624
          c0.782,0.937,3.274,2.994,3.381,3.081l3.211,1.18l0.334-0.335c0.469-0.47,1.083-0.944,1.289-0.996
          c0.385-0.093,0.966-0.152,2.339,0.153c1.045,0.232,2.387,1.142,3.274,1.742l0.353,0.237c1.297,0.868,1.97,1.713,2.057,2.583
          c0.138,1.399-0.312,3.371-0.689,4.256c-0.288,0.671-1.623,2.235-2.396,3.064l-0.24,0.257l0.195,1.544l1.672,5.062l0.047,0.109
          c0.037,0.071,3.729,7.16,4.021,7.749c0.113,0.224,0.363,0.767,0.625,1.347l-19.63,7.114l0-0.003l-2.635-1.271l-0.233-0.872
          l-0.979-0.836l-0.266-1.168l-0.47-0.93l-1.034-1.253l-1.039-0.449l-1.737-1.354l-0.192-0.244l0.026-1.14l-0.812-1.005l-1.743-1.505
          l-1.117-1.449l-2.068-2.09l-0.351-0.491l-0.267-1.063l-0.749-0.89l-1.854-1.31l-0.636-0.899l-1.461-1.125l-0.892-0.905
          l-0.504-1.057l-0.862-0.598l-1.196,0.541l-1.288-0.144l-1.785-0.524l-1.04,0.164l-1.304-0.479l-1.465,0.024l-1.788-0.28
          l-1.539-29.59l14.6-2.982C555.421,494.409,555.883,495.444,556.159,496.114z M554.937,493.506l-14.367,2.935l1.05-0.724
          l13.033-2.662C554.735,493.162,554.831,493.317,554.937,493.506z M551.039,489.819c0.144,0.989,1.3,1.698,2.418,2.384
          c0.364,0.224,0.789,0.487,1.025,0.681l-12.439,2.541l8.879-6.121C550.973,489.485,551.017,489.662,551.039,489.819z M550.858,489.1
          l-9.316,6.427l-1.151,0.235l-0.002-0.041l10.302-7.107C550.752,488.775,550.806,488.937,550.858,489.1z M535.39,471.349
          c-0.477,2.068,0.489,3.448,0.719,3.739l2.557,3.994l0.355,0.04c0.025,0.003,2.571,0.295,3.924,0.972
          c0.922,0.461,2.296,2.69,2.945,3.988l0.074,0.148l4.323,3.429c0.089,0.188,0.208,0.465,0.327,0.762l-10.827,7.464l-0.291,0.059
          l0.004,0.075l-28.751,12.392l-0.24,0.02l-0.512-0.133l-0.67-0.578l-1.103-0.242l-1.564,0.011l-0.543-0.279l-0.884-0.025
          l6.59-19.448l9.263-27.334l3.085,1.819l10.579,6.236C535.584,469.524,535.644,470.246,535.39,471.349z M533.222,466.844
          c0.467,0.438,0.829,0.808,1.138,1.151l-13.208-7.786l0.013-0.038l0.159-0.006l0.134-0.005l-0.145-0.424l0.003-0.01l11.585,6.829
          C533.007,466.651,533.121,466.749,533.222,466.844z M520.07,454.513l3.169,1.209l0.601,0.961c0,0,1.038,1.779,1.927,3.411
          c0.636,1.161,2.466,2.629,4.402,4.184c0.645,0.517,1.297,1.044,1.907,1.559l-10.696-6.305l0.093-0.275l-0.32,0.013l-2.824-8.235
          L520.07,454.513z M517.776,450.037l3.167,9.237l-0.355-0.21l-0.079,0.233l-0.087,0.004l-3.744-10.918L517.776,450.037z
           M495.259,427.401c0.367,0.615,0.822,1.38,0.741,2.284c-0.195,2.115,2.125,2.874,3.988,3.483l0.398,0.132
          c0.222,0.073,0.379,0.134,0.49,0.181c-0.057,0.158-0.108,0.359-0.108,0.599c0,1.067,0.749,1.558,1.542,2.078l0.283,0.187
          c0.908,0.604,3.377,0.383,4.802,0.189l0.916,1.526l-0.352,1.174l-0.031,2.749c0,1.045,1.081,1.538,2.045,1.646l4.452,0.269
          l1.164,2.844l0.65,0.98l3.973,11.585l-12.201,0.496l-24.192,0.982c-0.219-3.929-3.474-7.051-7.468-7.051
          c-1.13,0-2.198,0.257-3.158,0.704l-16.797-39.419l21.644,6.548l16.847,5.097C494.973,426.898,495.1,427.134,495.259,427.401z
           M494.434,425.795c0.184,0.227,0.296,0.434,0.359,0.632l-38.568-11.668l-0.208-0.488l0.022-0.091l24.709,7.475L494.434,425.795z
           M478.209,380.271c0.182,0.556,0.388,1.187,0.695,1.901c0.854,1.995,1.151,3.156,1.154,3.167c0.164,0.657,0.323,1.795,0.164,2.268
          c-0.358,1.08,0.088,2.779,0.14,2.97l0.217,0.792l1.472-0.551c0.513,0.622,0.941,1.278,0.967,1.5c0,0.289-0.431,0.442-0.742,0.442
          h-3.384l0.489,3.921l3.52-0.17l1.114,0.991l0.121,0.088c0.017,0.01,1.719,1.005,2.533,1.821c0.48,0.48,0.438,1.212,0.408,1.46
          c-0.211,0.388-0.915,1.685-1.294,2.446l-0.189,0.365c-0.458,0.876-1.084,2.075-1.084,3.55c0,1.53,0.58,5.462,0.604,5.629
          l0.08,0.539l1.912,0.32l0.875,2.186l-1.34,1.675l0.475,0.475c0.203,0.202,1.996,1.989,2.618,2.455
          c0.214,0.163,0.997,1.134,1.569,1.843c0.86,1.065,1.749,2.168,2.277,2.607c0.233,0.195,0.42,0.373,0.587,0.544l-23.887-7.226
          l-14.138-4.277l8.629-35.588l12.647-0.023C477.762,378.942,477.969,379.535,478.209,380.271z M477.294,378.191l-0.463,0.001
          l-12.063,0.022l0.027-0.149l0.085-0.351l11.995-0.022C477.034,377.856,477.175,378.022,477.294,378.191z M468.5,359.8
          c0.275-0.027,0.813,0.906,1.109,2.386c0.153,0.766,0.817,1.344,1.239,1.643l0.202,1.314c-0.413,0.619-0.946,1.528-1.003,2.184
          c-0.149,0.037-0.476,0.077-1.194,0.077c-0.628,0-1.103,0.199-1.41,0.591c-0.529,0.676-0.305,1.583-0.141,2.245
          c0.027,0.108,0.052,0.209,0.07,0.297c0.201,1.013,0.622,1.845,1.249,2.472c0.612,0.616,2.944,2.402,3.208,2.604l0.166,0.126
          l0.207,0.023c0.346,0.038,1.361,0.185,1.892,0.396c0.186,0.075,0.38,0.134,0.587,0.196c0.573,0.171,1.167,0.356,1.995,1.136
          l-11.245,0.02l-0.475,0.001l3.186-17.677C468.258,359.826,468.375,359.816,468.5,359.8z M467.876,359.85l-3.14,17.421l-0.487,0.243
          h-0.006l3.186-17.673C467.571,359.852,467.72,359.856,467.876,359.85z M444.962,345.751c0.669,1.002,1.532,1.705,2.714,2.21
          c0.305,0.13,0.366,0.372,0.312,1.231c-0.011,0.167-0.02,0.325-0.02,0.468c0,1.212,1.763,1.748,2.521,1.922l0.459,0.105l0.294-0.368
          c0.025-0.031,0.625-0.782,1.23-1.692c0.134-0.202,0.332-0.498,0.491-0.688c0.002,0.003,0.004,0.007,0.005,0.011
          c0.51,1.02,1.29,1.021,1.6,0.975c0.868-0.13,1.472-1.017,1.6-1.785c0.038-0.228,0.173-0.444,0.32-0.618
          c0.395,0.36,0.971,0.944,1.448,1.658c0.807,1.208,1.232,3.017,1.236,3.035l0.144,0.62l2.417-0.15
          c0.166,0.56,0.421,1.367,0.632,1.789c0.108,0.214,0.201,0.508,0.309,0.848c0.147,0.465,0.314,0.992,0.586,1.534
          c0.352,0.705,0.995,1.063,1.512,1.352c0.328,0.183,0.638,0.355,0.81,0.57c0.509,0.638,1.002,0.931,1.586,1.033l-3.217,17.851
          l-29.05,14.464l6.601-51.848C442.088,341.211,444.43,344.953,444.962,345.751z M444.852,331.939l1.198,1.199l-2.719,1.812
          l-1.19,0.331l0.609-4.787L444.852,331.939z M442.78,330.248l0.019-0.15l0.229-0.034l-0.004,0.046l0.98,0.981L442.78,330.248z
           M442.825,329.891l0.002-0.018l0.012,0.016L442.825,329.891z M445.475,287.286l-0.291,1.881l-1.346,1.178l0.134,0.459
          c0.011,0.036,1.041,3.573,1.185,4.152c0.14,0.551,0.83,1.67,1.234,2.295l-0.288,0.771c-0.167,0.271-0.902,1.443-1.496,2.037
          c-0.728,0.729-0.76,1.989-0.711,2.694l-2.574,1.716l1.651,1.861c0.538,1.842,1.758,5.8,2.531,6.574
          c0.312,0.312,0.318,0.433,0.076,1.438c-0.041,0.17-0.085,0.353-0.129,0.551c-0.229,1.028-0.855,1.538-2.028,1.654
          c-0.707,0.07-1.089,0.446-1.286,0.75c-0.491,0.758-0.313,1.817-0.178,2.344l-0.904,2.258l2.13,6.083l-0.125,1.633l-0.185-0.127
          l0.024-0.187l0.017-0.131l-0.317,0.048l-22.723-31.4c2.118-1.32,3.532-3.659,3.532-6.333c0-0.232-0.014-0.46-0.035-0.686
          l22.228-3.66L445.475,287.286z M445.805,286.902l-1.861,0.307l-20.592,3.39c-0.02-0.167-0.045-0.332-0.076-0.495l21.999-3.622
          l1.052-0.173C446.122,286.539,445.94,286.747,445.805,286.902z M456.554,282.847l-0.22-0.051l0.355-0.4L456.554,282.847z
           M456.96,281.493l-0.103,0.343l-0.796,0.897l-0.954-0.22l-1.344,0.402l-1.466-0.977l-2.31,1.54l0.439,0.875
          c-0.651-0.192-1.661-0.364-2.308,0.125c-0.359,0.269-1.017,0.97-1.578,1.592l-23.302,3.836c-0.32-1.486-1.085-2.805-2.147-3.818
          l30.358-30.917c1.373,1.564,3.384,2.557,5.632,2.557c4.129,0,7.476-3.333,7.488-7.448l23.637-3.78l-31.045,34.991H456.96z
           M457.49,281.493l31.097-35.052l0.711-0.114L458.1,281.493H457.49z M500.022,310.434c0,0.664-0.049,1.286-0.089,1.684
          c-0.202,0.052-0.478,0.1-0.801,0.1c-0.765,0-2.936,0.366-3.887,0.534c-0.398-0.476-1-1.241-1.26-1.76
          c-0.667-1.33-2.467-0.949-3.433-0.743l-0.219,0.045c-0.322,0.063-0.734,0.004-1.01-0.059c-0.25-0.387-0.753-1.1-1.358-1.583
          c-0.594-0.473-1.188-0.499-1.667-0.52c-0.326-0.014-0.583-0.025-0.849-0.158c-0.414-0.235-1.123-2.156-1.392-3.508
          c-0.213-1.049-0.879-1.794-2.032-2.801c-1.209-1.059-3.225-2.022-4.495-1.816c-0.306,0.052-0.305,0.051-0.743-0.504
          c-0.102-0.13-0.215-0.273-0.346-0.43c-0.542-0.65-0.571-1.269-0.43-2.41c0.027-0.218,0.004-0.467-0.054-0.734l24.973,11.704
          C500.17,308.464,500.022,309.242,500.022,310.434z M501.09,307.268c-0.013,0.015-0.022,0.03-0.035,0.045l-25.162-11.792
          c-0.061-0.214-0.142-0.436-0.238-0.664l25.737,12.062C501.29,307.035,501.186,307.152,501.09,307.268z M520.478,282.848
          c-1.062,0.192-1.553-0.588-1.601-0.67l-0.297-0.533l-0.582,0.182c-0.421,0.131-2.562,0.82-3.376,1.471
          c-0.7,0.56-1.281,1.723-1.816,2.866c-0.069,0.147-0.127,0.273-0.174,0.366c-0.038,0.076-0.089,0.169-0.148,0.279
          c-0.414,0.762-1.275,2.349-1.275,4.085c0,1.104-0.935,2.52-1.554,3.457c-0.251,0.38-0.468,0.709-0.604,0.979
          c-0.148,0.296-0.857,0.475-1.755,0.701c-0.579,0.146-1.235,0.312-1.989,0.563c-1.053,0.351-1.415,0.976-1.534,1.439
          c-0.199,0.777,0.167,1.556,0.498,2.062l-1.403,1.532l-0.148,3.861c-0.263,0.268-0.734,0.757-1.191,1.271l-17.256-8.087
          l-8.737-4.095c-0.706-1.521-1.977-3.172-2.422-3.617c-0.449-0.45-0.646-1.463-0.679-1.783l-0.063-0.609l-3.107-0.312l-0.031-0.093
          l1.385-2.16l-1.365-2.535l-0.212-0.106c-0.015-0.007-1.453-0.729-2.434-1.431c-1.27-0.905-2.559,0.08-3.329,0.668
          c-0.174,0.133-0.334,0.257-0.476,0.343c-0.552,0.331-1.082,0.567-1.831,0.623c0.006-0.011,0.012-0.021,0.019-0.031l0.288-0.461
          l-1.319-1.581h-1.531l31.078-35.029l30.891,22.568l0.671,13.667C520.876,282.759,520.68,282.811,520.478,282.848z M521.265,282.632
          l-0.651-13.246l0.167-0.073l0.343,0.251l0.633,12.875C521.601,282.507,521.437,282.572,521.265,282.632z M557.936,281.65
          c-0.26-0.04-0.362-0.088-0.38-0.088c-0.001,0-0.001,0-0.002,0.001c-0.012-0.037-0.024-0.13-0.024-0.364
          c0-0.312,0.358-0.79,0.89-1.19c0.052-0.039,0.101-0.079,0.146-0.121c-0.149,0.483,0.123,0.885,0.294,1.136
          c0.116,0.171,0.259,0.382,0.404,0.699C559.062,281.752,558.668,281.765,557.936,281.65z M560.15,280.256
          c0.137-0.089,0.258-0.127,0.271-0.14c0,0.001,0.083,0.112,0.083,0.487c0,0.084,0.001,0.167,0.002,0.248
          C560.375,280.598,560.25,280.404,560.15,280.256z M562.823,280.387c-0.487,0.454-0.498,1.221-0.453,1.836
          c0.012,0.163-0.012,0.246-0.002,0.246c0,0,0,0,0,0c-0.013,0.007-0.051,0.016-0.107,0.017c-0.1-0.203-0.257-0.763-0.257-1.882
          c0-1.163-0.507-1.638-0.933-1.831c-0.734-0.335-1.552,0.03-2.028,0.445c-0.021,0.019-0.041,0.038-0.061,0.056
          c0.049-0.14,0.076-0.287,0.078-0.438c0.009-0.494-0.251-1-0.692-1.352c-0.109-0.088-0.204-0.146-0.352-0.232
          c-0.021-0.023-0.134-0.175-0.37-0.966c-0.226-0.749-0.756-1.169-1.471-1.114c-1.121,0.084-2.306,1.463-2.452,2.492
          c-0.025,0.175-0.042,0.358-0.059,0.537c-0.003,0.027-0.006,0.056-0.009,0.086c0-0.001-0.001-0.002-0.002-0.003
          c-0.204-0.202-0.746-0.739-1.369-0.514c-0.608,0.218-0.672,0.875-0.718,1.355c-0.011,0.113-0.028,0.291-0.053,0.422
          c-0.378-0.012-0.649,0.114-0.848,0.353c-0.358,0.431-0.193,0.944-0.084,1.284c0.005,0.014,0.01,0.03,0.015,0.047
          c-0.263,0.051-0.654,0.181-0.872,0.558c-0.279,0.481-0.072,0.958,0.065,1.273c0.021,0.051,0.051,0.117,0.072,0.174
          c-0.344,0.264-0.534,0.286-1.596-0.304c-0.218-0.121-0.883-0.486-1.433-0.088c-0.578,0.418-0.435,1.166-0.283,1.957
          c0.073,0.384,0.156,0.819,0.156,1.173c0,0.716-0.343,1.021-0.452,1.1c-0.862,0.254-1.847,0.392-2.101,0.293
          c-0.163-0.065-0.396-0.151-0.672-0.254c-0.939-0.347-2.511-0.929-3.385-1.475c-1.95-1.22-3.902-1.249-5.224-0.076
          c-0.827,0.738-2.657,0.357-3.29,0.148c-1.804-0.602-3.953-1.488-4.274-1.846c-0.661-0.825-2.92-2.046-3.365-2.283l-0.429-0.226
          l-0.382,0.295c-0.008,0.006-0.481,0.367-1.181,0.701l-0.621-12.635l0.185,0.135l-0.165-0.79l40.106-17.596l1.437,23.425
          l0.322,5.256C563.049,280.209,562.918,280.299,562.823,280.387z M563.896,280.084c-0.185-0.026-0.339-0.012-0.48,0.015
          l-1.493-24.34l-0.269-4.38l0.278-0.122l0.219,0.034l1.767,28.798C563.911,280.089,563.903,280.086,563.896,280.084z
           M590.794,288.352l-0.233,0.001c-0.354,0-0.437-0.047-0.438-0.048c-0.043-0.039-0.137-0.237-0.204-0.382
          c-0.092-0.195-0.198-0.421-0.348-0.684c-0.125-0.217-0.481-0.874-1.173-0.813c-0.672,0.065-0.9,0.737-1.084,1.278
          c-0.042,0.125-0.086,0.255-0.134,0.374c-0.198,0.495-0.61,1.845-1.257,4.127l-0.004,0.01c-0.254,0.152-1.038,0.315-1.547,0.315
          c-0.174,0-0.482-0.029-0.859-0.062c-0.934-0.082-2.215-0.193-3.115-0.082c-0.429,0.055-2.153-0.912-3.612-2.079l-0.153-0.095
          c-0.245-0.113-0.958-0.475-1.317-0.782c-0.609-0.524-1.618-1.258-3.333-1.586c-1.213-0.23-1.447-0.506-1.802-0.924l-0.242-0.289
          c-0.42-0.512-0.942-1.149-2.001-1.325c-0.329-0.055-0.422-0.262-0.73-1.661c-0.292-1.304-0.9-1.97-1.264-2.368
          c-0.059-0.064-0.111-0.122-0.152-0.173c-0.363-0.456-1.014-0.82-1.669-0.967l-0.752-12.244l-1.017-16.572l39.186,6.032
          l-7.466,21.657l-3.221,9.34C590.833,288.352,590.812,288.352,590.794,288.352z M591.064,288.352l10.675-30.963l0.354,0.051
          l0.152,0.023l-10.654,30.899C591.409,288.354,591.234,288.352,591.064,288.352z M602.565,293.788
          c-0.567,0.438-3.735-0.064-5.609-0.537l6.932-0.995C603.717,292.625,603.35,293.005,602.565,293.788z M603.994,291.94
          c-0.007,0.035-0.018,0.068-0.027,0.102l-7.501,1.077c-0.305-0.088-0.557-0.172-0.712-0.244c-0.079-0.039-0.16-0.081-0.24-0.125
          l8.662-1.244C604.092,291.643,604.025,291.786,603.994,291.94z M605.956,290.644c-0.595-0.083-1.227,0.194-1.615,0.637
          l-9.084,1.304c-0.365-0.271-0.664-0.712-0.664-1.691c0-0.715-0.221-1.296-0.656-1.729c-0.573-0.568-1.378-0.739-2.139-0.79
          l10.648-30.882l0.048,0.008l0.001-0.004l14.734,2.105l16.225,2.318c0,0.003,0,0.007,0,0.01c0,2.788,1.534,5.215,3.804,6.498
          l-9.269,19.439l-0.438,0.032l0.025,0.045l-20.914,3.003C606.447,290.779,606.202,290.678,605.956,290.644z M627.92,288.575
          l-0.05,0.004l0.024-0.05L627.92,288.575z M627.677,288.132l0.099,0.18l-0.159,0.333l-20.496,2.943
          c-0.073-0.184-0.168-0.338-0.28-0.465L627.677,288.132z M632.854,298.42c-0.479-0.479-1.004-0.77-1.512-1.051
          c-0.759-0.42-1.543-0.854-2.456-2.017c-0.62-0.789-1.459-1.186-2.46-1.147c-1.776,0.069-3.49,1.517-3.974,2.24
          c-0.236,0.355-0.812,0.755-1.367,0.81c-0.396,0.039-0.557-0.125-0.61-0.178c-1.204-1.203-2.479-0.544-3.322-0.109l-0.256,0.13
          c-0.027-0.219-0.055-0.515-0.081-0.888c-0.103-1.419-0.764-1.606-1.198-1.593c-1.017,0.037-1.791,1.715-1.791,2.542
          c0,0.184-0.064,0.424-0.246,0.546c-0.201,0.136-0.543,0.12-0.963-0.049l-0.517-0.213c-0.86-0.362-1.099-0.386-1.899-0.17
          c0.286-0.498,0.603-1.085,0.772-1.707c0.276-1.016-0.077-1.467-0.423-1.667c-0.96-0.558-2.362,0.68-2.92,1.238
          c-0.136,0.136-0.275,0.275-0.377,0.369c-0.017-0.009-0.033-0.019-0.052-0.029c-0.098-0.052-0.211-0.114-0.36-0.154
          c-0.025-0.11,0.061-0.485,0.129-0.787c0.107-0.471,0.241-1.058,0.298-1.796c0.029-0.377-0.006-0.692-0.081-0.959l20.849-2.993
          l5.744,10.476C633.503,299.036,633.193,298.758,632.854,298.42z M634.18,299.573l-5.93-10.815l0.092-0.013l0.454-0.033
          l6.234,11.368C634.83,299.997,634.544,299.837,634.18,299.573z M666.092,297.483c0.057-0.043,0.125-0.092,0.206-0.146
          c1.308-0.871,0.922-1.748,0.282-3.2l-0.307-0.703c-0.467-1.089-1.375-1.677-2.436-1.565c-0.971,0.1-1.892,0.777-2.337,1.648
          c-0.081-0.207-0.173-0.46-0.276-0.771c-0.283-0.847-0.661-1.364-1.191-1.627c-0.746-0.37-1.516-0.109-2.329,0.167l-0.264,0.088
          c-1.636,0.548-2.056,2.282-2.362,3.548l-0.064,0.262c-0.161,0.641-0.543,1.218-2.193,1.077c-0.83-0.068-1.299,0.255-1.543,0.54
          c-0.712,0.831-0.373,2.237-0.101,3.367l0.103,0.435c0.145,0.65-0.079,1.043-0.795,1.403c-0.722,0.359-2.434,1.633-3.477,2.676
          c-0.777,0.775-3.126,0.939-3.949,0.587c-0.828-0.356-1.777-0.951-3.108-2.87c-1.522-2.2-3.634-2.451-4.636-2.249
          c-0.004,0.001-0.014-0.002-0.018-0.001l-6.28-11.453l13.129-0.951l24.259-1.757l1.969,13.071
          C667.935,298.123,667.209,297.765,666.092,297.483z M668.914,300.019c-0.045-0.021-0.097-0.058-0.151-0.118l-2.098-13.933
          l0.079-0.006l0.399,0.216l2.093,13.895C669.086,300.069,668.975,300.046,668.914,300.019z M678.536,295.063
          c-0.373,1.064,0.28,2.042,0.594,2.511c0.168,0.251-0.418,0.965-0.889,1.538c-0.194,0.236-0.393,0.479-0.58,0.728
          c-0.223,0.298-0.873,0.545-1.654,0.63c-1.005,0.111-1.788-0.08-1.932-0.2c-0.977-1.219-2.141-0.947-3.805-0.392
          c-0.332,0.111-0.574,0.157-0.774,0.179l-2.068-13.724l13.002,7.016C679.408,293.801,678.78,294.365,678.536,295.063z
           M680.666,293.249l-13.34-7.199l-0.055-0.365l0.205-0.124l0.401,0.217l13.42,7.242
          C681.072,293.093,680.864,293.169,680.666,293.249z M686.758,296.304c-0.17-0.105-0.4-0.315-0.56-0.638l1.54,0.831
          C687.56,296.566,687.164,296.555,686.758,296.304z M690.307,297.937c-0.006-0.024-0.015-0.043-0.021-0.066l0.189,0.102
          C690.401,297.962,690.343,297.949,690.307,297.937z M714.251,298.627c-0.809,0.411-0.733,1.671-0.558,2.577
          c-0.232-0.363-0.536-0.781-0.976-1.365c-0.372-0.497-0.84-1.007-1.479-1.022c-0.699-0.033-1.102,0.501-1.556,1.049l-0.079,0.095
          c-0.135,0.161-0.544,0.653-0.433,1.26c0.053,0.286,0.2,0.498,0.387,0.676c-1.405,0.286-2.67,0.954-3.668,1.905
          c-0.773-0.385-1.567-0.884-1.892-1.208c-0.511-0.511-1.824-2.237-2.865-4.022c-1.24-2.125-5.549-1.313-7.863-0.878l-0.376,0.07
          c-0.949,0.172-1.559,0.227-1.956,0.232l-0.742-0.4c-0.196-0.552-0.513-0.966-0.932-1.171c-0.277-0.136-0.719-0.222-1.272-0.018
          l-1.906-1.028c-0.036-0.138-0.065-0.284-0.065-0.457c0-0.811-0.057-1.717-0.735-2.213c-0.738-0.54-1.85-0.353-3.722,0.23
          l-13.81-7.452l27.804-16.867l0.471,0.049l0.192,0.02l-0.19-0.337l37.386-20.706c0.572,1.688,1.732,3.101,3.237,4.006
          l-13.912,31.668l-6.933,15.78C715.414,298.69,714.855,298.32,714.251,298.627z M716.309,299.706
          c-0.076-0.106-0.197-0.272-0.348-0.446l13.131-29.89l7.739-17.615c0.146,0.082,0.296,0.156,0.448,0.229l-20.967,47.724
          C716.31,299.707,716.309,299.707,716.309,299.706z M720.793,303.687c-0.004,0-0.009,0-0.014,0c-0.302,0-1.016-0.242-1.505-0.408
          l-0.312-0.105c0.064-0.054,0.13-0.107,0.189-0.156c0.372-0.306,0.836-0.686,1.281-1.18c0.12-0.134,0.222-0.273,0.306-0.416
          c-0.059,0.486-0.054,1.04,0.035,1.66C720.821,303.419,720.809,303.601,720.793,303.687z M767.566,319.987
          c-0.018-0.001-0.035,0.001-0.053,0c-0.164-0.005-0.34-0.003-0.522,0.012l1.306-2.458c-0.03,0.536-0.036,0.77,0.001,0.942
          c0.005,0.026,0.019,0.05,0.027,0.076L767.566,319.987z M767.862,319.933c-0.005,0.019-0.01,0.036-0.014,0.054
          c-0.017,0.001-0.039-0.001-0.057,0l0.077-0.145L767.862,319.933z M768.533,318.905c-0.109,0.07-0.191,0.133-0.27,0.195l0.17-0.321
          C768.464,318.822,768.49,318.868,768.533,318.905z M763.676,300.999c0.071,0.032,0.205,0.091,0.216,0.091
          c0.132,0.17,0.225,0.44,0.322,0.726c0.032,0.092,0.063,0.183,0.097,0.273c-0.063,0.185-0.224,0.529-0.335,0.77
          c-0.465,1-0.739,1.641-0.538,2.183l0.079,0.215l0.187,0.134c0.428,0.308,0.614,0.506,0.693,0.61
          c-0.07,0.061-0.17,0.134-0.244,0.188c-0.352,0.259-0.833,0.614-0.929,1.233c-0.076,0.501,0.036,0.879,0.346,1.134
          c0.003,0.068,0.015,0.139,0.039,0.212c-0.139,0.29-0.252,0.718-0.003,1.211c0.108,0.219,0.226,0.677,0.325,1.081
          c-0.038,0.041-0.077,0.081-0.111,0.116l-0.126,0.136c-0.304,0.346-0.469,0.619-0.589,0.818c-0.187,0.309-0.256,0.424-0.98,0.742
          c-1.087,0.401-1.786,0.776-1.911,1.405c-0.064,0.324,0.043,0.643,0.296,0.877c0.491,0.453,1.093,0.786,1.624,1.08
          c0.433,0.24,0.88,0.488,1.069,0.705l0.115,0.133l0.162,0.067c0.312,0.129,0.639,0.094,0.875,0.066
          c0.062-0.007,0.122-0.011,0.165-0.016c0.04,0.002,0.146,0.039,0.224,0.065c0.253,0.086,0.634,0.218,1.071,0.097
          c0.515-0.138,0.727-0.54,0.841-0.756c0.008-0.015,0.016-0.03,0.022-0.044c0.067-0.019,0.146-0.033,0.223-0.05
          c0.212-0.047,0.432-0.096,0.626-0.191c0.249-0.122,0.435-0.233,0.581-0.321c0.073,0.069,0.161,0.156,0.247,0.244
          c0.002,0.203-0.014,0.539-0.031,0.829l-1.581,2.976c-0.056,0.011-0.108,0.011-0.165,0.027c-0.346,0.103-0.54,0.341-0.71,0.546
          c-0.154,0.165-0.502,0.367-0.809,0.545c-0.27,0.157-0.533,0.313-0.743,0.47c-1.671,1.253-1.804,2.471-1.892,3.276
          c-0.072,0.668-0.08,0.738-0.797,0.89c-0.491,0.104-1.202-0.458-1.899-1c-1.489-1.153-3.739-2.898-6.387-0.012l-0.394,0.429
          l0.318,0.488c0.041,0.063,0.032,0.244,0.024,0.403c-0.015,0.281-0.031,0.63,0.146,0.968c0.256,0.495,0.538,0.973,0.82,1.449
          c0.024,0.041,0.047,0.081,0.071,0.122c-0.8,0.792-1.415,1.757-1.792,2.842c-3.463,0.157-7.696,0.256-10.154-0.317
          c-0.538-0.126-1.177-0.402-1.795-0.667c-0.251-0.108-0.496-0.214-0.751-0.315c-0.03-0.037-0.066-0.083-0.11-0.138
          c-0.558-0.645-1.135-1.212-1.675-1.735c-0.107-0.105-0.24-0.2-0.374-0.29l-0.742-0.536c-0.889-0.643-1.809-1.307-2.566-2.026
          c-0.074-0.069-0.177-0.239-0.267-0.388c-0.117-0.192-0.235-0.381-0.367-0.537c-0.256-0.298-0.604-0.561-0.912-0.793
          c-0.061-0.045-0.119-0.09-0.177-0.134l-0.078-0.343c-0.18-0.767-0.299-1.273-0.244-1.774l0.01-0.083l-0.01-0.083
          c-0.028-0.253,0.022-0.426,0.149-0.512c0.287-0.195,1.054-0.139,2.114,0.427c1.11,0.591,1.71,0.311,2.016-0.029
          c1.039-1.148-0.724-4.005-0.741-4.03c-0.398-0.602-0.716-1.527-0.298-2.153c0.61-0.917,1.076-2.012-0.094-3.183
          c-1.119-1.121-3.013-0.481-4.719,0.293c-0.38,0.173-0.573,0.156-0.603,0.145c-0.051-0.068-0.064-0.422,0.206-0.854
          c0.983-1.572-0.189-2.693-0.89-3.363l-0.258-0.25c-0.292-0.292-0.624-0.621-0.996-0.907l34.79-6.801
          c0.003,0.129,0.025,0.27,0.102,0.426C763.239,300.804,763.471,300.907,763.676,300.999z M763.008,299.392
          c0.036,0.096,0.069,0.189,0.086,0.244c-0.029,0.062-0.06,0.156-0.08,0.265l-35.029,6.847c-0.216-0.148-0.441-0.281-0.683-0.376
          l4.531-0.886L763.008,299.392z M776.417,286.84c-0.062-0.008-0.158-0.019-0.175-0.023c-0.579-0.307-1.308-0.876-1.803-1.275
          c-0.121-0.098-0.257-0.194-0.397-0.292c-0.127-0.088-0.258-0.178-0.377-0.278l-0.407-0.346l-0.216,0.128l-0.341-0.166l-0.341,0.187
          c-0.009,0.005-0.021,0.011-0.034,0.019c-0.11-0.089-0.261-0.167-0.469-0.181c-0.475-0.02-0.746,0.29-0.899,0.466
          c-0.441,0.294-0.824,1.835-0.827,1.851l-0.12,0.604l0.569,0.235c0.881,0.363,1.443,0.604,2.123,1.051
          c-0.765,1.86-2.595,3.695-4.089,5.194l-0.319,0.321c-0.476-0.118-1.208-0.299-1.519-0.383c-1.023-0.888-2.329-1.331-3.364-1.618
          l-0.197-0.055l-0.197,0.053c-0.658,0.176-0.637,0.879-0.627,1.217c0.002,0.047,0.004,0.094,0.004,0.136
          c-0.04,0.051-0.109,0.125-0.162,0.184c-0.298,0.323-0.748,0.812-0.796,1.6c-0.011,0.226,0.069,0.403,0.153,0.55
          c-0.003,0.023-0.005,0.047-0.008,0.072c-0.072,0.686-0.192,1.834,0.71,2.162c0.064,0.024,0.127,0.042,0.188,0.053
          c0.069,0.159,0.157,0.284,0.229,0.387c0.029,0.042,0.062,0.082,0.082,0.124c0.026,0.079,0.064,0.179,0.107,0.29
          c0.01,0.027,0.023,0.061,0.036,0.095l-32.708,6.394l-3.348,0.654c-0.348-0.072-0.718-0.074-1.117,0.041
          c-0.16-0.073-0.432-0.656-0.577-0.97c-0.154-0.331-0.312-0.673-0.512-0.972c-0.345-0.518-0.501-0.754,0.533-1.918
          c0.601-0.675,0.715-1.479,0.315-2.204c-0.546-0.989-1.928-1.565-2.79-1.423c-0.688,0.116-1.351,0.629-1.724,1.539
          c-0.017-0.227-0.079-0.449-0.19-0.66c-0.362-0.687-1.177-1.11-1.93-1.011c-0.634,0.085-1.097,0.518-1.237,1.156
          c-0.131,0.586-0.333,0.787-0.367,0.803c-0.003,0-0.291-0.037-0.836-0.741l21.009-47.82c0.933,0.416,1.963,0.652,3.051,0.652
          c3.216,0,5.951-2.026,7.012-4.865l5.476,3.657c-0.008,0.143-0.007,0.292,0.013,0.455c0.089,0.749,0.443,1.339,0.768,1.832
          c0.167,0.254,0.383,0.428,0.693,0.677l0.132,0.085c0.65,0.328,1.15,0.616,1.578,0.907c0.49,0.331,0.802,0.827,0.878,1.395
          c0.036,0.274,0.023,0.317-0.202,0.656c-0.354,0.531-0.852,0.542-1.778,0.516c-1.141-0.03-2.844-0.081-3.672,2.16
          c-0.309,0.834-0.151,1.808,0.409,2.541c0.479,0.628,1.089,1.021,1.68,1.401c0.337,0.217,0.655,0.422,0.929,0.66
          c0.225,0.195,0.436,0.422,0.649,0.649c0.453,0.481,0.922,0.979,1.546,1.31c0.796,0.417,1.789,0.398,2.851,0.378
          c1.19-0.025,2.425-0.047,3.36,0.55c0.511,0.328,0.669,1.259,0.837,2.246c0.192,1.137,0.411,2.425,1.268,3.335
          c1.165,1.24,4.026,2.451,6.325,3.424c0.65,0.275,1.236,0.523,1.688,0.733c0.351,0.163,0.78,0.396,1.238,0.656l2.494,8.801
          C776.588,286.864,776.497,286.85,776.417,286.84z M777.069,287.016c-0.054-0.029-0.106-0.039-0.16-0.059l-2.475-8.732
          c0.206,0.119,0.413,0.238,0.624,0.362l0.881,3.109l1.636,5.774C777.442,287.305,777.289,287.135,777.069,287.016z M797.192,287.784
          c-0.199,0.188-0.315,0.301-0.4,0.429l-0.099,0.148l-0.021,0.177c-0.01,0.082-0.015,0.242-0.017,0.428
          c-0.001,0.05-0.002,0.119-0.004,0.191c-0.5,0.025-1.198-0.035-1.879-0.092c-1.059-0.09-2.154-0.183-3.074-0.045
          c-0.159,0.023-0.307,0.055-0.45,0.086c-0.225,0.048-0.383,0.075-0.525,0.077c-0.089-0.095-0.203-0.225-0.328-0.364
          c-0.926-1.028-1.346-1.501-1.965-1.246l-0.176,0.102c-0.133,0.104-0.254,0.246-0.377,0.401c-0.048-0.09-0.103-0.195-0.165-0.317
          l-0.083-0.161c-0.132-0.232-0.319-0.499-0.519-0.779c-0.281-0.397-0.667-0.94-0.72-1.19c-0.001-0.05,0.008-0.111,0.015-0.176
          c0.039-0.362,0.129-1.208-0.635-1.49c-0.405-0.15-0.929-0.07-1.823,0.085c-0.321,0.056-0.81,0.141-1.106,0.158
          c-0.044-0.094-0.108-0.196-0.211-0.286l-0.228-0.136c-0.495-0.188-0.823-0.058-1.011,0.086c-0.405,0.311-0.357,0.794-0.33,1.077
          c0.034,0.37,0.147,0.654,0.279,0.875c-0.058,0.042-0.112,0.095-0.164,0.16c-0.353,0.451-0.167,0.756,0.523,1.892l0.17,0.282
          c0.132,0.227,0.242,0.425,0.331,0.594c-0.146,0.028-0.271,0.032-0.37,0.027c-0.009-0.011-0.018-0.022-0.026-0.033
          c-0.091-0.113-0.228-0.284-0.449-0.41c-0.379-0.217-0.793-0.123-1.093-0.057c-0.005,0.001-0.01,0.002-0.014,0.003
          c-0.697-0.403-1.53-0.588-2.387-0.535l-2.554-9.012c0.025,0.015,0.049,0.029,0.074,0.043c1.154,0.679,2.349,1.381,3.188,1.701
          c1.898,0.729,3.824,0.993,5.687,1.247c0.416,0.057,0.834,0.114,1.256,0.177c2.512,0.368,4.95,0.359,6.793,0.307
          c0.354-0.01,0.726-0.036,1.103-0.062c0.517-0.036,1.062-0.074,1.531-0.066c0.106,0.255,0.215,0.51,0.324,0.764
          c0.262,0.615,0.524,1.229,0.752,1.853c0.095,0.259,0.247,0.463,0.37,0.626c0.066,0.089,0.149,0.199,0.167,0.253
          c0.128,0.391,0.243,0.785,0.358,1.178c0.099,0.337,0.198,0.674,0.304,1.01C797.208,287.77,797.2,287.777,797.192,287.784z"
        />
        <path
          class="st32"
          d="M326.291,382.443l0.666-1.375l1.478,0.291l0.571-1.428l-0.474-1.513l-0.835,0.065l-1.295-0.983l-0.838,1.366
          l0.031,0.051l-0.34-0.388l0.066-0.744l-1.449-0.937l-0.446-0.801l-1.424-0.292l-0.426-0.5l-0.155-0.007l0.179-0.695l-0.987-0.156
          l-0.113-0.223l-0.669-0.204l-0.266-0.365l-0.012-0.668l-0.862-0.609l-0.197-0.554l-0.212-0.428l-0.774-0.127l-0.33-0.604
          l-0.572-0.434l0-0.002l0.325-0.657l-1.093-0.785l-0.744-2.265l-0.442,0.335l-0.186-0.404l-0.223-1.567l-0.632-1.445l-0.503-0.321
          l-0.174-0.704l0.061-0.232l0.333-0.212l0.323-1.545l-0.309-0.448l0.349-0.462l-0.03-1.481l-0.512-0.722l-0.033-0.361l0.133-0.424
          l0.11,0.551l1.685,1.153l0.858,1.607l0.448,0.522l0.149,1.104l0.61,0.529l-0.033,0.08l0.774,1.469l1.051,0.637l0.271-0.251
          l1.771,2.019l1.407,0.433l0.512-0.684l1.255-0.302l-0.102-0.142l0.324-0.105l0.382-0.824l0.124,0.02l0.809-0.469l0.25-0.66
          l1.031-0.379l0.892-1.306l-1.183-0.778l-0.325-0.856l-0.934-0.527l-0.822,0.486l-0.332-0.185l0.027-0.063l-0.135-1.068l0.285-0.508
          l-0.687-1.861l-0.707-0.591l-0.353,0.08l0.062-0.462l-1.277-1.501l-1.572-3.024l-0.277-1.198l0.158-0.664l0.24-0.477l0.641-0.147
          l0.324-1.042l-0.331-1.886l-0.995-2.473l-0.989-1.484l1.064,0.442l0.368-1.743l-0.622-4.546l0.079-1.181l-1.171-3.535l-0.239-1.802
          l0.087-0.951l0.38-1.992l0.982-2.211l-0.469-1.15l0.387-0.418v-1.659l0.289-0.595l0.516-0.042l0.773-1.142l0.519-0.235l0.108-1.155
          l-0.407-1.453l1.335,1.233l0.646,0.193l0.643,0.865l0.948,0.173l0.819,0.728l1.329,0.167l0.479-0.468l1.368-0.083l-0.735-1.895
          l-0.991-1.437l-0.608-0.542l-0.225-0.824l-0.74-0.282l-1.104-1.208l-0.94,1.644l-0.184,0.065l-0.415-0.069l-1.604-1.102
          l-0.445,2.162l-0.828-1.277l-0.838-0.315l-1.184,0.373l-0.706-0.159l-0.525,0.48l-3.323-4.993l-1.504-4.682l-0.655-1.229
          l-0.865-2.799l-0.589-2.715l-0.081-1.052l0.467-0.841l-0.102-1.573l0.296-1.042l0.689-1.053l-1.48-2.474l-3.186-1.691l-1.253,0.037
          l-0.828,0.462l-1.043,0.998l-1.795,4.296l-1.45,1.641l0.445,1.205l0.021,1.268l0.601,1.326l1.122,0.337l0.022,0.293l0.652,1.939
          l1.203,1.758l1.019,0.665l1.459,0.205l0.995,0.973l0.654,2.315l-0.661,3.25l0.511,1.631l0.212,0.263l-0.001,0.001l0.097,0.189
          l1.873,1.137l0.327,1.45l0.638,0.563l0.184,1.101l0.041,0.174l-0.211,0.139l-0.729,1.538l0.167,1.199l0.589,0.211l1.061,2.77
          l-0.121,0.33l-0.812,0.78l-1.807-0.91l-0.288,0.297l-0.122-0.294l-0.972-0.552l-0.817-1.514l-1.505-1.686l-1.213-2.094
          l-1.142,1.478l-0.877,0.484l-0.224,0.886l0.298,2.033l-0.059,0.488l-0.667,0.983l0.848,1.782l0.69-0.397l0.1,0.283l0.106,1.522
          l0.665,0.975l-0.54,0.915l1.243,1.347l0.224,0.491l0.105,0.641l-0.402,1.492l0.323,0.308l0.249,2.562l0.102,0.016l-0.168,0.208
          l0.354,0.304l-0.432,0.662l0.941,0.806l1.516,0.34l0.418-0.567l0.522,1.181l0.056,0.454l-0.402,1.083l0.696,2.517l0.153,1.307
          l0.691,1.817l1.072,1.325l0.375-0.475l0.064,0.081l-0.419,1.259l0.229,1.792l0.363,0.584l-0.725,0.917l0.077,0.545l-0.621-0.279
          l-0.505,0.269l-0.3-0.61l-0.821,0.263l-1.408-2.406l-1.36,2.334l0.208,1.003l-0.307,0.558l0.246,3.008l1.077,2.685l-0.185,0.662
          l0.448,0.92l0.531,0.129l1.214,1.683l0.673,0.389l0.186,0.288l0.641,2.411l-0.683,0.502l-1.107,1.823l0.343,1.366l-0.522,0.843
          L308.96,371l0.499,0.8l0.825,0.566l0.543,0.108l0.255,0.646l1.36,0.037l0.4-1.024l0.658,0.062l0.281-0.784l0.811,0.484l0.322-0.24
          l0.058,0.074l-0.691,0.571l0.528,0.771l-0.166,0.526l0.498,1.94l0.748-0.802l0.363,1.655l0.37,0.047l0.757,1.097l1.104,0.593
          l0.95,1.006l0.909,0.229l0.18,0.808l0.362,0.317l-0.098,0.584l0.948,0.649l0.32,0.674l0.645,0.06l0.488,1.008l-0.255,0.26
          l1.425,0.237l0.177-0.685l0.479,0.47L326.291,382.443z M323.463,315.749l1.073-0.379l0.211-0.368l0.303,0.115l0.148,0.54
          l0.764,0.642l0.78,1.1l-0.265,0.26l-0.145-0.018l-0.789-0.7l-0.778-0.142l-0.587-0.791l-0.816-0.244l-0.046-0.04L323.463,315.749z
           M318.834,362.144l-0.104-0.197l0.024-0.058l0.279,0.069L318.834,362.144z M321.455,364.207l-1.462-1.668l-0.869-0.558l2.356,0.586
          l-0.269,1.3L321.455,364.207z M323.198,363.13l-0.144-0.147l-0.091-0.047l0.282,0.07L323.198,363.13z M324.469,362.71l-1.046-0.168
          l-0.105,0.275l-1.122-0.279l-0.641-0.332l-0.034,0.164l-2.688-0.668l0.195-0.466l5.483,1.363L324.469,362.71z M315.581,315.293
          l0.322,0.221l-3.09,0.983l-0.083-0.499l2.743-0.873L315.581,315.293z M311.628,313.395l-2.079-1.262l-0.389-1.086l0.656-3.229
          l-0.861-3.051l-1.633-1.597l-1.616-0.227l-0.498-0.325l-0.882-1.253l-0.545-1.557l-0.092-1.222l-1.455-0.438l-0.197-0.434
          l-0.012-1.066l-0.226-0.737l0.938-1.062l1.858-4.343l0.596-0.592l0.417-0.233l0.489-0.015l2.466,1.31l0.784,1.311l-0.235,0.355
          l-0.496,1.613l0.09,1.392l-0.472,0.849l0.12,1.553l0.63,2.928l0.903,2.934l0.66,1.242l1.513,4.708l3.298,5.091l-2.663,0.847
          l-0.035-0.211l-0.673-0.595L311.628,313.395z M311.291,345.171l-0.401-1.014l-0.148-1.266l-0.604-2.218l0.361-0.972l-0.116-0.928
          l-0.917-2.151l-1.399-0.851l-0.32,0.447l-0.079-0.067l0.161-0.2l-0.36-1.073l-0.419-0.064l-0.164-1.92l-0.122-0.116l0.255-0.944
          l-0.174-1.06l-0.373-0.88l-0.7-0.813l0.457-0.775l-0.973-1.426l-0.085-1.219l-0.262-0.824l-0.762-1.066l0.136-1.062l-0.28-1.908
          l0.521-0.288l0.145,0.25l1.486,1.658l0.979,1.812l0.899,0.51l0.388,0.938l1.129,0.251l0.393-0.406l1.764,0.896l1.833-1.76
          l0.433-1.184l-1.508-3.938l-0.308-0.11l0.301-0.634l0.115-0.075l0.385,0.37l0.273,0.646l1.263,0.807l0.873-0.775l0.172-0.885
          l-0.28-0.804l-1.164-1.118l-1.197,0.037l-0.05-0.302l2.022-0.643l1.244-0.396l0.804,0.552l0.827-0.755l0.337,0.076l1.007-0.317
          l0.809,1.249l0.281,1.005l-0.112,0.062l-0.607,0.896l-1.267,0.09l0.198,0.82l-0.398,0.819v1.417l-0.37,0.4l-0.042,0.838
          l0.364,0.893l-0.759,1.707l-0.458,2.312l-0.113,1.191l0.27,2.04l1.133,3.462l-0.071,1.032l0.563,4.121l-1.278-0.531l-0.474,1.339
          l0.227,1.1l1.29,1.897l0.889,2.171l0.234,1.334l-0.417,0.096l-0.597,1.153l-0.328,1.295l0.376,1.628l1.72,3.36l0.993,1.197
          l-0.078,0.576l0.705,1.162l0.738-0.167l0.248,0.671l-0.188,0.335l0.146,1.158l-0.328,0.774l0.364,1.438l0.67-0.318l0.084,0.472
          l1.038,0.517l0.14-0.083l-0.102,0.27l-5.477-1.362l0.055-0.131l-1.178-0.203l-0.074-0.064l-0.136-1.011l-0.593-0.645l-0.986-1.846
          l-1.484-1.015l-0.139-0.696l-0.477-0.482l-0.585-1.303l-0.641-0.5l-0.126-0.25l-0.101-0.712l0.518-0.604l0.215-1.064l-0.505-0.81
          l-0.144-1.127l0.57-1.711l-0.982-1.219L311.291,345.171z M310.65,361.507c0.39-0.408,0.75-0.845,1.04-1.333l0.36,0.522
          l-0.062,0.297l-0.297,0.189l-0.28,1.072l-0.32-0.493L310.65,361.507z M314.637,369.989l-0.187-0.356l-1.345-0.672l-0.966,1.148
          l0.14,0.464l-0.423-0.041l-0.224,0.573l-0.755-0.149l-0.312-0.214l-0.141-0.226l-0.002-0.033l0.596-0.962l-0.345-1.373l0.606-0.998
          l0.687-0.451l0.488-0.945l-0.423-1.592l0.401,0.256l0.34,0.738l0.223,1.566l1.071,2.408l0.257-0.194l0.241,0.734l0.255,0.184
          L314.637,369.989z M324.302,382.326l-0.624-1.288l-0.637-0.059l-0.128-0.268l-0.491-0.337l0.078-0.464l-0.588-0.516l-0.124-0.738
          l-0.711-0.656l-0.855-0.215l-0.732-0.776l-1.082-0.625l-0.125-0.182l0.096-0.231l0.017,0.015l1.018-0.577l0.211,0.349l0.158,0.025
          l0.634,0.921l0.443,0.02l0.337,0.396l1.256,0.257l0.293,0.527l1.004,0.648l-0.043,0.48l1.629,1.863l0.049,0.017l-0.257,0.576
          L324.302,382.326z"
        />
        <path
          class="st32"
          d="M301.679,366.068l0.353-0.939l-0.681-1.593l-1.579-2.14l-0.031-0.078l0.763-0.636l-1.003-1.658l0.194-0.109
          l0.28,0.312l1.173,0.149l0.623-1.254l0.429-0.315l0.118-0.91l-0.297-0.602l0.355-0.946l-0.691-0.524l-0.341-1.933l-1.369,1.072
          l-0.702-0.03l-0.605,1.941l-1.112,1.03l-0.479,1.026l-0.437-0.38l0.282,0.712l-0.231,0.495l-0.144,1.179l0.443,1.053l-0.209,0.214
          l-0.904,0.472l-0.126,1.08l1.114,1.823l1.018-0.032l1.571,1.284l1.392,0.609L301.679,366.068z M298.396,363.031l-0.708,0.022
          l-0.292-0.478l0.184-0.096l0.732-0.696l0.102,0.257l1.593,2.167l0.092,0.214L298.396,363.031z"
        />
        <polygon
          class="st32"
          points="330.726,358.713 331.738,357.58 329.74,355.28 328.41,356.509 329.359,357.724 	"
        />
        <path
          class="st32"
          d="M337.007,403.18l0.09-0.303l-0.804-0.797l-1.085-0.21l-1.264-1.104l-0.783-0.252l-1.539-2.575l-1.72,1.849
          l0.133,1.046l0.7,1.071l2.647,1.698l0.293,0.913l0.572-0.059l0.445,0.626l0.63,0.107l1.311,1.049l0.269-0.06l0.348,0.38
          l1.582,0.283l0.359-1.242l-1.138-1.57L337.007,403.18z M335.365,403.442l-0.012-0.017l0.016,0.003L335.365,403.442z"
        />
        <path
          class="st32"
          d="M491.722,574.489l0.542-0.551l0.23-1.063l-0.219-0.174l0.102-0.19l-0.345-1.73l-1.228,0.319l-0.18-0.474
          l-1.245-0.263l-0.307,0.311l-1.071-0.07l-0.613,0.397l-0.661,0.055l-0.119,0.089l-0.017-0.43l-1.368-0.338l-0.471-1.093
          l-1.613,0.565l-1.838-0.148l-0.865,0.413l-0.528,0.053l-0.326-0.923l-1.225,0.958l-0.104-0.043l-3.557,0.674l-1.081,0.866
          l-0.299,0.532l-2.687,1.429l1.812,2.892l1.196-0.064l0.862-0.598l0.223,0.314l0.252,0.652l-0.379,0.972l-1.838,1.338l-0.261,1.137
          l0.045,0.058l-2.407,0.964h9.792l-1.499-0.388l1.01-0.43l1.02-0.073l1.104-0.38l1.226-0.139l0.07-0.069l0.59,0.277l0.524-0.68
          l0.188-0.014l0.294,0.347l1.29-0.155l0.101-0.178l2.298,0.318l0.04-0.72l0.609-0.086v-0.724l0.717-0.649l0.267-1.066l0.224-0.396
          l1.206-0.495L491.722,574.489z M473.213,574.957l-0.457-0.729l0.443-0.236l0.894,0.353L473.213,574.957z M483.193,577.995
          l-0.199,0.258l-0.453-0.214l-0.444,0.444l-0.796,0.09l-1.059,0.372l-1.037,0.074l-1.386,0.546l-1.73,0.136l-1.281-0.173
          l1.025-0.745l0.751-1.925l-0.496-1.282l-0.415-0.606l7.633,3.016L483.193,577.995z M484.926,578.017l-0.117-0.138l-1.04,0.075
          l-8.298-3.279l-0.214-0.312l-0.6-0.01l-1.225-0.484l0.579-0.308l10.981,4.339L484.926,578.017z M490.56,573.531l-0.474,0.933
          l-1.137,0.466l-0.53,0.938l-0.256,0.909l-0.873,0.79v0.087l-0.539,0.076l-0.018,0.323l-0.502-0.74l-1.076,0.436l-8.075-3.191
          l-2.836-1.121l0.164-0.088l0.372-0.661l0.562-0.45l2.753-0.521l0.583,0.238l0.242-0.083l0.028,0.078l2.12,0.012l-0.153-0.413
          l0.668-0.319l1.752,0.143l0.593-0.208l0.212,0.491l0.522,0.129l-0.881,0.897l1.185,1.126l0.972,0.125l0.818-0.56l0.318-0.705
          l0.138-0.103l0.676-0.101l0.511-0.331l1.222,0.08l0.028,0.074l0.862,0.438l0.055-0.014l-0.207,0.385l0.352,0.28L490.56,573.531z"
        />
        <path
          class="st32"
          d="M516.805,610.918l-1.724,0.122l-0.655,0.42l-1.11,1.881l-0.617,0.285l-0.323,1.065l0.932,0.699h1.578
          l0.207-0.556l0.365-0.055l0.285-0.549l1.215,0.108l1.16-0.343l0.752-1.148l-0.561-1.152L516.805,610.918z M515.437,612.699
          l0.101-0.169l0.037-0.003l0.039,0.188L515.437,612.699z M515.822,612.733l-0.047-0.225l0.503-0.046l0.066,0.317L515.822,612.733z
           M516.805,612.82l-0.251-0.022l-0.074-0.354l0.011-0.001l0.577,0.3L516.805,612.82z"
        />
        <path
          class="st32"
          d="M501.813,536.964l0.228,0.551l0.93,0.278l0.483,0.561l0.874,0.061l0.084,0.577l1.287-1.037l0.022,0.08
          l-0.073,1.145l0.807,0.602l0.637,0.069l0.544,0.6l0.569-0.062l0.115,0.495l1.595,0.857l0.097-0.275l0.601,0.699l1.022-0.008
          l0.135-0.12l0.108,0.311l1.027,0.375l0.982,1.624l0.334-0.445l0.442,1.948l0.865-0.837l2.058,1.02l2.057,0.465l3.903,0.337
          l2.732,0.62l2.42,0.27l1.385-0.298l2.363,0.408l2.667-0.224l1.761-0.473l1.923-0.168l0.624,0.166l1.832-0.182l1.27,0.188
          l1.985-0.631l2.151-0.403l1.063,0.11l1.841-0.419l0.766-1.088l0.11-0.234l1.183,0.326l0.731-1.194l-0.094-0.977l1.557-1.474
          l-0.393-0.783l0.351-0.921l-0.275-0.716l-1.978-1.543l-0.187-0.03l-0.305-0.648l-0.862-0.176l-0.682-0.678l-0.717-0.211
          l-0.573-0.476l-1.316,0.12l-0.21,0.455l-0.204-0.199l-0.366,0.021l0.344-0.332l-0.925-1.701l-3.041-0.192l-0.26-0.466l-4.515-0.446
          l-0.014,0.09l-0.219-0.174l-0.865-0.093l-0.57-0.395l-3.004-0.12l-0.325-0.665l-1.558-0.112l-0.547-0.413l-0.822,0.082
          l-0.738-0.266l-1.506,0.158l-0.969-0.565l-1.16-0.038l-0.277,0.192l-0.16-0.31l-1.228,0.045l-2.363-0.718l-1.045,0.031
          l-0.484-0.473l-1.072,0.044l-0.742,0.398l-1.129,0.13l-3.365-0.23l-0.609-0.48l-1.503-0.07l-0.439-0.294l-1.347-0.172l-1.205,0.452
          l-0.591,0.755l0.019,1.219l0.373,0.867l0.889,1.124l-0.068,0.094l0.368,1.454l-0.741,0.385l-0.494,0.675l-0.29,1.232l0.089,0.277
          l-0.663-0.703l-1.228-0.021l-0.172,0.19l-0.111-0.311l-0.827-0.922l-0.7-0.212l-0.359-0.312l-0.203-0.783l-0.143-0.106l0.125-0.58
          l-0.799-0.668l-0.384-1.013l-0.837-0.503l-0.334,0.028l-0.175-0.361l-0.698-0.738l-1.045-2.436l-1.105,0.911l-0.042-0.164
          l-0.853-0.93l-1.309,0.208l-0.119,0.219l-0.243-0.549l-1.101-0.552l-0.58-0.67l-1.314,0.007l-0.45-0.422l-2.879-0.053l-1.089,0.023
          l-0.875,0.295l-1.019-0.161l-1.075,0.438l-0.248,1.033l0.392,0.578l-0.322,0.452l0.596,0.753l0.154,0.508l-0.297,0.668l0.528,1.275
          l1.447-0.191l1.28,0.604l0.224-0.322l0.499,0.308l0.668,0.068l0.351,0.438l0.822,0.125l0.406,0.354l2.223,0.89l0.023-0.06
          l0.62,0.371l0.535-0.116l0.724,0.47l-0.218,0.351l1.401,0.593l0.296,0.606l1.184,0.306l2.123,1.242L501.813,536.964z
           M544.008,534.745l-0.184,0.049l-1.082,0.902l0.571,1.217l1.547-0.312l0.345,0.325l0.688-0.04l0.605,0.591l1.112-0.385l0.219-0.474
          l0.223,0.184l0.646,0.191l0.73,0.727l0.565,0.115l0.322,0.686l0.589,0.093l1.222,0.953l-0.339,0.889l0.189,0.377l-1.015,0.96
          l-1.652,1.069l-0.285,1.096l-0.355,0.506l-1.038,0.235l-1.034-0.106l-2.452,0.464l-1.172,0.369l-0.561-10.781L544.008,534.745z
           M542.212,534.631l0.325,6.24l0.24,4.615l-0.373,0.118l-0.125-0.018l-0.572-10.987L542.212,534.631z M508.039,538.587l1.752-0.666
          l0.365-1.015l-0.57-0.502l-0.126-0.394l0.125-0.529l0.106-0.146l0.362-0.188l1.124,0.015l1.582-0.72l-0.876-1.192l-1.136-0.4
          l-0.055-0.212l0.697-0.957l-1.234-0.563l-0.577-0.699l-0.213-0.496l-0.006-0.392l0.466-0.179l0.628,0.08l0.492,0.329l1.44,0.068
          l0.596,0.469l3.965,0.271l1.551-0.179l0.691-0.371l0.082-0.003l0.477,0.465l1.436-0.043l2.369,0.72l0.526-0.02l0.15,0.292
          l0.984,0.256l0.646-0.448l0.285,0.009l1.064,0.622l1.652-0.174l0.736,0.266l0.575-0.059l0.432,0.327l1.139,0.081l0.181,0.373
          l0.628,0.34l2.995,0.121l0.525,0.363l0.686,0.073l0.26,1.636l1.655,0.64l0.304-1.99l2.319,0.229l0.207,0.37l0.577,11.091
          l-0.786-0.116l-1.744,0.174l-0.616-0.164l-1.989,0.175l-2.021,0.495l-2.405,0.2l-2.461-0.424l-1.431,0.308l-2.093-0.229l-2.7-0.618
          l-3.958-0.342l-1.732-0.382l-1.595-0.73l-0.962-0.79l-0.245-1.077l-1.128-0.615l-0.187,0.248l-0.105-0.177l-0.856-0.312
          l-0.451-1.288l-1.169-0.265l-0.163,0.145l-0.239-0.391l-1.662-0.634l-0.009,0.025l-0.005-0.022l-1.131,0.125l-0.412-0.455
          l-0.617-0.067l0.03-0.471l-0.056-0.207L508.039,538.587z M503.5,535.872l0.077-0.15l0.577,0.175l0.05,0.057l-0.538,0.481
          l-0.166-0.05V535.872z M503.3,536.261v0.066l-0.029-0.009L503.3,536.261z M487.129,526.688c-0.023,0.003-0.045,0.01-0.068,0.014
          l-0.018-0.027L487.129,526.688z M498.532,533.978l-0.239-0.489l-0.301-0.128l0.157-0.252l-0.761-0.901l-0.857,0.05l-0.955-0.617
          l-0.172,0.037l-0.482-0.771c-1.196-2.536-3.773-4.295-6.768-4.295c-0.04,0-0.079,0.007-0.119,0.008l0.486-0.192l0.925-0.021
          l2.272,0.041l0.464,0.436l1.222-0.005l0.323,0.373l0.886,0.444l0.404,0.915l0.819,0.527l0.988-0.35l0.143-0.264l0.009,0.035
          l-0.62,1.981l1.638,1.288l0.718-1.384l-0.053-0.219l0.464-0.259l0.102,0.237l0.665,0.642l0.566,1.171l0.861-0.073l0.333,0.877
          l0.406,0.34l-0.136,0.637l0.491,0.366l0.122,0.47l-0.291-0.264l-0.815,1.016l-0.118-0.019l-1.968-1.152L498.532,533.978z"
        />
        <path
          class="st32"
          d="M533.857,565.732l0.847,0.003l1.791-0.562l5.447-0.087l6.999,0.969l0.449,0.055l1.151,0.3l1.196,0.142
          l0.549-0.284l1.072,0.347l1.37-0.095l3.677,0.461l0.664-0.171l1.091,0.347l0.728-0.679l0.39,0.173l2.256,0.249l1.116-0.119
          l0.452,0.383l2.104-0.014l0.169-0.158l0.179,0.341l2.093-0.448l0.851,0.222l0.085-0.062l0.649,0.88l0.854-0.654l0.694,0.058
          l0.142-0.443l0.208,0.163l1.416,0.065l0.898-0.272l0.813,0.024l0.402-0.325l2.399-0.057l0.96-0.538l0.432,0.114l1.949-0.841
          l-0.463-1.442l-1.194-0.255l-0.741-0.516l-3.372-0.77l-0.984,0.195l-0.557,0.39l-1.583,0.103l-1.012-0.321l-4.52-0.67l-1.707-0.049
          l-0.459-0.353l-1.257,0.192l-0.335-0.062l-0.397-0.81l-1.333-0.217l-0.401,0.197l-2.063-0.539l-3.38-0.135l-0.577,0.135
          l-1.76-0.227l-0.75,0.241l-0.854-0.821l-0.537,0.381l-0.054-0.188l-0.396-0.223l-0.151-0.438l-1.414-0.237l-0.798,0.597
          l-0.073,0.173l-0.757-0.097l-0.382,0.084l-0.561-0.524l-0.923-0.141l-0.375,0.235l-0.782-0.262l-0.848,0.76l-0.397-0.188
          l-1.994-0.339l-0.465,0.286l-0.424-0.27l-1.663-0.122l-0.83-0.4l-2.028,0.754l-1.05-0.075l-0.918-0.7l-0.538,0.146l-0.166-0.561
          l0.983-0.531l-1.295-1.148l-0.671-0.211l-0.145-0.541l-1.172-1.031l-0.522-0.16l0.241-0.622l-0.219-1.257l-0.312-0.227
          l-0.191-0.754l-1.477-0.044l-0.004,0.002l-1.04-0.322l-1.141,0.636l0.03,0.107l-0.254-0.069l-0.69,0.176l-0.056-0.537l-0.735-0.593
          l-1.168,0.104l-0.264,0.7l-0.523,0.016l-0.087,0.075l-0.008-0.091l-0.835,0.107l-0.21-0.201l0.188-1.297l-0.18-1.021l-1.097-0.462
          l-0.479,0.46l-0.19-0.223l-1.264,0.108l-0.81,0.812l0.023,0.098l-0.073,0.011l-0.173-0.537l-1.335,0.322l-1.237,1.647l1.666,1.09
          l0.192,0.566l-0.444-0.351l-1.038,0.062l-1.382-0.622l-2.193,0.189l-0.675,0.232l-0.768-0.436l-0.708,0.788l-0.351-0.022
          l-0.298,0.41l-0.511-0.139l-0.24,0.426l-0.804-0.209l-0.102,0.359l-0.792-0.166l-0.063,0.193l-1.024,0.186l-0.135,0.309
          l-0.298,0.042l-0.669-0.607l-0.525,0.142l-0.328-0.331l-1.054,0.61l-0.779-0.182l-1.02,1.527l1.118,0.401l0.226,0.546l2.987,0.772
          l0.901,0.605l0.671,0.097l0.442,0.496l-1.197,1.148l-0.017-0.025l0.44-0.445l-1.362-1.325l-0.515,0.426l-0.316-0.133l-0.4,0.074
          l-0.694-0.48l-0.77,0.151l-2.71-1.288l-1.566,1.774l0.013,1.815l1.349-0.579l0.09,0.631l1.363-0.195l0.298,0.175l-0.004,0.759
          l1.387,0.234l0.107-0.135l1.051,0.242l0.058,0.552l1.293-0.762l0.535,0.146v0.061l1.188,0.382l0.854-0.765l1.292,0.254
          l-0.962-2.235l0.409,0.172l0.628,1.155l1.273-0.006l0.233,0.358l1.992,0.288l0.599-0.683l0.897,0.618l0.685,0.276l0.304,0.424
          l1.636,0.589l0.866,0.917l0.996,0.275l1.65-0.481l0.604,0.126l1.416,0.739l1.746,0.319l1.121,0.697l7.13,1.21L533.857,565.732z
           M504.734,555.605l0.018-0.005l0.014,0.013L504.734,555.605z M550.215,561.181l0.29-0.684l0.333,0.188l0.476,1.644l1.245-0.898
          l0.604,0.58l1.283-0.413l1.695,0.217l0.647-0.15l2.936,0.102l2.521,0.658l0.473-0.232l0.096,0.016l0.388,0.792l1.326,0.246
          l0.875-0.134l0.338,0.259l2.104,0.055l4.254,0.626l1.223,0.389l2.284-0.147l0.643-0.45l0.361-0.072l2.776,0.635l0.143,0.099
          l-0.878,0.49l-2.536,0.059l-0.367,0.298l-0.503-0.015l-0.885,0.269l-0.682-0.032l-0.369-0.289l-1.393-0.178l-0.201,0.63
          l-0.079-0.007l-0.103,0.078l-0.133-0.18l-1.012-0.298l-0.201,0.147l-0.507-0.132l-1.327,0.284l-0.567-1.079l-1.177,1.105
          l-0.961,0.006l-0.511-0.439l-1.614,0.172l-1.74-0.159l-0.66-0.3l-0.907,0.123l-0.435,0.405l-0.667-0.212l-0.805,0.208l-3.537-0.443
          l-1.227,0.085l-1.393-0.45l-0.699,0.362l-0.587-0.065l-1.187-0.304l-0.466-0.064c0.101-0.488,0.172-0.987,0.172-1.506
          c0-0.706-0.105-1.386-0.289-2.033L550.215,561.181z M534.561,560.193l0.333,0.255c-0.03,0.079-0.055,0.16-0.082,0.241l-1.374-0.19
          L534.561,560.193z M522.1,553.502l0.019,0.217l-0.641-0.068l-0.053-0.062L522.1,553.502z M520.338,550.959l0.664,0.047
          l-0.088,0.608l-0.787-0.155L520.338,550.959z M518.748,551.689l0.447,0.286l-0.029,0.068l-0.357,0.006l-0.101-0.295l-0.076-0.05
          L518.748,551.689z M518.93,553.927l0.352-0.385l0.594-0.011l0.138,0.711l0.707,0.836l0.37,0.039l-0.037,0.271l0.37,0.857
          l-0.133,0.015l-0.644-0.644l-0.766-0.145l-1.425-1.47l-0.244-0.03l-0.03-0.179L518.93,553.927z M508.105,556.721l-0.12-0.018
          l-0.292-0.896l0.526-0.056l0.763,0.728l0.294-1.035l0.501,0.129l0.228-0.403l0.377,0.111l0.432-0.595l1.109,0.072l-0.037-0.331
          l0.939-0.369l1.695-0.147l1.362,0.614l0.837-0.05l0.062,0.05l0.135,0.799l0.828,0.103l1.388,1.433l0.779,0.146l0.825,0.825
          l2.587-0.286l1.643-1.023l-1.677-1.084l-0.67-0.199l-0.026-0.061l0.082-0.602l1.437-1.246h0.403l0.487-0.332l0.415,0.593
          l0.731,0.136l0.825-0.211l1.096,0.3l0.404-0.325l0.124,0.353l1.112-0.67l0.033,0.131l0.22,0.159l0.082,0.275l-0.392,1.01
          l0.37,0.928l1.104,0.339l0.588,0.518l0.048,0.18l-0.525,0.04l0.449,1.617l1.191,0.677l0.423,1.435l-24.918-3.445L508.105,556.721z
           M512.212,559.398l-0.181-0.025l-0.308-0.476l-1.196,0.006l-0.389-0.717l2.995,0.414l-0.932,0.744L512.212,559.398z
           M509.734,557.929l-0.878-0.369l-0.263-0.295l1.994,0.276l24.167,3.342c-0.05,0.161-0.104,0.319-0.143,0.484l-19.116-2.642
          L509.734,557.929z M526.196,563.436l-1.127-0.701l-1.694-0.271l-1.403-0.732l-1.264-0.292l-1.602,0.468l-0.182-0.051l-0.823-0.872
          l-1.534-0.554l-0.302-0.42l-0.86-0.315l-1.018-0.7l-1.118-0.375l21.304,2.945c-0.102,0.488-0.166,0.989-0.166,1.507
          c0,0.398,0.043,0.784,0.106,1.163l-0.77-0.003l-1.066,0.304L526.196,563.436z"
        />
        <polygon
          class="st32"
          points="482.514,526.111 481.683,528.202 483.459,528.461 484.318,528.448 484.997,527.376 483.601,525.702
          "
        />
        <path
          class="st32"
          d="M477.688,520.797l-0.05,0.782l-1.755,2.104l2.968,1.235l1.427-0.528l0.781,0.321l1.019-0.92l-0.008-0.036
          l0.923,0.263l0.875-0.721l1.13,1.122l-0.213-2.896l-0.379-0.533l-0.012-0.427l-1.365-1.273l-1.192-0.319l-1.371,0.092l-0.728-0.245
          l-0.859,0.456l0.033,0.706l-0.247-0.066L477.688,520.797z M479.285,521.637l1.757,0.479l0.094-1.595l0.554-0.036l0.59,0.157
          l0.633,0.592l0.007,0.255l0.018,0.025l-0.556,0.16l0.053,0.625l-1.054-0.301l-0.936,0.839l-0.139-0.057l-0.804,0.298l-0.261-0.307
          l0.541-0.115L479.285,521.637z M478.968,522.83l0.289,0.34l-0.373,0.138l-0.161-0.067l-0.258-0.304L478.968,522.83z"
        />
        <path
          class="st32"
          d="M535.365,567.643l-1.261,0.098l-0.589-0.408l-2.599,0.551l-1.88,1.799l2.261,1.153l2.512,0.237l0.728,0.5
          l1.129-0.304l0.48-0.382l0.727,0.084l1.086-0.691l0.355-0.874l-0.465-1.058l-1.115-0.416L535.365,567.643z M535.701,569.324
          l-0.725,0.577l-0.16,0.042l-0.478-0.328l-2.615-0.247l-0.124-0.063l1.594-0.371l0.495,0.344l1.583-0.123l0.993,0.203l0.129,0.048
          L535.701,569.324z"
        />
        <path
          class="st32"
          d="M475.702,519.035l-1.236,0.139l-0.388,0.281l-1.025-0.139l-0.378,0.542l-0.838,0.026l-1.029,0.936
          l0.664,1.176l1.994,0.568l0.362-0.082l-0.058,0.137l0.885,0.908l1.1-0.307l0.443-0.812l1.295-0.905l-1.178-1.24L475.702,519.035z
           M473.73,520.966l0.028-0.04l0.094,0.013L473.73,520.966z"
        />
        <polygon
          class="st32"
          points="493.559,662.516 493.346,662.25 492.95,661.931 492.297,661.706 492.071,661.73 491.694,661.45
          490.949,661.704 490.349,662.534 490.615,663.848 491.617,663.754 492.008,663.961 492.504,663.922 493.202,664.257
          494.139,664.41 495.039,663.381 494.166,662.739 	"
        />
        <path
          class="st32"
          d="M656.835,623.865L655.748,623l-1.093-1.379l-1.104-0.181l-0.754,0.653l-0.135,0.453l-0.905-0.059
          l-0.997,0.884l2.112,2.591l1.822,0.209l0.901-0.607l0.232,0.159h1.056l0.507-1.004L656.835,623.865z M653.971,624.579l-0.321-0.036
          l-0.37-0.454l0.478,0.031l0.265-0.885l0.396,0.5L653.971,624.579z"
        />
        <polygon
          class="st32"
          points="660.446,624.125 659.285,623.348 658.248,624.354 658.039,624.336 657.37,624.837 656.977,626.007
          659.219,626.787 659.441,627.448 661.053,627.931 661.426,625.874 660.619,624.769 	"
        />
        <path
          class="st32"
          d="M352.682,334.845l1.496,2.966l0.614-2.622l0.345-0.345l0.157,0.04l0.667-1.192l-0.769-1.77l-1.399-0.165
          l-2.41,0.23l-0.678,1.124l1.444,1.633L352.682,334.845z M353.704,333.273l0.003,0.275l-0.773-0.182l-0.016-0.018L353.704,333.273z"
        />
        <path
          class="st32"
          d="M709.628,661.96l-0.559-0.856l-0.564-0.28l0.173-0.712l1.747-1.02l-0.941-0.675
          c-0.261-0.186-0.539-0.347-0.818-0.507c-0.352-0.202-0.685-0.393-0.951-0.632c-0.631-0.569-1.113-1.35-1.625-2.177
          c-0.48-0.776-0.978-1.58-1.635-2.255c-0.526-0.541-0.932-0.667-1.257-0.768c-0.161-0.05-0.288-0.089-0.447-0.184
          c-0.018-0.014-0.062-0.068-0.123-0.143c-0.425-0.52-0.785-0.761-1.134-0.993c-0.023-0.016-0.046-0.031-0.069-0.047
          c0.075-0.261,0.154-0.554,0.231-0.841c0.121-0.447,0.271-1.003,0.343-1.172c0.043-0.099,0.106-0.189,0.167-0.283
          c0.111-0.17,0.236-0.363,0.323-0.598l0.157-0.428l-0.308-0.337c-0.472-0.516-0.718-1.128-0.677-1.684
          c0.023-0.333,0.067-0.688,0.11-1.048c0.082-0.676,0.167-1.374,0.145-2.028c-0.006-0.163-0.032-0.312-0.067-0.451
          c0.482-0.056,0.981-0.155,1.354-0.464l0.787-0.654l-4.952-3.185c-0.274-0.306-1.317-1.476-1.695-1.979
          c-0.387-0.516-1.291-1.724-1.556-2.257c-0.664-1.315-5.421-0.872-6.095-0.681c-0.654,0.188-2.271,0.084-3.135-0.022l-0.284-0.035
          l-0.235,0.162c-0.762,0.527-2.042,1.385-2.382,1.575c-0.093-0.02-0.318-0.259-0.318-0.51c0-1.145-1.043-1.511-1.733-1.753
          c-0.127-0.044-0.255-0.089-0.379-0.139c-0.382-0.151-0.628-0.618-1.285-1.944l-0.262-0.527c-1.026-2.053-3.192-2.739-3.284-2.768
          l-0.287-0.088l-1.1,0.549l-1.76-3.386l-2.989-0.649c0.088-0.37,0.182-0.856,0.248-1.445c0.182-1.637-2.479-3.191-3.742-3.834
          c-0.377-0.356-2.051-1.964-2.794-3.075c-0.823-1.236-2.403-1.032-3.561-0.575l-0.62-1.239c0.025-0.41-0.002-1.193-0.489-1.769
          c-0.244-0.288-0.697-0.632-1.471-0.632c-0.757,0-2.225-1.508-2.978-2.526l-0.13-0.176l-4.271-1.645l-1.292,0.215l-0.078-0.353
          l1.44-2.342l-2.873-1.619c-0.271-0.51-1.012-1.919-1.36-2.73c-0.272-0.64-0.291-2.143-0.239-2.939l0.02-0.287l-1.818-2.312
          l-3.493-2.191l-2.096,3.076c-0.112-0.13-0.244-0.283-0.398-0.464c-0.953-1.107-2.331-2.033-2.39-2.072l-0.13-0.087l-0.154-0.027
          c-0.001,0-4.166-0.744-5.654-1.043c-1.95-0.389-2.674,0.71-3.304,1.662c-0.628,0.938-1.477,3.181-1.571,3.433l-0.22,0.953
          l-0.849-0.111l0.352,0.661l-0.176-0.062l-0.783-1.105l-0.858-0.551l-1.166-1.348l-0.547,0.476l-0.336-0.304l-0.699-0.058
          l-0.461-0.594l-0.982-0.184l-1.118-0.764l-0.724-0.057l-2.434-1.838l-1.492-0.122l-0.769,1.493l0.102,0.073l-0.179-0.001
          l-0.351,0.383l-0.622-0.088l-0.244-0.092l-0.229-0.508l-1.097-0.436l-0.145,0.077l-1.211-0.172l-0.71-0.317l0.034-1.85
          l-0.251-1.111l-2.062-1.79l-1.776-0.145l-1.035-0.697l-1.115-0.486l-1.659-1.255l-1.859-0.815l-0.536-0.443l-2.378-1.278
          l-1.448-0.34l-1.031-0.672l-1.663-0.058l-0.041-0.515l-4.788-0.745l-6.755-1.73l-1.372,0.144l-1.268-0.285l-3.121,0.55
          l-1.771-0.002l-0.479,0.252l-0.407-0.162l-0.445,0.01l-0.031-0.223l-1.6-1.133l-0.267-0.665l-0.976-0.394l-0.731-0.834
          l-1.464-0.535l-1.96-0.336l-0.99,1.213l1.471,0.876l0.103,0.272l-0.104,1.497l0.748,0.649l-0.147,0.181l0.025,0.59l-0.101,0.145
          l-1.432-0.507l-0.042,2.051l1.181,0.728l1.761,0.286l3.829,2.205l1.566,0.611l1.565-0.083l0.755,0.513l1.754-0.021l1.294,0.824
          l2.76,0.178l0.652-0.169l1.529,0.05l0.93-0.796l0.499-0.148l0.488-0.025l0.986,0.337l1.857,1.858l4.151,2.698l1.59,0.099
          l1.128,0.835l2.281,0.793l2.18,1.989l1.13,0.652l1.083-0.124l1.904,0.246l0.69-0.318l0.096,0.484l0.976-0.187l0.758,1.045
          l1.816,0.72l0.038,0.069l-0.354-0.098l-1.49,2.376l1.142,0.962l2.005,0.666l1.272,0.807l1.675,0.326l3.109,1.719l1.848,0.302
          l0.865,0.536l2.259,0.277l1.674,0.827l1.741,1.168l0.727,0.159l0.747,0.448l2.014,0.233l1.503,0.763l0.858-0.192l0.798,1.052
          l0.955-0.08l0.438,0.465l0.255-0.019l0.089,0.488l1.002,0.279l0.475-0.168l0.438,0.459l1.795,0.355l0.504-0.186l0.387,1.001
          l-0.122,0.008l-0.342,1.154l1.7,2.07l1.632-0.556l-0.262-0.597l0.705-1.469l-0.4-0.98l-1.562-1.925l-0.253-0.703l0.521,0.399
          l0.418,0.845l0.587,0.682l1.279,0.905l0.998-0.568l-0.029-0.613l1.173-1.278l1.378,1.014l0.534-0.58l0.899,0.345l0.304-0.41
          l0.092,0.206l-0.193,0.496l0.364,0.736l1.396,0.925l0.604,1.176l0.027-0.002l-0.019,0.025l0.654,1.076l2.909,2.465l2.097,0.889
          l0.564,0.99l0.819,0.378l0.876,1.021l0.199-0.117l0.294,0.46l0.594,0.101l0.232,0.847l0.946,0.313l0.684,0.771l0.89,0.4
          l0.757,0.753l0.243,0.198l1.17,0.075l0.626,0.641l2.786,1.449l0.068-0.241l0.929,0.859l-0.697,1.028l0.244,0.917l-0.313,0.159
          l0.32,1.12l2.521,0.941l1.201,1.008l0.308-0.707l0.403,0.32l0.852,0.112l-0.165,0.687l0.732,1.919l1.67-0.207l0.619-1.038
          l-0.179-0.187l0.815,0.074l1.14,0.671l1.09,1.087l1.49,0.833l0.377-0.778l0.682,0.132l0.229-0.55l0.116,0.021l0.308-0.234
          l0.495,0.829l0.969,0.392l0.362,0.658l-0.363,0.608l0.079,0.703l-1.266-0.622l0.104,1.352l-0.952,0.438l1.014,1.274l0.943,0.271
          l0.359,0.427l0.246,1.025l0.704,1.149l2.438,1.37l0.907,0.876l0.354,1.35l2.334,1.066l0.741,0.685l0.96,0.16l0.609,0.306
          l0.453,0.48l2.269,1.546l1.386,0.679l1.406,0.959l1.676,1.772l0.513-0.363l0.53,0.269l0.356,0.387l-0.071-0.022l0.039,1.651
          l3.071,2.427l0.165-0.926l0.035,0.027l1.191-0.105l0.701,0.176l0.391,0.325l0.587,1.463l1.404,1.728l0.958,0.379l0.245,0.236
          l0.621,1.532l1.854,1.57l0.933,0.32l1.121-0.593l-0.414-1.084L709.628,661.96z M564.89,575.914l0.514,0.188l0.462,0.526
          l-0.932-0.209l0.023-0.327L564.89,575.914z M592.066,588.595l-1.639-0.101l-3.632-2.343l-2.047-2.048l-1.59-0.544l-0.866,0.046
          l-1.064,0.294l-0.73,0.626l-1.162-0.038l-0.631,0.163l-2.062-0.132l-1.417-0.864l-1.73,0.021l-0.782-0.53l-1.751,0.092
          l-1.109-0.427l-3.896-2.243l-0.955-0.204l0.478-0.69l-0.021-0.523l1.37-0.41l-0.637-1.792l0.641,0.259l0.192,0.481l1.432,1.014
          l0.129,0.9l1.473-0.031l0.765,0.305l0.762-0.4l1.467,0.002l3.021-0.521l1.215,0.273l1.349-0.142l6.371,1.628l3.881,0.627
          l0.053,0.669l2.585,0.089l0.914,0.596l1.424,0.306l2.092,1.109l0.562,0.464l0.23,0.102l-4.577,3.994L592.066,588.595z
           M592.334,588.792l4.614-4.027l0.505,0.224l-3.88,3.387l-0.826,0.721L592.334,588.792z M605.753,598.21l0.185-0.295l0.537,0.147
          l0.655-0.675l0.362-1.718l-0.593-0.231l-0.279-0.437l-1.902-0.753l-0.755-1.042l-0.643-0.359l-0.14,0.026l-0.885-0.756
          l-1.146,0.111l-0.253,0.671l-1.55-0.201l-0.786,0.091l-0.491-0.273l-2.352-2.147l-2.261-0.747l-0.546-0.403l4.743-4.14l0.76,0.337
          l1.66,1.256l1.168,0.519l1.301,0.875l1.677,0.137l1.318,1.146l0.103,0.455l-0.04,2.199l0.397,0.643l1.312,0.586l0.213,0.06
          l0.065,0.568l0.394,0.289l-0.96,4.501L605.753,598.21z M607.641,598.86l-0.426-0.147l0.946-4.437l0.255,0.187l0.248-0.138
          l-0.974,4.566L607.641,598.86z M640.141,606.773l-0.259,0.158l-0.674-0.24l-0.673,0.989l0.223,0.446l-0.797,0.868l-0.288-0.312
          l-0.519-1.049l-0.833-0.661l-2.301-0.969l-1.591-1.17l-1.037-0.188L630,605.472l1.998,1.968l1.861,1.114l0.057,0.058l-0.588,0.218
          l-0.914-0.182l-0.779-0.816l-0.624,0.22l-0.125-0.681L630,607.438l-0.438-0.465l-0.609,0.043l0.102-0.295l-0.988-0.901l-1.52,0.341
          l-1.141-0.579l-2.079-0.288l-0.575-0.345l-0.794-0.214l-1.588-1.059l-1.874-0.933l-2.301-0.326l-0.848-0.524l-1.772-0.245
          l-3.089-1.708l-1.733-0.374l-0.883-0.56l1.026-4.81l0.454-0.253l0.044,0.097l0.902,0.339l1.646,0.251l0.1-0.108l0.963,0.731
          l1.141,0.088l0.84-1.007l-0.505-0.928l1.457,1.101l0.763,0.061l1.048,0.715l0.732,0.137l0.518,0.666l0.85,0.069l0.953,0.864
          l0.412-0.366l0.249,0.288l0.867,0.593l0.891,1.256l0.812,0.286l0.432,0.626l2.043,0.688l1.045-0.688l-0.249,0.999l0.942,0.273
          l0.313,0.729l1.113,0.096l1.091-0.468l0.197,0.058l0.387-1.261l-0.324-0.653l1.539-1.427l-1.729-1.449l-1.147,0.563l-0.376,1.077
          l-0.117,0.152l-0.53-0.474l-1.582-0.696l-0.605-0.612l0.009-0.037c0.257-0.68,0.957-2.423,1.444-3.151
          c0.551-0.831,0.756-1.142,1.714-0.951c1.32,0.266,4.741,0.88,5.523,1.021c0.339,0.238,1.295,0.942,1.96,1.715
          c0.928,1.085,1.083,1.237,1.083,1.237l0.645,0.635l1.29-1.893V606.773z M640.843,607.047l-0.503-0.37v-11.789l0.503-0.738V607.047z
           M665.615,628.182l-0.017-0.407l0.315,0.519L665.615,628.182z M666.581,625.057l-1.032,0.06l-1.558-1.439l-0.139-1.311
          l-0.433-1.016l-1.177-0.388l-0.753,0.929l0.085,0.865l-0.263-0.132l-0.94-0.965l-1.228-0.075l-0.654-0.65l-0.943-0.477
          l-0.732-0.831l-0.569-0.188l-0.273-0.993l-0.881-0.149l-0.714-1.12l-0.365,0.215l-0.272-0.317l-0.679-0.312l-0.582-1.025
          l-2.275-0.922l-2.084-1.729l0.15,0.034l0.731-1.159l-0.084-0.327l0.28-0.265l0.238-0.84l-0.349-1.45l-1.34,0.434l-0.481,0.561
          l-0.779,0.207l-0.088,0.154l-0.086-0.166l-1.23-0.815l0.135-0.345l-0.404-0.91l0.109-1.28l-1.919,0.171l-0.334,0.452l-0.784-0.302
          l-0.315,0.342l-0.527-0.388v-13.336l0.149-0.219l1.962,1.199l1.383,1.76c-0.027,0.692-0.043,2.363,0.371,3.336
          c0.456,1.062,1.478,2.962,1.521,3.042l0.103,0.191l1.896,1.067l-0.942,1.533l0.518,2.333l2.291-0.381l3.485,1.342
          c0.597,0.774,2.423,2.958,4.051,2.958c0.239,0,0.302,0.072,0.325,0.101c0.146,0.17,0.161,0.591,0.127,0.816l-0.041,0.241
          l1.507,3.008l0.685-0.382c0.354-0.197,1.879-0.858,2.29-0.237c0.934,1.397,3.009,3.332,3.097,3.414l0.176,0.121
          c1.697,0.848,3.041,1.994,3.021,2.376c-0.136,1.208-0.399,1.891-0.4,1.895l-0.329,0.823l3.724,0.809l-1.695,0.226l-2.646-0.588
          L666.581,625.057z M679.376,632.752l-0.619-1.04l-1.016-0.34l-0.566,0.43l-0.653-0.112l-0.219,0.526l-0.613-0.118l-0.126,0.26
          l-0.168-0.017l-0.825-0.823l-1.66-1.004l-1.047-0.096l-0.438-0.508l-1.201-0.499l-1.522-0.229l-1.254-0.995l0.167-0.739
          l-0.189-0.621l0.714-0.261l3.242,0.581l1.109-0.886l-0.475-1.256l0.224,0.049l2.115,4.071l1.855-0.927
          c0.495,0.216,1.568,0.791,2.124,1.901l0.26,0.522c0.519,1.049,0.864,1.734,1.329,2.186L679.376,632.752z M686.658,634.122
          c0.343,0.037,0.931,0.088,1.554,0.101c-0.006,0.048-0.013,0.096-0.019,0.144l-1.645-0.17
          C686.585,634.172,686.627,634.144,686.658,634.122z M680.202,633.039c0.142,0.1,0.293,0.189,0.468,0.258
          c0.143,0.057,0.29,0.108,0.437,0.16c0.177,0.062,0.434,0.153,0.594,0.239l-1.093-0.113l-0.218-0.396l-0.428-0.173L680.202,633.039z
           M681.618,640.533l-0.329-0.499l-0.299-1.242l-0.691-0.822l0.775-0.27l-0.193-1.72l0.545-0.911l-0.7-1.272l1.128,0.117
          c0.043,0.653,0.414,1.303,0.971,1.646c0.466,0.288,0.991,0.323,1.441,0.097c0.424-0.212,1.385-0.85,2.025-1.284l1.891,0.196
          c-0.017,0.199-0.037,0.397-0.037,0.6c0,1.124,0.256,2.187,0.702,3.143l-5.397,3.274L681.618,640.533z M684.091,641.998
          l-0.116-0.112l-0.295-0.169l5.268-3.195c0.069,0.137,0.143,0.272,0.22,0.404L684.091,641.998z M707.371,659.137l-0.09,0.369
          l-0.483-0.466l-0.882-0.35l-0.961-1.141l-0.685-1.704l-0.943-0.788l-1.279-0.318l-0.848,0.074l-3.048-3.114l-1.672-0.897
          l-0.187,0.133l-0.837-0.886l-1.641-1.14l-1.394-0.684l-2.024-1.358l-0.492-0.521l-1.035-0.568l-0.842-0.175l-0.501-0.462
          l-1.994-0.951l-0.28-1.068l-0.979-0.945l5.019-3.044c1.325,2.104,3.665,3.507,6.34,3.507c1.699,0,3.263-0.569,4.519-1.521
          l0.009,0.133c0.019,0.276,0.095,0.514,0.161,0.723c0.047,0.146,0.091,0.283,0.094,0.375c0.019,0.535-0.06,1.175-0.134,1.794
          c-0.047,0.386-0.093,0.766-0.118,1.12c-0.06,0.801,0.198,1.643,0.719,2.374c-0.099,0.153-0.193,0.309-0.264,0.472
          c-0.107,0.25-0.228,0.686-0.413,1.373c-0.128,0.476-0.26,0.969-0.368,1.274l-0.181,0.51l0.427,0.333
          c0.214,0.166,0.4,0.292,0.574,0.408c0.315,0.211,0.524,0.35,0.831,0.725c0.106,0.131,0.252,0.311,0.49,0.451
          c0.317,0.188,0.569,0.266,0.771,0.329c0.244,0.075,0.367,0.113,0.628,0.382c0.542,0.557,0.976,1.257,1.434,1.997
          c0.544,0.88,1.106,1.789,1.896,2.502c0.279,0.251,0.575,0.446,0.87,0.622L707.371,659.137z"
        />
        <path
          class="st32"
          d="M581.045,591.268l-0.689-0.342l-0.49,0.04l-1.392-1.562l-0.866-2.146l-0.625-0.202l-0.224-0.531l-0.663-0.364
          l-1.062,0.02l-1.54-0.508l-0.888,0.194l-0.606-0.292l-2.328-0.438l-1.474-0.508l-0.309-0.197l-0.373-0.662l-1.014-0.194
          l-2.396,0.654l-1.938,0.175l-3.123-0.042l-4.372,0.35l-1.364,0.839l-1.022,0.139l-2.408,0.956l-0.55-0.02l-1.836-0.716
          l-5.176-1.012l-0.833-0.42l-2.612-0.391l-0.599-0.415l-0.663-0.243l-0.505-1.011l-2.236-0.078l-0.512,0.143l-0.627-0.748
          l-1.025,0.008l-0.298,0.266l-1.44-0.231l-0.311,0.341l-1.027-0.104l-0.423,0.382l-1.838-0.132l-1.547,1.5l0.914,0.957l0.992,0.261
          l0.261,0.541l1.201,0.328l0.456-0.845l0.168-0.036l0.187,0.028l0.252,0.517l0.496,0.254l0.167,0.536l0.477,0.409l-0.312,0.204
          l-0.735,0.058l-0.819,0.874l0.13,0.6l-1.666,0.546l-0.753,0.96l0.417,1.774l0.741,0.661l0.96,0.461l0.933,1.167l1.169-1.717
          l2.598,0.658l0.614-0.06l0.618,0.355l0.076-0.035l0.796,0.948l1.485,0.952l0.704-0.847l0.157,0.173l2.314,0.388l0.382,0.353
          l1.304-0.298l3.575,0.324l1.516-0.122l-0.087,0.262l0.684,1.023l1.411-0.212l0.353,0.252l3.464-0.604l1.897-1.259l1.277-0.284
          l0.341-0.268l0.458,0.171l0.742-0.421l1.266-1.315l0.657-0.177l4.453,0.273l1.891,0.291l0.96,0.412l2.937,0.592l0.853,0.377
          l1.454,0.207l0.796,0.467l0.431-0.345l0.941,0.234l0.401-0.361l1.694,0.157l0.776-0.369l1.615,0.155l0.776-0.474l0.6-0.037
          l0.388,0.321l0.965-0.11l1.008-1.308l-0.55-1.492L581.045,591.268z M564.671,591.044l-4.785-0.297l-1.293,0.354l-1.3,1.404
          l-0.032,0.018l-0.591-0.22l-0.735,0.576l-1.081,0.24l-2.002,1.27l-2.536,0.441l-0.337-0.24l-0.151,0.023l0.309-0.422l-0.567-1.03
          l-0.957-0.155l-0.959,0.575l-1.566,0.126l-3.685-0.334l-0.765,0.175l-0.13-0.12l-2.242-0.374l-0.341-0.377l-2.877-1.413
          l-0.685,0.307l-0.366-0.21l-0.829,0.081l-3.169-0.804l-1.147,0.537l0.041,0.273l-0.59-0.258l-0.299-0.267l-0.144-0.61l1.817-0.622
          l0.604-0.822l-0.1-0.456l0.396-0.031l0.445-0.29l1.174-0.01l0.402-0.271l1.794,0.674l0.604-1.233l-0.346-1.156l-0.668-0.09
          l-0.322-0.382l-1.056-0.301l-0.26,0.23l-0.411-0.192l-0.736,0.021l-0.207-0.664l-0.611-0.312l-0.365-0.746l0.24,0.024l0.273-0.3
          l1.343,0.216l0.266-0.236l0.002-0.001l0.424,0.506l0.69,0.198l0.795-0.221l1.113,0.039l0.392,0.784l0.959,0.299l0.803,0.557
          l2.606,0.348l0.812,0.41l5.277,1.047l1.891,0.737l1.246,0.086l2.435-0.992l1.242-0.169l1.327-0.815l3.941-0.318l2.25,0.029
          l4.158,5.291L564.671,591.044z M565.792,591.227l-0.269-0.342l-3.924-4.993l0.622,0.008l0.02-0.002l4.282,5.448L565.792,591.227z
           M578.905,592.811l-1.531-0.147l-0.78,0.371l-1.935-0.181l-0.298,0.268l-0.889-0.221l-0.204,0.163l-0.177-0.104l-1.439-0.172
          l-0.826-0.365l-2.947-0.596l-0.975-0.418l-0.088-0.014l-4.336-5.517l1.824-0.161l2.248-0.602l0.213,0.378l0.7,0.446l1.771,0.634
          l2.204,0.396l0.979,0.472l0.993-0.218l1.392,0.459l0.809-0.014l0.254,0.601l0.611,0.198l0.655,1.623l1.275,1.463l0.043,0.515
          l0.806,0.453l0.141-0.012L578.905,592.811z"
        />
        <path
          class="st32"
          d="M555.399,611.176l-0.885,0.287l-0.841-0.421l-1.271,0.316l-0.394-0.063l-0.52-0.357l-1.008,0.028
          l-0.937-0.401l-0.901,0.192l-0.168,0.19l-0.078-0.104l-1.567-0.354l-0.202,0.668l-1.398-0.758l-1.539,0.838l-0.034-0.884
          l-1.54,0.149l-0.764,0.564l-0.201,0.828l0.27,0.709l-0.269,2.154l1.897-0.814l0.787,0.866l0.568,0.089l-0.303,1.036l0.931,0.788
          l0.499,1.017l0.835,0.51l0.282-0.056l0.171,0.68l1.607,0.424l0.407-1.208l0.621-0.076l0.88-0.519l0.364,0.356l0.373-0.027
          l-0.144,0.262l0.418,1.215l2.669-0.802l1.534-0.877l2.252-3.526l0.103-2.109l-0.89-0.509L555.399,611.176z M544.473,612.681
          l0.146-0.241l0.613-0.334l0.255,0.139l0.022,0.11L544.473,612.681z M556.325,613.624l-1.844,2.886l-0.982,0.577l-0.563,0.17
          l-0.307-1.695l-1.242,0.736l-0.119,0.008l-0.337-0.328l-1.183-0.352l-0.355,0.684l-0.428,0.252l-1.25,0.152l-0.091,0.271
          l-0.364-0.438l-0.585,0.114l-0.404-0.823l0.474-0.337l-0.417-2.301l0.213,0.162l1.224-0.864l0.621,0.829l1.038-1.183l0.771,0.33
          l0.85-0.022l0.396,0.271l1.028,0.165l1.03-0.256l0.896,0.448l1.131-0.367l0.839,0.128L556.325,613.624z"
        />
        <polygon
          class="st32"
          points="535.829,613.024 535.052,613.641 534.497,613.641 533.329,615.371 535.887,615.827 536.688,615.357
          536.723,615.204 536.951,615.193 537.97,614.292 536.729,612.902 	"
        />
        <path
          class="st32"
          d="M635.115,621.314l-0.064-0.176l0.094-1.122l-2.419-0.974l-0.266-0.456l-0.839-0.106l-0.521-0.561l-0.688,0.1
          l-1.236-0.667l-0.749-0.005l-0.476-0.802l-0.783-0.342l-0.974,0.158l-0.627,0.27l-0.745-0.66l-0.525-0.173l-1.419-0.25
          l-0.477,0.244l-0.89-1.066l-1.487-0.191l-1.947-1.034l-5.853-1.446l-1.508-0.856l-1.628-0.166l-1.94-0.745l-1.086-0.081
          l-0.263-0.698l-0.969-0.396l-0.45,0.31l-1.214-0.344l-0.925,0.37l-1.057-0.338l-0.272-0.037l0.665-0.788l-0.463-0.985l-1.352-0.326
          l-0.688,0.288l-0.607-0.775l-2.142,2.148l-1.247,0.361l-0.792-0.708l-1.666,1.73l1.077,0.818l0.294,0.889l0.632,0.89l1.328,0.317
          l3.598,1.694l1.167-0.349l0.402-0.57l0.734,0.854l1.41,0.335l0.947,0.024l0.112,0.746l1.447,0.298l0.981-0.334l1.838,0.229
          l1.579,0.924l0.987,0.855l1.869,0.254l3.761,1.19l1.637,0.286l0.646,0.636l2.635-0.068l0.326,0.202l1.354,0.032l0.619-0.165
          l2.526,1.303l1.504,0.355l1.127,0.456l0.019,1.549l1.122-0.411l0.8,0.188l0.534-0.595l0.452,0.388l0.888,0.02l0.424,0.771
          l0.469-0.104l0.32,0.292l1.041-0.184l0.818-1.215l-0.166-0.94L635.115,621.314z M603.527,613.416l-0.854-0.203l-0.499-0.579
          l-0.875-0.585l-0.879-0.132l-1.152,0.757l0.092,0.203l-2.806-1.326l-1.064-0.272l-0.113-0.146l-0.2-0.604l1.939-0.561l1.218-1.223
          l0.118,0.151l-0.417,0.206l0.611,1.37l2.178,0.097l1.475,0.472l0.952-0.382l1.143,0.324l-0.519,2.434L603.527,613.416z
           M604.079,613.419l0.508-2.38l0.061,0.017l0.104-0.071l0.229,0.607l-0.39,1.829L604.079,613.419z M633.554,621.042l-0.396-0.088
          l-0.595,0.719l-0.396-0.34l-1.209,0.025l-0.304,0.338l-0.007-0.593l-2.111-0.854l-1.409-0.32l-2.951-1.523l-0.973,0.261
          l-0.73-0.019l-0.361-0.224l-2.445,0.064l-0.526-0.519l-1.95-0.333l-3.772-1.194l-1.616-0.23l-0.702-0.608l-2.017-1.196
          l-2.486-0.309l-0.923,0.314l-0.085-0.434l-0.684-0.557l-0.109-0.001l0.372-1.746l1.558,0.055l1.918,0.737l1.605,0.19l1.325,0.752
          l5.882,1.458l2.09,1.111l-0.148,0.254l0.849,0.935l2.096,0.341l0.775-0.398l0.762,0.129l0.179,0.06l1.263,1.119l1.223-0.594
          l0.366-0.06l0.336,0.609l0.739,0.469l0.806,0.007l1.346,0.726l0.401-0.059l0.357,0.383l0.642,0.081l0.152,0.261l1.869,0.752
          L633.554,621.042z"
        />
        <path
          class="st32"
          d="M651.238,621.769l0.534-0.342l-0.103-0.562l0.712-0.178l0.869-1.478l-1.728-0.584l-2.607-2.188l-2.522-1.531
          l-0.554-0.562l-3.848-0.912l0.127,1.745l0.169,0.138l-0.279-0.015l-2.01-1.395l-1.531-0.201l-0.397,1.362l2.582,2.133l0.737,0.121
          l1.376,1.53l1.024-0.397l1.16,0.59l0.276,0.663l1.393,0.234l1.332,1.085l0.728,1.069l0.745,0.435l1.655,0.023L651.238,621.769z
           M650.116,620.706l-0.222,0.143l-0.038,0.188h-0.017l-0.112-0.065l-0.598-0.879l-1.87-1.564l-0.967-0.162l-0.066-0.159l0.61-0.321
          l-1.44-1.297l-0.579-0.944l0.257,0.061l0.317,0.322l2.604,1.598l2.549,2.138l-0.519,0.453L650.116,620.706z"
        />
        <polygon
          class="st32"
          points="357.21,381.011 355.759,380.838 354.954,381.571 355.017,382.731 355.515,383.446 358.353,385.487
          359.2,384.057 358.154,381.818 	"
        />
        <polygon
          class="st32"
          points="355.234,393.694 356.132,396.982 359.527,398.609 357.795,395.234 	"
        />
        <path
          class="st32"
          d="M359.738,388.543l0.905,0.962l0.691,0.442l2.176,2.436l1.62,0.788l0.589,0.027l0.916,0.715l1.766-0.527
          l-0.644-1.705l-1.074-0.712l-0.204-0.685l-1.794-0.853l-0.48-0.424l-0.934-1.036l-0.188-0.645l-0.972-1.36l-0.91-0.514
          l-1.702-0.368l-1.076,1.163l1.131,1.421L359.738,388.543z M365.233,391.353l0.07,0.233l-0.87-0.422l-0.297-0.333L365.233,391.353z
           M361.683,387.88l0.187,0.642l-0.207-0.123l-0.542-0.574l-0.022-0.105l0.421-0.053L361.683,387.88z M361.106,387.121l0.274,0.36
          l-0.321,0.04l-0.1-0.484L361.106,387.121z"
        />
        <polygon
          class="st32"
          points="397.255,471.861 396.764,472.509 396.442,472.299 394.271,472.579 395.72,474.214 397.294,474.73
          398.241,473.585 	"
        />
        <path
          class="st32"
          d="M468.604,582.257l-0.381-0.559l-1.088-0.216l-0.962,0.592l-0.121,0.915l0.285,0.928l-0.441,0.844l0.115,0.729
          l-0.822,1.016l-0.145,0.976l1.328,1.009l1.066-0.934l1.096-0.339l0.61-0.604l0.847-2.625l-0.934-1.733L468.604,582.257z
           M467.826,585.804l-0.089,0.089l-0.165,0.051l0.023-0.028l-0.142-0.898l0.497-0.949l-0.093-0.306l0.278-0.002l0.229,0.376
          L467.826,585.804z"
        />
        <polygon
          class="st32"
          points="414.545,568.355 413.654,568.399 413.071,569.362 413.75,570.348 414.86,570.694 415.823,569.832
          415.168,568.799 	"
        />
        <path
          class="st32"
          d="M340.479,398.951l0.616,0.891l0.586,0.188l0.773,0.885l1.84,0.288l1.224-0.395l-1.041-1.758l0.173-0.77
          l-0.301-0.889l-1.591-0.83l-0.052-0.119l0.116-0.668l-0.237-0.699l0.385-1.675l-1.817-1.955l-3.042-0.226l-0.843,2.122l0.268,1.162
          l0.641,0.856l0.89,0.298l0.734,1.509l0.487,0.654L340.479,398.951z M343.083,398.422l-0.167,0.741l-0.38-0.435l-0.497-0.16
          l-0.013-0.019l0.256-0.545L343.083,398.422z M342.105,397.911l-0.205,0.437l-0.1-0.596L342.105,397.911z M339.1,394.087
          l-0.17-0.228l-0.092-0.398l0.263-0.663l1.356,0.101l0.875,0.941l-0.303,1.313l0.251,0.74l-0.108,0.619l-0.055-0.071l-0.974-2.006
          L339.1,394.087z"
        />
        <path
          class="st32"
          d="M345.012,397.442l2.089,1.655l0.558,0.104l0.612,0.725l2.78,1.95h0.452l0.739-0.469l1.366-0.054l0.41-1.284
          l-0.023-2.034l-1.373-1.373l-0.355-0.035l-0.35-0.905l0.671-0.412l0.627-1.78l-0.276-0.967l-0.621-0.652l-0.142-0.464l-1.322-1.207
          l-1.848-0.259l-0.745,0.561l-0.019,0.039l-0.963-1.55l-0.426,2.859l0.618,1.079l-0.185,0.585l0.625,1.956l-0.003,0.387
          L345.012,397.442z M348.872,397.139l0.534-0.763l0.009-1.094l-0.552-1.729l0.24-0.757l-0.249-0.434l0.202,0.008l0.386-0.812
          l0.747,0.104l0.676,0.617l0.126,0.409l0.61,0.642l0.043,0.151l-0.292,0.829l-1.281,0.789l1.131,2.929l0.74,0.074l0.562,0.562
          l0.013,1.18l-0.017,0.053l-0.719,0.028l-0.479,0.303l-1.948-1.345l-0.892-1.059l-0.677-0.126L348.872,397.139z"
        />
        <path
          class="st32"
          d="M353.039,420.07l0.921,0.923l0.376,0.107l2.246-0.534l-0.908-1.593l-0.635-0.159l-0.384-1.085l0.34,0.797
          l2.135,0.853l0.387,0.812l1.058,0.531l0.277-0.151l1.335,0.822l0.977-0.625l-0.007-0.222l0.403-0.08l0.802-0.944l-0.084-0.101
          l0.865-0.185l-0.745-1.355l-0.748-0.344l-0.072-1.294l-1.011,0.006l-1.001-0.908l-0.548,1.15L358.362,416l-0.409-0.685
          l-1.504-0.494l-2.34-1.455l-2.013-2.495l0.432-0.604l-1.505-0.627l-0.293,0.062l-0.551-0.908l-0.935-0.814l-2.45-0.964
          l-0.919,0.657l0.27,0.722l-0.247-0.067l-0.306,0.227l-0.811-0.241l-0.137,0.788l-0.11-0.362l-1.42-1.196l-0.982-0.258l-0.322,0.131
          l-0.223-0.291l-1.319,0.126l-0.423,1.181l0.371,1.104l0.773,0.383l0.525,0.857l2.255,0.645l0.042-0.294l0.153,0.101l0.623-0.571
          l0.29,0.568l1.479,1.44l1.95,1.228l0.66-1.682l0.758,0.391l-0.024,0.134l1.988,3.978l0.647,0.923L353.039,420.07z M358.756,418.172
          l1.174-0.104l0.15-0.315h0.059l0.006,0.16l0.35,0.64l-0.384,0.61l0.045,0.054l-0.488,0.096l-0.707-0.436l0.037-0.063l-0.346-0.719
          L358.756,418.172z M342.528,409.506l-0.041-0.012l-0.36-0.589l0.104-0.042l0.173,0.045l0.187,0.157L342.528,409.506z
           M347.303,411.495l-1.148-1.071l-0.156-0.306l0.206-0.151l1.24,0.338l0.253-0.352l-0.503,1.082L347.303,411.495z M347.742,409.892
          l0.372-0.515L348,409.074l0.393,0.148l0.57,0.463l0.738,1.215L347.742,409.892z M351.418,412.813l0.278-0.045l1.324,1.64
          l2.712,1.734l1.219,0.423l0.272,0.456l0.926,0.696l-0.278,0.039l-1.042-0.667l-0.267,0.448l-0.435-0.174l-0.401-0.94l-1.08-0.338
          l-1.025-1.061l-0.862,0.083l-0.126,0.141L351.418,412.813z"
        />
        <path
          class="st32"
          d="M419.756,487.125l-1.099-0.319l-1.255-0.695l-0.514-0.363l-0.73-1l0.239-0.186l0.817,0.185l0.197-1.523
          l-0.978-1.465l-0.844,0.188l0.065-0.284l-1.005-0.946l-4.563-2.65l-1.177-1.141l-2.516-1.482l-1.611-2.588l-7.832-6.435
          l-0.808-0.809l0.096-0.018l0.605-0.879l-0.173-0.584l1.367,0.978l1.152,1.119l0.696,1l1.465,0.694l1.014,0.964l1.693,1.05
          l0.708,0.612l0.399,0.815l0.24,0.352l1.632,0.312l-0.046-1.571l-1.688-2.251l-0.356-1.039l-1.205-1.324l-0.742-1.24l-0.338-0.346
          l0.45-0.709l-0.529-0.788l-2.218-2.116l-0.029,2.189l-0.648-0.065l-0.142-1.104l-1.804-0.946l-0.514,0.161l-0.471-0.532
          l-1.187,0.202l-1.575,1.495l0.948,1.275l-0.341,0.027l-0.267-0.098l-0.509-0.568l-0.702-0.124l0.007-0.021l-0.22-1.21l0.125-1.477
          l-0.381-0.84l-0.71-0.886l2.47,1.079l-2.256-3.862l-1.832-1.944l-1.657-0.48l-0.204,1.486l1.773,2.977l0.869,0.38l-1.585-0.578
          l-2.101-1.815l-0.328-0.802l-0.89-0.335l-1.177-1.269l-0.528-0.932l-1.098-0.488l-0.442-0.793l-0.234-0.156l0.299-0.883
          l-0.662-0.892l-2.419-1.701l-1.222-0.523l-0.136,0.212l-0.989-0.729l2.535,0.759l-1.967-4.048l-0.745-0.521l-0.05-0.121
          l0.165-0.449l-0.359-1.146l-1.608,0.095l-0.396,0.976l0.072,0.372l-0.225,0.275l-0.883-1.083l-0.299-1.052l-0.096-1.644
          l-0.533-0.559l-0.525-1.205l-0.609-0.007l0.458-0.455l-0.955-2.278l-1.261,1.113l-0.942-0.774l-2.57-1.441l-0.263-0.394
          l-0.268-0.923l-0.078-0.064l0.297-0.742l-2.042-0.985l-0.438,0.151l-0.501-0.576l-0.081-0.597l-0.768-0.544l0.292-0.623
          l-1.798-1.526l-0.861-0.229l-0.575-0.922l0.142,0.215l1.362,0.948l0.817,0.271l0.303,0.688l1.573,0.735l0.334-0.059l1.345,0.918
          l0.756-1.36l-0.532-1.078l-2.349-1.784l-2.107-0.927l-1.648-1.267l-0.416,2.065l0.013,0.02l-0.627,0.008l-0.272-0.212l-0.169-0.604
          l-0.534-0.191l-1.144-1.599l-0.564,2.2l0.394,1.576l0.417,0.56l-0.413-0.375l-0.797,1.127l-0.468-0.905l-0.714-0.215l-0.328-1.715
          l-2.076,2.061l0.246,1.415l4.112,2.424l1.257,1.048l1.136,0.293l0.274-0.794l1.157,0.843l0.907,1.01l1.548,1.058l0.379,0.923
          l1.478,1.806l1.515,1.062l2.399,2.355l0.818,0.555l0.995,1.767l0.526,0.298l1.265,2.386l0.446,1.323l0.896,0.784l0.591,0.893
          l1.435,3.134l1.058,1.574l1.075,1.04l0.261,0.763l1.005,1.06l0.278,0.645l3.636,3.507l1.925,2.229l1.924,1.536l3.372,3.196
          l0.927-2.011l-0.182-0.646l0.386,0.116l-0.249,0.32l1.004,1.158l-1.073,0.988l1.586,1.48l0.854-0.215l0.025,0.275l0.643,0.957
          l0.708,0.634l0.064,0.11l-2.307-0.946l-0.083,1.615l1.243,1.577l0.552,0.147l0.493,0.681l0.824-0.465l1.003,0.242l0.24-0.605
          l0.109-0.275l0.146,0.061l0.231-0.234l0.208,0.123l0.199,0.727l0.541,0.096l0.138,0.414l0.642,0.197l-0.032,0.175l0.439,0.779
          l1.425,1.266l-0.689,0.521l-0.812-0.295l-0.819,0.403l-0.504,1.206l2.468,0.989l0.057-0.069l0.438,0.819l0.854-0.13l2.179,1.413
          h0.568l-0.058,0.173l0.012,0.964l1.765,1.139l2.132-2.422l-0.021-0.031l3.297,1.784l1.028,1.16l0.241,0.044l-0.435,1.024
          l1.505,1.981l0.874,0.101l-0.961,1.2l0.707-0.059l0.041,1.479l0.621,0.306l0.261,0.84l1.25,0.325l0.681-1.151l-0.043-0.15
          l0.116,0.154l1.141,0.206l0.857,0.618l2.035-0.068l-0.629-1.526L419.756,487.125z M400.803,464.386h0.001l-0.093,0.146l1.062,0.938
          l0.721,1.204l1.08,1.209l-0.234-0.143l-1.043-0.99l-1.408-0.708l-0.509-0.732l-1.089-1.077L400.803,464.386z M396.918,462.546
          l0.047,0.046l-0.114-0.024L396.918,462.546z M357.888,429.147l-0.323-0.269l0.006-0.006l0.37,0.123L357.888,429.147z
           M358.006,428.806l-0.295-0.098l0.011-0.013l0.3,0.065L358.006,428.806z M376.801,445.481l-0.032-0.023l-0.151-0.209
          L376.801,445.481z M359.479,427.16l0.967,0.258l0.666,0.565l-0.141,0.3l0.361,0.816l0.19,0.134l-2.114-0.701l0.458-0.42
          L359.479,427.16z M359.985,429.188l-0.852-0.4l0.107-0.099l2.797,0.927l0.051,0.374l0.206,0.238l-1.683-0.558L359.985,429.188z
           M386.162,457.78l-0.451-0.602l-0.536-1.124l-0.891-0.507l-1.023,0.11l-0.488,0.88l-0.12,0.494l-2.318-2.238l-0.212-0.49
          l-1.016-1.138l-0.275-0.804l-1.18-1.115l-0.915-1.345l-1.434-3.13l-0.735-1.127l-0.798-0.733l-0.338-1.003l-1.545-2.933
          l-0.54-0.308l-0.974-1.724l-0.946-0.622l-2.396-2.352l-1.438-0.989l-1.214-1.438l-0.508-1.236l-1.71-1.14l-0.89-0.991l-0.178-0.137
          l1.457,0.483l0.409,0.473l0.912,0.562l0.488-0.169l0.224,0.108l-0.186,0.465l0.569,0.465l0.17,0.587l0.625,0.999l2.715,1.508
          l1.646,1.354l0.152,0.849l0.83,0.479l0.594,0.008l0.191,0.438l0.389,0.474l0.069,1.177l0.435,1.591l0.961,1.176l1.068,2.227
          l0.812,1.142l3.364,2.526l0.307-0.478l1.945,1.327l0.118,0.16l-0.187,0.551l0.205,0.967l0.457,0.305l0.508,0.914l1.104,0.49
          l0.327,0.577l1.581,1.732l0.607,0.229l0.214,0.522l0.565,0.495l-0.545,1.043L386.162,457.78z M386.371,457.884l0.52-0.996
          l0.383,0.336l-0.459,0.879L386.371,457.884z M390.173,462.38l-1.256-0.298l-0.345-0.232l-0.625-1.002l0.219,0.132l0.249-0.065
          l0.761,0.907l1.065,0.431L390.173,462.38z M391.041,459.712l-0.39,1.088l-0.566-0.229l-1.132-1.349l-0.555,0.146l-0.538-0.325
          l-1.227,0.045l0.626-0.766L387,458.194l0.434-0.831l0.888,0.778l0.381,0.295l2.283,0.869l0.35,0.415l0.037,0.082L391.041,459.712z
           M393.98,465.272l-1.03-1.188l0.065-0.027l0.455,0.469l0.865,0.317L393.98,465.272z M396.221,462l0.476,0.533l-0.555-0.12
          l-0.147-0.199L396.221,462z M400.455,472.755l-0.105-0.187l0.142-0.765l-0.743-0.841l-0.45,0.149l-0.13-0.391l-0.439-0.077
          l-0.155-0.407l-1.739-1.023l-0.334,0.338l-0.701-1.223l-0.758-0.696l-0.37-0.55l-0.012-0.129l0.206-0.052l-0.23-0.215l-0.046-0.512
          l1.358,1.354l7.628,6.222l1.108,1.78h-0.107l-1.088-0.964l-0.834,0.043L400.455,472.755z M401.276,475.831l-0.061,0.037
          l-0.214-0.688l0.911,0.766L401.276,475.831z M403.667,476.904l-1.274-0.777l0.56-0.029l1.037,0.919L403.667,476.904z
           M405.58,478.021l-0.37-0.581l-1.174-0.407h1.021l0.724,0.793l0.107,0.098L405.58,478.021z"
        />
        <polygon
          class="st32"
          points="442.961,576.064 440.32,577.146 439.17,578.156 439.834,579.358 440.875,579.73 441.854,579.641
          443.257,579.064 444.292,578.242 444.817,577.048 444.215,575.934 	"
        />
        <path
          class="st32"
          d="M454.952,490.269l-0.316-1.061l-2.405-3.472l-1.019-0.807l-1.367,0.642l0.089,0.711l-0.793-0.137
          l-0.801,1.134l0.847,1.265l3.196,2.567l1.227,1.818l1.91-0.606l-0.769-1.36L454.952,490.269z M450.799,487.952l0.108,0.019
          l0.525-0.765l1.801,2.549l0.046,0.153L450.799,487.952z"
        />
        <path
          class="st32"
          d="M441.32,497.386l0.482-0.755l-0.242-1.107l-3.623-1.644l-0.788-0.201l-1.793-1.864l-1.798-1.135l-0.183,0.31
          l-1.723-0.871l-1.339,0.07l-1.338-0.901l-1.126,0.322l-0.066,1.244l0.126,0.229l-0.2,0.514l0.648,0.854l1.474,1.134l0.952-0.374
          l0.941,1.053l0.906,0.531l0.34,0.672l0.851,0.549l0.479-0.035l2.119,1.412l0.922-0.03l0.405-0.517l0.068,0.039l0.353-0.072
          l0.169,0.156l2.063,0.563L441.32,497.386z M429.787,491.68l-0.299-0.223l-0.025-0.034l0.347,0.233L429.787,491.68z
           M431.418,491.668l0.425,0.201l0.023,0.3L431.418,491.668z M432.086,492.414l-0.034-0.447l0.355,0.168l0.174,0.159l0.056,0.736
          L432.086,492.414z M436.265,495.484l-1.559-1.039l-0.492,0.035l-0.087-0.056l-0.373-0.735l-0.903-0.477l-0.055-0.72l0.931,0.854
          l0.347-0.589l0.267,0.165l2.033,2.113l0.551,0.131L436.265,495.484z"
        />
        <polygon
          class="st32"
          points="420.188,468.077 419.539,468.149 419.918,467.837 421.721,467.526 420.697,466.078 419.971,465.522
          419.479,464.231 417.64,464.394 417.755,464.939 417.191,464.813 416.392,466.099 419.052,468.227 418.73,469.428 419.553,470.132
          420.826,470.686 422.259,470.188 421.483,468.852 	"
        />
        <path
          class="st32"
          d="M385.918,442.826l-1.83-0.949l-0.374,0.048l-0.315-0.761l-0.789-0.606l-0.602-0.945l-0.246-0.13l-0.295-1.51
          l-0.955-0.886l-0.41-0.825l-0.702,0.123l-1.984-2.13l-1.148,1.657l0.939,2.443l-0.427,0.8l0.896,1.232l0.718,0.268l0.535,0.622
          l0.675,0.427l1.324,1.928l2.246,2.197l1.275,0.711l0.625,0.667l1.667,0.548l0.91-1.14l-1.388-1.365l-0.472-0.71l0.744-0.8
          L385.918,442.826z M379.979,440.197l-0.699-0.813l-0.656-0.244l-0.078-0.107l0.308-0.575l-0.3-0.78l0.305,0.327l0.399-0.07
          l0.832,0.807l0.333,1.701L379.979,440.197z M384.242,444.73l-0.1-0.053l-2.02-1.957l-1.456-2.12l0.291,0.152l0.458,0.721
          l0.751,0.636l0.349,0.841l0.69,0.551l0.606-0.077l0.633,0.328L384.242,444.73z"
        />
        <polygon
          class="st32"
          points="395.8,455.828 393.809,456.119 394.878,457.517 397.45,458.589 398.018,457.247 397.574,456.694
          399.318,457.984 399.85,458.71 400.972,458.926 402.004,457.807 400.459,455.779 399.37,455.254 397.995,454.11 396.698,455.085
          397.227,456.303 396.398,456.193 	"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import * as d3 from "d3";
import {
  TweenMax,
  TweenLite,
  TimelineLite,
  TimelineMax,
  Linear,
  RoughEase
} from "gsap/all";

export default {
  name: "DeepNeuralNetworksChart",
  props: ["active"],
  mounted() {
    const vm = this;

    const svg = d3.select(vm.$refs.svg);

    svg.selectAll("g.light_g").each(function() {
      const g = d3.select(this);
      const ellipse = g.select("ellipse");

      ellipse
        .style("fill", "url(#light_gradient)")
        .attr("rx", 60)
        .attr("ry", 60);
    });
    vm.chart = Chart();
    vm.chart.setup();
    vm.chart.update(vm.active);

    function Chart() {
      return {
        stars: [],
        setup() {
          let appearMin = 0.3;
          let appearMax = 0.8;

          let delayMin = 2;
          let delayMax = 6;

          let durationMin = 0.3;
          let durationMax = 1;

          let numAnimations = 5;

          let eases = [];
          for (let i = 0; i < numAnimations; i++) {
            let ease = new RoughEase({
              template: Linear.easeNone,
              strength: random(1, 3),
              points: random(50, 200) | 0,
              taper: "both",
              randomize: true,
              clamp: true
            });

            eases.push(ease);
          }

          const stars = (this.stars = []);
          svg.selectAll("g.light_g").each(function() {
            const star = this;

            stars.push(createStar(star));
          });

          function createStar(star) {
            let tl = new TimelineMax({ repeat: -1, yoyo: true });

            TweenLite.set(star, {
              scale: 0.3,
              transformOrigin: "50% 50%"
            });

            for (let i = 0; i < numAnimations; i++) {
              let ease1 = eases[random(numAnimations) | 0];
              let ease2 = eases[random(numAnimations) | 0];

              let scale = random(0.15, 0.8);

              let appear = "+=" + random(appearMin, appearMax);
              let delay = "+=" + random(delayMin, delayMax);
              let duration1 = random(durationMin, durationMax);
              let duration2 = random(durationMin, durationMax);

              tl.to(star, duration1, { scale: scale }, delay).to(
                star,
                duration2,
                { scale: 0 },
                appear
              );
            }

            tl.progress(random(1));

            return {
              element: star,
              timeline: tl
            };
          }

          function random(min, max) {
            if (max == null) {
              max = min;
              min = 0;
            }
            if (min > max) {
              let tmp = min;
              min = max;
              max = tmp;
            }
            return min + (max - min) * Math.random();
          }
        },
        update(is_active) {
          this.stars.forEach(({ element, timeline }) =>
            is_active ? timeline.resume() : timeline.pause()
          );
        }
      };
    }
  },
  watch: {
    active() {
      this.chart && this.chart.update(this.active);
    }
  }
};
</script>

<style scoped>
.st32 {
  fill: #ffffff;
}

.main_group {
  transform: translate(-130px, 0px);
  transform-origin: 50% 50%;
  scale: 1.25;
}

svg {
  width: 110%;
  margin-left: -5%;
}

@media (max-width: 576px) {
  svg {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
